@use '../config' as *;

.btn {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  text-decoration: none;

  background: var(--color-bg-dark);
  padding: var(--space-2xs) var(--space-sm);
  border-radius: var(--radius-md);

  font-size: 1em;
  font-weight: 500;
  color: var(--color-contrast-higher);
  text-decoration: none;
  line-height: 1.2;
  cursor: pointer;

  transition: 0.2s;
  will-change: transform;

  &:focus-visible {
    outline: none;
  }

  &:active {
    transform: translateY(2px);
  }
}

/* primary */
.btn--primary {
  background: var(--color-primary);
  border: 2px solid var(--color-primary);
  color: var(--color-white);

  &:hover {
    background: var(--color-primary-light);
  }
}

.btn--primary__outline {
  background: transparent;
  border: 2px solid var(--color-primary);
  color: var(--color-primary);

  &:hover,
  &:focus-visible{
    background: var(--color-primary-light);
    border: 2px solid var(--color-primary-light);
    color: var(--color-bg);
  }
}

/* subtle */
.btn--subtle {
  background: var(--color-bg-lighter);
  color: var(--color-contrast-higher);

  &:hover {
    background: var(--color-bg-light);
    border: 2px solid var(--color-bg-light);
  }
}

.btn--subtle__outline {
  background: transparent;
  border: 2px solid var(--color-bg-lighter);
  color: var(--color-bg-lighter);

  &:hover,
  &:focus-visible{
    background: var(--color-bg-light);
    border: 2px solid var(--color-bg-lighter);
    color: var(--color-bg);
  }
}

/* accent */
.btn--accent {
  background: var(--color-accent);
  border: 2px solid var(--color-accent);
  color: var(--color-white);

  &:hover {
    background: var(--color-accent-light);
    border: 2px solid var(--color-accent-light);
  }
}

.btn--accent__outline {
  background: transparent;
  border: 2px solid var(--color-accent);
  color: var(--color-accent);

  &:hover,
  &:focus-visible {
    background: var(--color-accent-light);
    border: 2px solid var(--color-accent-light);
    color: var(--color-bg);
  }
}

/* contrast */
.btn--contrast {
  background: alpha(var(--color-contrast-higher), 0.95);
  border: 2px solid alpha(var(--color-contrast-higher), 0.95);
  color: var(--color-bg);

  &:hover {
    background: var(--color-contrast-higher);
  }
}

.btn--contrast__outline {
  background: transparent;
  border: 2px solid var(--color-contrast-higher);
  color: var(--color-contrast-higher);

  &:hover,
  &:focus-visible {
    background: var(--color-contrast-higher);
    border: 2px solid var(--color-contrast-higher);
    color: var(--color-bg);
  }
}

/* primary subtle */
.btn--primary-subtle {
  background: alpha(var(--color-primary), 0.15);
  color: var(--color-primary);

  &:hover {
    background: alpha(var(--color-primary), 0.12);
  }
}

/* accent subtle */
.btn--accent-subtle {
  background: alpha(var(--color-accent), 0.15);
  color: var(--color-accent);

  &:hover {
    background: alpha(var(--color-accent), 0.12);
  }
}

/* feedback */
.btn--disabled, .btn[disabled], .btn[readonly] {
  opacity: 0.6;
  cursor: not-allowed;
}

/* a button containing (only) an icon */
.btn--icon {
  padding: 0.5rem;
}