/*! purgecss start ignore */
*, *::after, *::before {
  box-sizing: border-box;
}

* {
  font: inherit;
  margin: 0;
  padding: 0;
  border: 0;
}

body {
  background-color: var(--color-bg, white);
}

ol, ul, menu {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

mark {
  background-color: transparent;
  color: inherit;
}

button, textarea, select, .reset {
  background-color: transparent;
  border-radius: 0;
  color: inherit;
  line-height: inherit;
  appearance: none;
}

a {
  color: var(--color-primary, hsl(250, 100%, 69%));
}

textarea {
  resize: vertical;
  overflow: auto;
  vertical-align: top;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

img, video, svg {
  display: block;
  max-width: 100%;
  height: auto;
}

[data-theme] {
  color: var(--color-contrast-high, hsl(240, 5%, 82%));
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  color: var(--color-contrast-high);
  font-family: var(--font-primary);
  font-size: var(--text-base);
  line-height: 1.5;
}

h1, h2, h3, h4, h5 {
  color: var(--color-contrast-higher);
  line-height: 1.2;
  font-weight: 700;
}

h1 {
  font-size: var(--text-2xl);
}

h2 {
  font-size: var(--text-xl);
}

h3 {
  font-size: var(--text-lg);
}

h4 {
  font-size: var(--text-md);
}

a {
  color: var(--color-contrast-higher);
}

small {
  font-size: var(--text-sm);
}

b, strong {
  font-weight: 700;
}

.link {
  text-decoration: none;
  background-image: linear-gradient(to right, currentColor 50%, hsla(var(--color-primary-h), var(--color-primary-s), var(--color-primary-l), 0.2) 50%);
  background-size: 200% 1px;
  background-repeat: no-repeat;
  background-position: 100% 100%;
  transition: background-position 0.2s;
}
.link.color-accent {
  background-image: linear-gradient(to right, currentColor 50%, hsla(var(--color-accent-h), var(--color-accent-s), var(--color-accent-l), 0.2) 50%);
}
.link.color-contrast-higher {
  background-image: linear-gradient(to right, currentColor 50%, hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), 0.2) 50%);
}
.link.color-bg {
  background-image: linear-gradient(to right, currentColor 50%, hsla(var(--color-bg-h), var(--color-bg-s), var(--color-bg-l), 0.2) 50%);
}
.link.color-white {
  background-image: linear-gradient(to right, currentColor 50%, hsla(var(--color-white-h), var(--color-white-s), var(--color-white-l), 0.2) 50%);
}
.link.color-black {
  background-image: linear-gradient(to right, currentColor 50%, hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), 0.2) 50%);
}
.link:hover {
  background-position: 0% 100%;
}

/* text-component */
.text-component,
.component-text-media {
  --heading-line-height: 1.2;
  --body-line-height: 1.5;
  --spacing: 1rem;
  line-height: var(--body-line-height);
}
.text-component > *,
.component-text-media > * {
  margin-bottom: var(--spacing);
}
.text-component :where(h1, h2, h3, h4),
.component-text-media :where(h1, h2, h3, h4) {
  margin-top: calc(var(--spacing) * 1.6666666667);
  line-height: var(--heading-line-height);
}
.text-component :where(ul, ol),
.component-text-media :where(ul, ol) {
  padding-left: 1.25em;
}
.text-component ul :where(ul, ol),
.text-component ol :where(ul, ol),
.component-text-media ul :where(ul, ol),
.component-text-media ol :where(ul, ol) {
  padding-left: 1em;
}
.text-component ul,
.component-text-media ul {
  list-style-type: disc;
}
.text-component ol,
.component-text-media ol {
  list-style-type: decimal;
}
.text-component ul li::marker,
.text-component ol li::marker,
.component-text-media ul li::marker,
.component-text-media ol li::marker {
  color: hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), 0.25);
}
.text-component img,
.component-text-media img {
  border-radius: var(--radius-md);
}
.text-component figcaption,
.component-text-media figcaption {
  margin-top: calc(var(--spacing) / 2);
  font-size: var(--text-sm);
  color: var(--color-contrast-low);
  text-align: center;
}
.text-component em,
.component-text-media em {
  font-style: italic;
}
.text-component strong,
.component-text-media strong {
  font-weight: bold;
}
.text-component s,
.component-text-media s {
  text-decoration: line-through;
}
.text-component u,
.component-text-media u {
  text-decoration: underline;
}
.text-component mark,
.component-text-media mark {
  background-color: hsla(var(--color-accent-h), var(--color-accent-s), var(--color-accent-l), 0.2);
  color: inherit;
  border-radius: var(--radius-md);
  padding: 0 0.25em;
}
.text-component blockquote,
.component-text-media blockquote {
  padding-left: 1em;
  border-left: 3px solid hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), 0.25);
  font-style: italic;
}
.text-component hr,
.component-text-media hr {
  margin: calc(var(--spacing) * 1.6666666667) 0;
  background: hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), 0.1);
  height: 1px;
}
.text-component > *:first-child,
.component-text-media > *:first-child {
  margin-top: 0;
}
.text-component > *:last-child,
.component-text-media > *:last-child {
  margin-bottom: 0;
}

/* overflow items */
:where(.text-component__item-full-width, .text-component__item-overflow, .text-component__item-overflow-left, .text-component__item-overflow-right, .text-component__item-left, .text-component__item-right) img {
  width: 100%;
}

.text-component__item-full-width {
  width: 100vw;
  margin-left: calc(50% - 50vw);
}

@media (min-width: 48rem) {
  .text-component__item-left,
  .text-component__item-right,
  .text-component__item-overflow-left,
  .text-component__item-overflow-right {
    width: 45%;
  }
  .text-component__item-left,
  .text-component__item-overflow-left {
    float: left;
    margin-right: var(--spacing);
  }
  .text-component__item-right,
  .text-component__item-overflow-right {
    float: right;
    margin-left: var(--spacing);
  }
}
@media (min-width: 80rem) {
  .text-component__item-overflow,
  .text-component__item-overflow-left,
  .text-component__item-overflow-right {
    --overflow-size: 8rem;
  }
  .text-component__item-overflow {
    width: calc(100% + var(--overflow-size) * 2);
    margin-left: calc(var(--overflow-size) * -1);
  }
  .text-component__item-overflow-left {
    margin-left: calc(var(--overflow-size) * -1);
  }
  .text-component__item-overflow-right {
    margin-right: calc(var(--overflow-size) * -1);
  }
}
/* spacing variations */
.text-component--tight {
  --heading-line-height: 1.1;
  --body-line-height: 1.2;
  --spacing: 0.625rem;
}

.text-component--relaxed {
  --heading-line-height: 1.25;
  --body-line-height: 1.625;
  --spacing: 1.25rem;
}
@media (min-width: 64rem) {
  .text-component--relaxed {
    --spacing: 1.5rem;
  }
}

.icon {
  --size: 1em;
  font-size: var(--size);
  height: 1em;
  width: 1em;
  display: inline-block;
  color: inherit;
  fill: currentColor;
  line-height: 1;
  flex-shrink: 0;
  max-width: initial;
}

.icon--3xs {
  --size: 8px;
}

.icon--2xs {
  --size: 12px;
}

.icon--xs {
  --size: 16px;
}

.icon--sm {
  --size: 20px;
}

.icon--md {
  --size: 24px;
}

.icon--lg {
  --size: 32px;
}

.icon--xl {
  --size: 48px;
}

.icon--2xl {
  --size: 64px;
}

.icon--3xl {
  --size: 96px;
}

/* rotate the icon infinitely */
.icon--is-spinning {
  animation: icon-spin 1s infinite linear;
}

@keyframes icon-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* SVG symbols - enable icon color corrections */
.icon use {
  color: inherit;
  fill: currentColor;
}

.btn {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  text-decoration: none;
  background: var(--color-bg-dark);
  padding: var(--space-2xs) var(--space-sm);
  border-radius: var(--radius-md);
  font-size: 1em;
  font-weight: 500;
  color: var(--color-contrast-higher);
  text-decoration: none;
  line-height: 1.2;
  cursor: pointer;
  transition: 0.2s;
  will-change: transform;
}
.btn:focus-visible {
  outline: none;
}
.btn:active {
  transform: translateY(2px);
}

/* primary */
.btn--primary {
  background: var(--color-primary);
  border: 2px solid var(--color-primary);
  color: var(--color-white);
}
.btn--primary:hover {
  background: var(--color-primary-light);
}

.btn--primary__outline {
  background: transparent;
  border: 2px solid var(--color-primary);
  color: var(--color-primary);
}
.btn--primary__outline:hover, .btn--primary__outline:focus-visible {
  background: var(--color-primary-light);
  border: 2px solid var(--color-primary-light);
  color: var(--color-bg);
}

/* subtle */
.btn--subtle {
  background: var(--color-bg-lighter);
  color: var(--color-contrast-higher);
}
.btn--subtle:hover {
  background: var(--color-bg-light);
  border: 2px solid var(--color-bg-light);
}

.btn--subtle__outline {
  background: transparent;
  border: 2px solid var(--color-bg-lighter);
  color: var(--color-bg-lighter);
}
.btn--subtle__outline:hover, .btn--subtle__outline:focus-visible {
  background: var(--color-bg-light);
  border: 2px solid var(--color-bg-lighter);
  color: var(--color-bg);
}

/* accent */
.btn--accent {
  background: var(--color-accent);
  border: 2px solid var(--color-accent);
  color: var(--color-white);
}
.btn--accent:hover {
  background: var(--color-accent-light);
  border: 2px solid var(--color-accent-light);
}

.btn--accent__outline {
  background: transparent;
  border: 2px solid var(--color-accent);
  color: var(--color-accent);
}
.btn--accent__outline:hover, .btn--accent__outline:focus-visible {
  background: var(--color-accent-light);
  border: 2px solid var(--color-accent-light);
  color: var(--color-bg);
}

/* contrast */
.btn--contrast {
  background: hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), 0.95);
  border: 2px solid hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), 0.95);
  color: var(--color-bg);
}
.btn--contrast:hover {
  background: var(--color-contrast-higher);
}

.btn--contrast__outline {
  background: transparent;
  border: 2px solid var(--color-contrast-higher);
  color: var(--color-contrast-higher);
}
.btn--contrast__outline:hover, .btn--contrast__outline:focus-visible {
  background: var(--color-contrast-higher);
  border: 2px solid var(--color-contrast-higher);
  color: var(--color-bg);
}

/* primary subtle */
.btn--primary-subtle {
  background: hsla(var(--color-primary-h), var(--color-primary-s), var(--color-primary-l), 0.15);
  color: var(--color-primary);
}
.btn--primary-subtle:hover {
  background: hsla(var(--color-primary-h), var(--color-primary-s), var(--color-primary-l), 0.12);
}

/* accent subtle */
.btn--accent-subtle {
  background: hsla(var(--color-accent-h), var(--color-accent-s), var(--color-accent-l), 0.15);
  color: var(--color-accent);
}
.btn--accent-subtle:hover {
  background: hsla(var(--color-accent-h), var(--color-accent-s), var(--color-accent-l), 0.12);
}

/* feedback */
.btn--disabled, .btn[disabled], .btn[readonly] {
  opacity: 0.6;
  cursor: not-allowed;
}

/* a button containing (only) an icon */
.btn--icon {
  padding: 0.5rem;
}

/* latin-ext */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("../fonts/poppins/poppins-light-ext.woff2") format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("../fonts/poppins/poppins-light.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("../fonts/poppins/poppins-bold-ext.woff2") format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("../fonts/poppins/poppins-bold.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
.form-control, input[type=select],
select {
  background: var(--color-bg-dark);
  box-shadow: inset 0 0 0 1px var(--color-contrast-lower);
  padding: var(--space-2xs) var(--space-xs);
  border-radius: var(--radius-md);
  width: 100%;
  font-size: 1em;
  line-height: 1.2;
  transition: 0.2s;
}
.form-control::placeholder, input[type=select]::placeholder,
select::placeholder {
  opacity: 1;
  color: var(--color-contrast-low);
}
.form-control:focus, input[type=select]:focus,
select:focus, .form-control:focus-within, input[type=select]:focus-within,
select:focus-within {
  background: var(--color-bg);
  outline: none;
  box-shadow: inset 0 0 0 1px hsla(var(--color-contrast-lower-h), var(--color-contrast-lower-s), var(--color-contrast-lower-l), 0), 0 0 0 2px var(--color-primary), var(--shadow-sm);
}

/* disabled */
.form-control--disabled,
.form-control[disabled],
input[disabled][type=select],
select[disabled],
.form-control[readonly],
input[readonly][type=select],
select[readonly] {
  opacity: 0.5;
  cursor: not-allowed;
}

/* error */
.form-control[aria-invalid=true], input[aria-invalid=true][type=select],
select[aria-invalid=true],
.form-control.form-control--error,
input.form-control--error[type=select],
select.form-control--error {
  box-shadow: inset 0 0 0 1px hsla(var(--color-contrast-lower-h), var(--color-contrast-lower-s), var(--color-contrast-lower-l), 0), 0 0 0 2px var(--color-error);
}
.form-control[aria-invalid=true]:focus, input[aria-invalid=true][type=select]:focus,
select[aria-invalid=true]:focus, .form-control[aria-invalid=true]:focus-within, input[aria-invalid=true][type=select]:focus-within,
select[aria-invalid=true]:focus-within,
.form-control.form-control--error:focus,
input.form-control--error[type=select]:focus,
select.form-control--error:focus,
.form-control.form-control--error:focus-within,
input.form-control--error[type=select]:focus-within,
select.form-control--error:focus-within {
  box-shadow: inset 0 0 0 1px hsla(var(--color-contrast-lower-h), var(--color-contrast-lower-s), var(--color-contrast-lower-l), 0), 0 0 0 2px var(--color-error), var(--shadow-sm);
}

/* legend */
.form-legend {
  font-weight: 700;
  color: var(--color-contrast-higher);
  line-height: 1.2;
  font-size: var(--text-md);
  margin-bottom: var(--space-md);
}

/* label */
.form-label {
  display: inline-block;
  font-size: var(--text-sm);
  margin-bottom: var(--space-3xs);
}

html {
  scroll-behavior: smooth;
}

.color-gradient {
  background: -webkit-linear-gradient(45deg, #c79461, #f2cc98);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/*! purgecss end ignore */
/* variables */
:root {
  /* spacing */
  --space-4xs: 0.125rem;
  --space-3xs: 0.25rem;
  --space-2xs: 0.375rem;
  --space-xs: 0.5rem;
  --space-sm: 0.75rem;
  --space-md: 1.25rem;
  --space-lg: 2rem;
  --space-xl: 3.25rem;
  --space-2xl: 5.25rem;
  --space-3xl: 8.5rem;
  --space-4xl: 13.75rem;
  /* font-family */
  --font-primary: Poppins, sans-serif;
  --font-secondary: Poppins, sans-serif;
  /* font-size */
  --text-xs: 0.6875rem;
  --text-sm: 0.8125rem;
  --text-base: 1rem;
  --text-md: 1.1875rem;
  --text-lg: 1.4375rem;
  --text-xl: 1.75rem;
  --text-2xl: 2.0625rem;
  --text-3xl: 2.5rem;
  --text-4xl: 3rem;
  /* line-height */
  --line-height-xs: 1.1;
  --line-height-sm: 1.2;
  --line-height-md: 1.4;
  --line-height-lg: 1.58;
  --line-height-xl: 1.72;
  /* max-width */
  --max-width-3xs: 20rem;
  --max-width-2xs: 26rem;
  --max-width-xs: 32rem;
  --max-width-sm: 48rem;
  --max-width-md: 64rem;
  --max-width-lg: 80rem;
  --max-width-xl: 90rem;
  --container-margin-x: var(--space-md);
  /* box-shadow */
  --shadow-ring: 0 0 0 1px hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), 0.05);
  --shadow-xs: 0 0 0 1px hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), 0.02), 0 1px 3px -1px hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), 0.2);
  --shadow-sm: 0 0.3px 0.4px hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), 0.02), 0 0.9px 1.5px hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), 0.045), 0 3.5px 6px hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), 0.09);
  --shadow-md: 0 0.9px 1.25px hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), 0.025), 0 3px 5px hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), 0.05), 0 12px 20px hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), 0.09);
  --shadow-lg: 0 1.2px 1.9px -1px hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), 0.01), 0 3px 5px -1px hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), 0.015), 0 8px 15px -1px hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), 0.05), 0 28px 40px -1px hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), 0.1);
  --shadow-xl: 0 1.5px 2.1px -6px hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), 0.009), 0 3.6px 5.2px -6px hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), 0.0115), 0 7.3px 10.6px -6px hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), 0.0125), 0 16.2px 21.9px -6px hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), 0.025), 0 46px 60px -6px hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), 0.15);
  /* inner-glow */
  --inner-glow: inset 0 0 0.5px 1px hsla(var(--color-white-h), var(--color-white-s), var(--color-white-l), 0.075);
  --inner-glow-top: inset 0 1px 0.5px hsla(var(--color-white-h), var(--color-white-s), var(--color-white-l), 0.075);
  /* border-radius */
  --radius-sm: 0.1875em;
  --radius-md: 0.375em;
  --radius-lg: 0.75em;
  /* z-index */
  --z-index-header: 3;
  --z-index-popover: 5;
  --z-index-fixed-element: 10;
  --z-index-overlay: 15;
  /* timing functions */
  --ease-in-out: cubic-bezier(0.645, 0.045, 0.355, 1);
  --ease-in: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  --ease-out: cubic-bezier(0.215, 0.61, 0.355, 1);
  --ease-out-back: cubic-bezier(0.34, 1.56, 0.64, 1);
}

/* colors */
:root, [data-theme=default] {
  --color-primary-darker-h: 356;
  --color-primary-darker-s: 78%;
  --color-primary-darker-l: 30%;
  --color-primary-darker: hsl(var(--color-primary-darker-h), var(--color-primary-darker-s), var(--color-primary-darker-l));
  --color-primary-dark-h: 356;
  --color-primary-dark-s: 78%;
  --color-primary-dark-l: 36%;
  --color-primary-dark: hsl(var(--color-primary-dark-h), var(--color-primary-dark-s), var(--color-primary-dark-l));
  --color-primary-h: 356;
  --color-primary-s: 78%;
  --color-primary-l: 42%;
  --color-primary: hsl(var(--color-primary-h), var(--color-primary-s), var(--color-primary-l));
  --color-primary-light-h: 356;
  --color-primary-light-s: 78%;
  --color-primary-light-l: 48%;
  --color-primary-light: hsl(var(--color-primary-light-h), var(--color-primary-light-s), var(--color-primary-light-l));
  --color-primary-lighter-h: 356;
  --color-primary-lighter-s: 78%;
  --color-primary-lighter-l: 54%;
  --color-primary-lighter: hsl(var(--color-primary-lighter-h), var(--color-primary-lighter-s), var(--color-primary-lighter-l));
  --color-accent-darker-h: 356;
  --color-accent-darker-s: 78%;
  --color-accent-darker-l: 30%;
  --color-accent-darker: hsl(var(--color-accent-darker-h), var(--color-accent-darker-s), var(--color-accent-darker-l));
  --color-accent-dark-h: 356;
  --color-accent-dark-s: 78%;
  --color-accent-dark-l: 36%;
  --color-accent-dark: hsl(var(--color-accent-dark-h), var(--color-accent-dark-s), var(--color-accent-dark-l));
  --color-accent-h: 356;
  --color-accent-s: 78%;
  --color-accent-l: 42%;
  --color-accent: hsl(var(--color-accent-h), var(--color-accent-s), var(--color-accent-l));
  --color-accent-light-h: 356;
  --color-accent-light-s: 78%;
  --color-accent-light-l: 48%;
  --color-accent-light: hsl(var(--color-accent-light-h), var(--color-accent-light-s), var(--color-accent-light-l));
  --color-accent-lighter-h: 356;
  --color-accent-lighter-s: 78%;
  --color-accent-lighter-l: 54%;
  --color-accent-lighter: hsl(var(--color-accent-lighter-h), var(--color-accent-lighter-s), var(--color-accent-lighter-l));
  --color-black-h: 0;
  --color-black-s: 0%;
  --color-black-l: 8%;
  --color-black: hsl(var(--color-black-h), var(--color-black-s), var(--color-black-l));
  --color-white-h: 0;
  --color-white-s: 0%;
  --color-white-l: 100%;
  --color-white: hsl(var(--color-white-h), var(--color-white-s), var(--color-white-l));
  --color-warning-darker-h: 35;
  --color-warning-darker-s: 79%;
  --color-warning-darker-l: 48%;
  --color-warning-darker: hsl(var(--color-warning-darker-h), var(--color-warning-darker-s), var(--color-warning-darker-l));
  --color-warning-dark-h: 35;
  --color-warning-dark-s: 79%;
  --color-warning-dark-l: 56%;
  --color-warning-dark: hsl(var(--color-warning-dark-h), var(--color-warning-dark-s), var(--color-warning-dark-l));
  --color-warning-h: 35;
  --color-warning-s: 79%;
  --color-warning-l: 66%;
  --color-warning: hsl(var(--color-warning-h), var(--color-warning-s), var(--color-warning-l));
  --color-warning-light-h: 35;
  --color-warning-light-s: 79%;
  --color-warning-light-l: 74%;
  --color-warning-light: hsl(var(--color-warning-light-h), var(--color-warning-light-s), var(--color-warning-light-l));
  --color-warning-lighter-h: 35;
  --color-warning-lighter-s: 79%;
  --color-warning-lighter-l: 82%;
  --color-warning-lighter: hsl(var(--color-warning-lighter-h), var(--color-warning-lighter-s), var(--color-warning-lighter-l));
  --color-success-darker-h: 170;
  --color-success-darker-s: 78%;
  --color-success-darker-l: 26%;
  --color-success-darker: hsl(var(--color-success-darker-h), var(--color-success-darker-s), var(--color-success-darker-l));
  --color-success-dark-h: 170;
  --color-success-dark-s: 78%;
  --color-success-dark-l: 31%;
  --color-success-dark: hsl(var(--color-success-dark-h), var(--color-success-dark-s), var(--color-success-dark-l));
  --color-success-h: 170;
  --color-success-s: 78%;
  --color-success-l: 36%;
  --color-success: hsl(var(--color-success-h), var(--color-success-s), var(--color-success-l));
  --color-success-light-h: 170;
  --color-success-light-s: 78%;
  --color-success-light-l: 42%;
  --color-success-light: hsl(var(--color-success-light-h), var(--color-success-light-s), var(--color-success-light-l));
  --color-success-lighter-h: 170;
  --color-success-lighter-s: 78%;
  --color-success-lighter-l: 47%;
  --color-success-lighter: hsl(var(--color-success-lighter-h), var(--color-success-lighter-s), var(--color-success-lighter-l));
  --color-error-darker-h: 342;
  --color-error-darker-s: 89%;
  --color-error-darker-l: 38%;
  --color-error-darker: hsl(var(--color-error-darker-h), var(--color-error-darker-s), var(--color-error-darker-l));
  --color-error-dark-h: 342;
  --color-error-dark-s: 89%;
  --color-error-dark-l: 43%;
  --color-error-dark: hsl(var(--color-error-dark-h), var(--color-error-dark-s), var(--color-error-dark-l));
  --color-error-h: 342;
  --color-error-s: 89%;
  --color-error-l: 48%;
  --color-error: hsl(var(--color-error-h), var(--color-error-s), var(--color-error-l));
  --color-error-light-h: 342;
  --color-error-light-s: 89%;
  --color-error-light-l: 56%;
  --color-error-light: hsl(var(--color-error-light-h), var(--color-error-light-s), var(--color-error-light-l));
  --color-error-lighter-h: 342;
  --color-error-lighter-s: 89%;
  --color-error-lighter-l: 62%;
  --color-error-lighter: hsl(var(--color-error-lighter-h), var(--color-error-lighter-s), var(--color-error-lighter-l));
  --color-bg-darker-h: 0;
  --color-bg-darker-s: 0%;
  --color-bg-darker-l: 90%;
  --color-bg-darker: hsl(var(--color-bg-darker-h), var(--color-bg-darker-s), var(--color-bg-darker-l));
  --color-bg-dark-h: 60;
  --color-bg-dark-s: 3%;
  --color-bg-dark-l: 94%;
  --color-bg-dark: hsl(var(--color-bg-dark-h), var(--color-bg-dark-s), var(--color-bg-dark-l));
  --color-bg-h: 0;
  --color-bg-s: 0%;
  --color-bg-l: 100%;
  --color-bg: hsl(var(--color-bg-h), var(--color-bg-s), var(--color-bg-l));
  --color-bg-light-h: 60;
  --color-bg-light-s: 3%;
  --color-bg-light-l: 100%;
  --color-bg-light: hsl(var(--color-bg-light-h), var(--color-bg-light-s), var(--color-bg-light-l));
  --color-bg-lighter-h: 0;
  --color-bg-lighter-s: 0%;
  --color-bg-lighter-l: 100%;
  --color-bg-lighter: hsl(var(--color-bg-lighter-h), var(--color-bg-lighter-s), var(--color-bg-lighter-l));
  --color-contrast-lower-h: 60;
  --color-contrast-lower-s: 1%;
  --color-contrast-lower-l: 85%;
  --color-contrast-lower: hsl(var(--color-contrast-lower-h), var(--color-contrast-lower-s), var(--color-contrast-lower-l));
  --color-contrast-low-h: 60;
  --color-contrast-low-s: 1%;
  --color-contrast-low-l: 66%;
  --color-contrast-low: hsl(var(--color-contrast-low-h), var(--color-contrast-low-s), var(--color-contrast-low-l));
  --color-contrast-medium-h: 30;
  --color-contrast-medium-s: 1%;
  --color-contrast-medium-l: 48%;
  --color-contrast-medium: hsl(var(--color-contrast-medium-h), var(--color-contrast-medium-s), var(--color-contrast-medium-l));
  --color-contrast-high-h: 60;
  --color-contrast-high-s: 1%;
  --color-contrast-high-l: 25%;
  --color-contrast-high: hsl(var(--color-contrast-high-h), var(--color-contrast-high-s), var(--color-contrast-high-l));
  --color-contrast-higher-h: 60;
  --color-contrast-higher-s: 4%;
  --color-contrast-higher-l: 11%;
  --color-contrast-higher: hsl(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l));
}

/* grid */
.grid {
  --grid-columns: 12;
  --column-gap: 0px;
  --row-gap: 0px;
  display: flex;
  flex-wrap: wrap;
  gap: var(--row-gap) var(--column-gap);
}

.grid > * {
  width: 100%;
  min-width: 0;
}

.grid-col-1 {
  --grid-columns: 1;
}

.col-1 {
  --column-span: 1;
}

.grid-col-2 {
  --grid-columns: 2;
}

.col-2 {
  --column-span: 2;
}

.grid-col-3 {
  --grid-columns: 3;
}

.col-3 {
  --column-span: 3;
}

.grid-col-4 {
  --grid-columns: 4;
}

.col-4 {
  --column-span: 4;
}

.grid-col-5 {
  --grid-columns: 5;
}

.col-5 {
  --column-span: 5;
}

.grid-col-6 {
  --grid-columns: 6;
}

.col-6 {
  --column-span: 6;
}

.grid-col-7 {
  --grid-columns: 7;
}

.col-7 {
  --column-span: 7;
}

.grid-col-8 {
  --grid-columns: 8;
}

.col-8 {
  --column-span: 8;
}

.grid-col-9 {
  --grid-columns: 9;
}

.col-9 {
  --column-span: 9;
}

.grid-col-10 {
  --grid-columns: 10;
}

.col-10 {
  --column-span: 10;
}

.grid-col-11 {
  --grid-columns: 11;
}

.col-11 {
  --column-span: 11;
}

.grid-col-12 {
  --grid-columns: 12;
}

.col-12 {
  --column-span: 12;
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12 {
  width: calc((100% - (var(--grid-columns) - 1) * var(--column-gap)) * var(--column-span) / var(--grid-columns) + (var(--column-span) - 1) * var(--column-gap));
  flex: initial;
}

.col {
  width: auto;
  flex: 1 1 0;
}

.col-content {
  width: auto;
  flex: 0 1 auto;
}

.offset-1 {
  --column-offset: 1;
}

.offset-2 {
  --column-offset: 2;
}

.offset-3 {
  --column-offset: 3;
}

.offset-4 {
  --column-offset: 4;
}

.offset-5 {
  --column-offset: 5;
}

.offset-6 {
  --column-offset: 6;
}

.offset-7 {
  --column-offset: 7;
}

.offset-8 {
  --column-offset: 8;
}

.offset-9 {
  --column-offset: 9;
}

.offset-10 {
  --column-offset: 10;
}

.offset-11 {
  --column-offset: 11;
}

.offset-1, .offset-2, .offset-3, .offset-4, .offset-5, .offset-6, .offset-7, .offset-8, .offset-9, .offset-10, .offset-11 {
  margin-left: calc((100% - (var(--grid-columns) - 1) * var(--column-gap)) * var(--column-offset) / var(--grid-columns) + var(--column-offset) * var(--column-gap));
}

/* gap */
.gap-4xs {
  --row-gap: var(--space-4xs);
  --column-gap: var(--space-4xs);
  gap: var(--row-gap) var(--column-gap);
}

.gap-x-4xs {
  --column-gap: var(--space-4xs);
  column-gap: var(--column-gap);
}

.gap-y-4xs {
  --row-gap: var(--space-4xs);
  row-gap: var(--row-gap);
}

.gap-3xs {
  --row-gap: var(--space-3xs);
  --column-gap: var(--space-3xs);
  gap: var(--row-gap) var(--column-gap);
}

.gap-x-3xs {
  --column-gap: var(--space-3xs);
  column-gap: var(--column-gap);
}

.gap-y-3xs {
  --row-gap: var(--space-3xs);
  row-gap: var(--row-gap);
}

.gap-2xs {
  --row-gap: var(--space-2xs);
  --column-gap: var(--space-2xs);
  gap: var(--row-gap) var(--column-gap);
}

.gap-x-2xs {
  --column-gap: var(--space-2xs);
  column-gap: var(--column-gap);
}

.gap-y-2xs {
  --row-gap: var(--space-2xs);
  row-gap: var(--row-gap);
}

.gap-xs {
  --row-gap: var(--space-xs);
  --column-gap: var(--space-xs);
  gap: var(--row-gap) var(--column-gap);
}

.gap-x-xs {
  --column-gap: var(--space-xs);
  column-gap: var(--column-gap);
}

.gap-y-xs {
  --row-gap: var(--space-xs);
  row-gap: var(--row-gap);
}

.gap-sm {
  --row-gap: var(--space-sm);
  --column-gap: var(--space-sm);
  gap: var(--row-gap) var(--column-gap);
}

.gap-x-sm {
  --column-gap: var(--space-sm);
  column-gap: var(--column-gap);
}

.gap-y-sm {
  --row-gap: var(--space-sm);
  row-gap: var(--row-gap);
}

.gap-md {
  --row-gap: var(--space-md);
  --column-gap: var(--space-md);
  gap: var(--row-gap) var(--column-gap);
}

.gap-x-md {
  --column-gap: var(--space-md);
  column-gap: var(--column-gap);
}

.gap-y-md {
  --row-gap: var(--space-md);
  row-gap: var(--row-gap);
}

.gap-lg {
  --row-gap: var(--space-lg);
  --column-gap: var(--space-lg);
  gap: var(--row-gap) var(--column-gap);
}

.gap-x-lg {
  --column-gap: var(--space-lg);
  column-gap: var(--column-gap);
}

.gap-y-lg {
  --row-gap: var(--space-lg);
  row-gap: var(--row-gap);
}

.gap-xl {
  --row-gap: var(--space-xl);
  --column-gap: var(--space-xl);
  gap: var(--row-gap) var(--column-gap);
}

.gap-x-xl {
  --column-gap: var(--space-xl);
  column-gap: var(--column-gap);
}

.gap-y-xl {
  --row-gap: var(--space-xl);
  row-gap: var(--row-gap);
}

.gap-2xl {
  --row-gap: var(--space-2xl);
  --column-gap: var(--space-2xl);
  gap: var(--row-gap) var(--column-gap);
}

.gap-x-2xl {
  --column-gap: var(--space-2xl);
  column-gap: var(--column-gap);
}

.gap-y-2xl {
  --row-gap: var(--space-2xl);
  row-gap: var(--row-gap);
}

.gap-3xl {
  --row-gap: var(--space-3xl);
  --column-gap: var(--space-3xl);
  gap: var(--row-gap) var(--column-gap);
}

.gap-x-3xl {
  --column-gap: var(--space-3xl);
  column-gap: var(--column-gap);
}

.gap-y-3xl {
  --row-gap: var(--space-3xl);
  row-gap: var(--row-gap);
}

.gap-4xl {
  --row-gap: var(--space-4xl);
  --column-gap: var(--space-4xl);
  gap: var(--row-gap) var(--column-gap);
}

.gap-x-4xl {
  --column-gap: var(--space-4xl);
  column-gap: var(--column-gap);
}

.gap-y-4xl {
  --row-gap: var(--space-4xl);
  row-gap: var(--row-gap);
}

.gap-0 {
  --row-gap: 0px;
  --column-gap: 0px;
  gap: 0px;
}

.gap-x-0 {
  --column-gap: 0px;
  column-gap: 0px;
}

.gap-y-0 {
  --row-gap: 0px;
  row-gap: 0px;
}

/* flexbox */
.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.flex-column {
  flex-direction: column;
}

.flex-column-reverse {
  flex-direction: column-reverse;
}

.flex-row {
  flex-direction: row;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-center {
  justify-content: center;
  align-items: center;
}

.flex-grow {
  flex-grow: 1;
}

.flex-grow-0 {
  flex-grow: 0;
}

.flex-shrink {
  flex-shrink: 1;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-basis-0 {
  flex-basis: 0;
}

/* justify-content */
.justify-start {
  justify-content: start;
}

.justify-end {
  justify-content: end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

/* align-items */
.items-center {
  align-items: center;
}

.items-start {
  align-items: start;
}

.items-end {
  align-items: end;
}

.items-baseline {
  align-items: baseline;
}

.items-stretch {
  align-items: stretch;
}

/* align-content */
.content-start {
  align-content: start;
}

.content-end {
  align-content: end;
}

.content-center {
  align-content: center;
}

.content-between {
  align-content: space-between;
}

/* order */
.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

/* aspect-ratio */
.aspect-ratio-16\:9 {
  aspect-ratio: 16/9;
}

.aspect-ratio-3\:2 {
  aspect-ratio: 3/2;
}

.aspect-ratio-4\:3 {
  aspect-ratio: 4/3;
}

.aspect-ratio-5\:4 {
  aspect-ratio: 5/4;
}

.aspect-ratio-1\:1 {
  aspect-ratio: 1/1;
}

.aspect-ratio-4\:5 {
  aspect-ratio: 4/5;
}

.aspect-ratio-3\:4 {
  aspect-ratio: 3/4;
}

.aspect-ratio-2\:3 {
  aspect-ratio: 2/3;
}

.aspect-ratio-9\:16 {
  aspect-ratio: 9/16;
}

/* media wrapper */
:where([class^=media-wrapper], [class*=" media-wrapper"]) {
  position: relative;
}

[class^=media-wrapper], [class*=" media-wrapper"] {
  height: 0;
}

[class^=media-wrapper] > *,
[class*=" media-wrapper"] > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

[class^=media-wrapper] > *:not(iframe),
[class*=" media-wrapper"] > *:not(iframe) {
  object-fit: cover;
}

.media-wrapper-16\:9 {
  padding-bottom: 56.25%;
}

.media-wrapper-3\:2 {
  padding-bottom: 66.6666666667%;
}

.media-wrapper-4\:3 {
  padding-bottom: 75%;
}

.media-wrapper-1\:1 {
  padding-bottom: 100%;
}

/* display */
.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.contents {
  display: contents;
}

.css-grid {
  display: grid;
}

.css-inline-grid {
  display: inline-grid;
}

.hide {
  display: none;
}

.is-visible {
  display: var(--display, block) !important;
}

.is-hidden {
  display: none !important;
}

/* margin */
.margin-4xs {
  margin: var(--space-4xs);
}

.margin-top-4xs {
  margin-top: var(--space-4xs);
}

.margin-right-4xs {
  margin-right: var(--space-4xs);
}

.margin-bottom-4xs {
  margin-bottom: var(--space-4xs);
}

.margin-left-4xs {
  margin-left: var(--space-4xs);
}

.margin-y-4xs {
  margin-top: var(--space-4xs);
  margin-bottom: var(--space-4xs);
}

.margin-x-4xs {
  margin-right: var(--space-4xs);
  margin-left: var(--space-4xs);
}

.margin-3xs {
  margin: var(--space-3xs);
}

.margin-top-3xs {
  margin-top: var(--space-3xs);
}

.margin-right-3xs {
  margin-right: var(--space-3xs);
}

.margin-bottom-3xs {
  margin-bottom: var(--space-3xs);
}

.margin-left-3xs {
  margin-left: var(--space-3xs);
}

.margin-y-3xs {
  margin-top: var(--space-3xs);
  margin-bottom: var(--space-3xs);
}

.margin-x-3xs {
  margin-right: var(--space-3xs);
  margin-left: var(--space-3xs);
}

.margin-2xs {
  margin: var(--space-2xs);
}

.margin-top-2xs {
  margin-top: var(--space-2xs);
}

.margin-right-2xs {
  margin-right: var(--space-2xs);
}

.margin-bottom-2xs {
  margin-bottom: var(--space-2xs);
}

.margin-left-2xs {
  margin-left: var(--space-2xs);
}

.margin-y-2xs {
  margin-top: var(--space-2xs);
  margin-bottom: var(--space-2xs);
}

.margin-x-2xs {
  margin-right: var(--space-2xs);
  margin-left: var(--space-2xs);
}

.margin-xs {
  margin: var(--space-xs);
}

.margin-top-xs {
  margin-top: var(--space-xs);
}

.margin-right-xs {
  margin-right: var(--space-xs);
}

.margin-bottom-xs {
  margin-bottom: var(--space-xs);
}

.margin-left-xs {
  margin-left: var(--space-xs);
}

.margin-y-xs {
  margin-top: var(--space-xs);
  margin-bottom: var(--space-xs);
}

.margin-x-xs {
  margin-right: var(--space-xs);
  margin-left: var(--space-xs);
}

.margin-sm {
  margin: var(--space-sm);
}

.margin-top-sm {
  margin-top: var(--space-sm);
}

.margin-right-sm {
  margin-right: var(--space-sm);
}

.margin-bottom-sm {
  margin-bottom: var(--space-sm);
}

.margin-left-sm {
  margin-left: var(--space-sm);
}

.margin-y-sm {
  margin-top: var(--space-sm);
  margin-bottom: var(--space-sm);
}

.margin-x-sm {
  margin-right: var(--space-sm);
  margin-left: var(--space-sm);
}

.margin-md {
  margin: var(--space-md);
}

.margin-top-md {
  margin-top: var(--space-md);
}

.margin-right-md {
  margin-right: var(--space-md);
}

.margin-bottom-md {
  margin-bottom: var(--space-md);
}

.margin-left-md {
  margin-left: var(--space-md);
}

.margin-y-md {
  margin-top: var(--space-md);
  margin-bottom: var(--space-md);
}

.margin-x-md {
  margin-right: var(--space-md);
  margin-left: var(--space-md);
}

.margin-lg {
  margin: var(--space-lg);
}

.margin-top-lg {
  margin-top: var(--space-lg);
}

.margin-right-lg {
  margin-right: var(--space-lg);
}

.margin-bottom-lg {
  margin-bottom: var(--space-lg);
}

.margin-left-lg {
  margin-left: var(--space-lg);
}

.margin-y-lg {
  margin-top: var(--space-lg);
  margin-bottom: var(--space-lg);
}

.margin-x-lg {
  margin-right: var(--space-lg);
  margin-left: var(--space-lg);
}

.margin-xl {
  margin: var(--space-xl);
}

.margin-top-xl {
  margin-top: var(--space-xl);
}

.margin-right-xl {
  margin-right: var(--space-xl);
}

.margin-bottom-xl {
  margin-bottom: var(--space-xl);
}

.margin-left-xl {
  margin-left: var(--space-xl);
}

.margin-y-xl {
  margin-top: var(--space-xl);
  margin-bottom: var(--space-xl);
}

.margin-x-xl {
  margin-right: var(--space-xl);
  margin-left: var(--space-xl);
}

.margin-2xl {
  margin: var(--space-2xl);
}

.margin-top-2xl {
  margin-top: var(--space-2xl);
}

.margin-right-2xl {
  margin-right: var(--space-2xl);
}

.margin-bottom-2xl {
  margin-bottom: var(--space-2xl);
}

.margin-left-2xl {
  margin-left: var(--space-2xl);
}

.margin-y-2xl {
  margin-top: var(--space-2xl);
  margin-bottom: var(--space-2xl);
}

.margin-x-2xl {
  margin-right: var(--space-2xl);
  margin-left: var(--space-2xl);
}

.margin-3xl {
  margin: var(--space-3xl);
}

.margin-top-3xl {
  margin-top: var(--space-3xl);
}

.margin-right-3xl {
  margin-right: var(--space-3xl);
}

.margin-bottom-3xl {
  margin-bottom: var(--space-3xl);
}

.margin-left-3xl {
  margin-left: var(--space-3xl);
}

.margin-y-3xl {
  margin-top: var(--space-3xl);
  margin-bottom: var(--space-3xl);
}

.margin-x-3xl {
  margin-right: var(--space-3xl);
  margin-left: var(--space-3xl);
}

.margin-4xl {
  margin: var(--space-4xl);
}

.margin-top-4xl {
  margin-top: var(--space-4xl);
}

.margin-right-4xl {
  margin-right: var(--space-4xl);
}

.margin-bottom-4xl {
  margin-bottom: var(--space-4xl);
}

.margin-left-4xl {
  margin-left: var(--space-4xl);
}

.margin-y-4xl {
  margin-top: var(--space-4xl);
  margin-bottom: var(--space-4xl);
}

.margin-x-4xl {
  margin-right: var(--space-4xl);
  margin-left: var(--space-4xl);
}

.margin-auto {
  margin: auto;
}

.margin-0 {
  margin: 0;
}

.margin-y-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.margin-y-0 {
  margin-top: 0;
  margin-bottom: 0;
}

.margin-x-auto {
  margin-left: auto;
  margin-right: auto;
}

.margin-x-0 {
  margin-left: 0;
  margin-right: 0;
}

.margin-top-auto {
  margin-top: auto;
}

.margin-top-0 {
  margin-top: 0;
}

.margin-right-auto {
  margin-right: auto;
}

.margin-right-0 {
  margin-right: 0;
}

.margin-bottom-auto {
  margin-bottom: auto;
}

.margin-bottom-0 {
  margin-bottom: 0;
}

.margin-left-auto {
  margin-left: auto;
}

.margin-left-0 {
  margin-left: 0;
}

/* padding */
.padding-4xs {
  padding: var(--space-4xs);
}

.padding-top-4xs {
  padding-top: var(--space-4xs);
}

.padding-right-4xs {
  padding-right: var(--space-4xs);
}

.padding-bottom-4xs {
  padding-bottom: var(--space-4xs);
}

.padding-left-4xs {
  padding-left: var(--space-4xs);
}

.padding-y-4xs {
  padding-top: var(--space-4xs);
  padding-bottom: var(--space-4xs);
}

.padding-x-4xs {
  padding-right: var(--space-4xs);
  padding-left: var(--space-4xs);
}

.padding-3xs {
  padding: var(--space-3xs);
}

.padding-top-3xs {
  padding-top: var(--space-3xs);
}

.padding-right-3xs {
  padding-right: var(--space-3xs);
}

.padding-bottom-3xs {
  padding-bottom: var(--space-3xs);
}

.padding-left-3xs {
  padding-left: var(--space-3xs);
}

.padding-y-3xs {
  padding-top: var(--space-3xs);
  padding-bottom: var(--space-3xs);
}

.padding-x-3xs {
  padding-right: var(--space-3xs);
  padding-left: var(--space-3xs);
}

.padding-2xs {
  padding: var(--space-2xs);
}

.padding-top-2xs {
  padding-top: var(--space-2xs);
}

.padding-right-2xs {
  padding-right: var(--space-2xs);
}

.padding-bottom-2xs {
  padding-bottom: var(--space-2xs);
}

.padding-left-2xs {
  padding-left: var(--space-2xs);
}

.padding-y-2xs {
  padding-top: var(--space-2xs);
  padding-bottom: var(--space-2xs);
}

.padding-x-2xs {
  padding-right: var(--space-2xs);
  padding-left: var(--space-2xs);
}

.padding-xs {
  padding: var(--space-xs);
}

.padding-top-xs {
  padding-top: var(--space-xs);
}

.padding-right-xs {
  padding-right: var(--space-xs);
}

.padding-bottom-xs {
  padding-bottom: var(--space-xs);
}

.padding-left-xs {
  padding-left: var(--space-xs);
}

.padding-y-xs {
  padding-top: var(--space-xs);
  padding-bottom: var(--space-xs);
}

.padding-x-xs {
  padding-right: var(--space-xs);
  padding-left: var(--space-xs);
}

.padding-sm {
  padding: var(--space-sm);
}

.padding-top-sm {
  padding-top: var(--space-sm);
}

.padding-right-sm {
  padding-right: var(--space-sm);
}

.padding-bottom-sm {
  padding-bottom: var(--space-sm);
}

.padding-left-sm {
  padding-left: var(--space-sm);
}

.padding-y-sm {
  padding-top: var(--space-sm);
  padding-bottom: var(--space-sm);
}

.padding-x-sm {
  padding-right: var(--space-sm);
  padding-left: var(--space-sm);
}

.padding-md {
  padding: var(--space-md);
}

.padding-top-md {
  padding-top: var(--space-md);
}

.padding-right-md {
  padding-right: var(--space-md);
}

.padding-bottom-md {
  padding-bottom: var(--space-md);
}

.padding-left-md {
  padding-left: var(--space-md);
}

.padding-y-md {
  padding-top: var(--space-md);
  padding-bottom: var(--space-md);
}

.padding-x-md {
  padding-right: var(--space-md);
  padding-left: var(--space-md);
}

.padding-lg {
  padding: var(--space-lg);
}

.padding-top-lg {
  padding-top: var(--space-lg);
}

.padding-right-lg {
  padding-right: var(--space-lg);
}

.padding-bottom-lg {
  padding-bottom: var(--space-lg);
}

.padding-left-lg {
  padding-left: var(--space-lg);
}

.padding-y-lg {
  padding-top: var(--space-lg);
  padding-bottom: var(--space-lg);
}

.padding-x-lg {
  padding-right: var(--space-lg);
  padding-left: var(--space-lg);
}

.padding-xl {
  padding: var(--space-xl);
}

.padding-top-xl {
  padding-top: var(--space-xl);
}

.padding-right-xl {
  padding-right: var(--space-xl);
}

.padding-bottom-xl {
  padding-bottom: var(--space-xl);
}

.padding-left-xl {
  padding-left: var(--space-xl);
}

.padding-y-xl {
  padding-top: var(--space-xl);
  padding-bottom: var(--space-xl);
}

.padding-x-xl {
  padding-right: var(--space-xl);
  padding-left: var(--space-xl);
}

.padding-2xl {
  padding: var(--space-2xl);
}

.padding-top-2xl {
  padding-top: var(--space-2xl);
}

.padding-right-2xl {
  padding-right: var(--space-2xl);
}

.padding-bottom-2xl {
  padding-bottom: var(--space-2xl);
}

.padding-left-2xl {
  padding-left: var(--space-2xl);
}

.padding-y-2xl {
  padding-top: var(--space-2xl);
  padding-bottom: var(--space-2xl);
}

.padding-x-2xl {
  padding-right: var(--space-2xl);
  padding-left: var(--space-2xl);
}

.padding-3xl {
  padding: var(--space-3xl);
}

.padding-top-3xl {
  padding-top: var(--space-3xl);
}

.padding-right-3xl {
  padding-right: var(--space-3xl);
}

.padding-bottom-3xl {
  padding-bottom: var(--space-3xl);
}

.padding-left-3xl {
  padding-left: var(--space-3xl);
}

.padding-y-3xl {
  padding-top: var(--space-3xl);
  padding-bottom: var(--space-3xl);
}

.padding-x-3xl {
  padding-right: var(--space-3xl);
  padding-left: var(--space-3xl);
}

.padding-4xl {
  padding: var(--space-4xl);
}

.padding-top-4xl {
  padding-top: var(--space-4xl);
}

.padding-right-4xl {
  padding-right: var(--space-4xl);
}

.padding-bottom-4xl {
  padding-bottom: var(--space-4xl);
}

.padding-left-4xl {
  padding-left: var(--space-4xl);
}

.padding-y-4xl {
  padding-top: var(--space-4xl);
  padding-bottom: var(--space-4xl);
}

.padding-x-4xl {
  padding-right: var(--space-4xl);
  padding-left: var(--space-4xl);
}

.padding-0 {
  padding: 0;
}

.padding-y-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.padding-x-0 {
  padding-left: 0;
  padding-right: 0;
}

.padding-top-0 {
  padding-top: 0;
}

.padding-right-0 {
  padding-right: 0;
}

.padding-bottom-0 {
  padding-bottom: 0;
}

.padding-left-0 {
  padding-left: 0;
}

/* vertical-align */
.align-baseline {
  vertical-align: baseline;
}

.align-sub {
  vertical-align: sub;
}

.align-super {
  vertical-align: super;
}

.align-text-top {
  vertical-align: text-top;
}

.align-text-bottom {
  vertical-align: text-bottom;
}

.align-top {
  vertical-align: top;
}

.align-middle {
  vertical-align: middle;
}

.align-bottom {
  vertical-align: bottom;
}

/* typography */
.truncate, .text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-replace {
  overflow: hidden;
  color: transparent;
  text-indent: 100%;
  white-space: nowrap;
}

.break-word {
  overflow-wrap: break-word;
  min-width: 0;
}

/* font-size */
.text-xs {
  font-size: var(--text-xs);
}

.text-sm {
  font-size: var(--text-sm);
}

.text-base {
  font-size: var(--text-base);
}

.text-md {
  font-size: var(--text-md);
}

.text-lg {
  font-size: var(--text-lg);
}

.text-xl {
  font-size: var(--text-xl);
}

.text-2xl {
  font-size: var(--text-2xl);
}

.text-3xl {
  font-size: var(--text-3xl);
}

.text-4xl {
  font-size: var(--text-4xl);
}

/* text-transform */
.text-uppercase {
  text-transform: uppercase;
}

.text-capitalize {
  text-transform: capitalize;
}

/* letter-spacing */
.letter-spacing-xs {
  letter-spacing: -0.1em;
}

.letter-spacing-sm {
  letter-spacing: -0.05em;
}

.letter-spacing-md {
  letter-spacing: 0.05em;
}

.letter-spacing-lg {
  letter-spacing: 0.1em;
}

.letter-spacing-xl {
  letter-spacing: 0.2em;
}

/* font-weight */
.font-thin {
  font-weight: 100;
}

.font-extralight {
  font-weight: 200;
}

.font-light {
  font-weight: 300;
}

.font-normal {
  font-weight: 400;
}

.font-medium {
  font-weight: 500;
}

.font-semibold {
  font-weight: 600;
}

.font-bold {
  font-weight: 700;
}

.font-extrabold {
  font-weight: 800;
}

.font-black {
  font-weight: 900;
}

/* font-style */
.font-italic {
  font-style: italic;
}

/* font-smooth */
.font-smooth {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.font-primary {
  font-family: var(--font-primary);
}

.font-secondary {
  font-family: var(--font-secondary);
}

/* text-align */
.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-justify {
  text-align: justify;
}

/* text-decoration */
.text-line-through {
  text-decoration: line-through;
}

.text-underline {
  text-decoration: underline;
}

.text-decoration-none {
  text-decoration: none;
}

/* text-shadow */
.text-shadow-xs {
  text-shadow: 0 1px 1px hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), 0.15);
}

.text-shadow-sm {
  text-shadow: 0 1px 2px hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), 0.25);
}

.text-shadow-md {
  text-shadow: 0 1px 2px hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), 0.1), 0 2px 4px hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), 0.2);
}

.text-shadow-lg {
  text-shadow: 0 1px 4px hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), 0.1), 0 2px 8px hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), 0.15), 0 4px 16px hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), 0.2);
}

.text-shadow-xl {
  text-shadow: 0 1px 4px hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), 0.1), 0 2px 8px hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), 0.15), 0 4px 16px hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), 0.2), 0 6px 24px hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), 0.25);
}

.text-shadow-none {
  text-shadow: none;
}

/* line-height */
.line-height-normal {
  line-height: normal;
}

.line-height-1 {
  line-height: 1;
}

.line-height-xs {
  line-height: var(--line-height-xs);
}

.line-height-sm {
  line-height: var(--line-height-sm);
}

.line-height-md {
  line-height: var(--line-height-md);
}

.line-height-lg {
  line-height: var(--line-height-lg);
}

.line-height-xl {
  line-height: var(--line-height-xl);
}

/* white-space */
.ws-nowrap {
  white-space: nowrap;
}

/* column-count */
.column-count-1 {
  column-count: 1;
}

.column-count-2 {
  column-count: 2;
}

.column-count-3 {
  column-count: 3;
}

.column-count-4 {
  column-count: 4;
}

/* cursor */
.cursor-pointer {
  cursor: pointer;
}

.cursor-default {
  cursor: default;
}

/* pointer-events */
.pointer-events-auto {
  pointer-events: auto;
}

.pointer-events-none {
  pointer-events: none;
}

/* user-select */
.user-select-none {
  user-select: none;
}

.user-select-all {
  user-select: all;
}

/* color */
[class^=color-], [class*=" color-"] {
  --color-opacity: 1;
}

.color-inherit {
  color: inherit;
}

.color-primary-darker {
  color: hsla(var(--color-primary-darker-h), var(--color-primary-darker-s), var(--color-primary-darker-l), var(--color-opacity, 1));
}

.color-primary-dark {
  color: hsla(var(--color-primary-dark-h), var(--color-primary-dark-s), var(--color-primary-dark-l), var(--color-opacity, 1));
}

.color-primary {
  color: hsla(var(--color-primary-h), var(--color-primary-s), var(--color-primary-l), var(--color-opacity, 1));
}

.color-primary-light {
  color: hsla(var(--color-primary-light-h), var(--color-primary-light-s), var(--color-primary-light-l), var(--color-opacity, 1));
}

.color-primary-lighter {
  color: hsla(var(--color-primary-lighter-h), var(--color-primary-lighter-s), var(--color-primary-lighter-l), var(--color-opacity, 1));
}

.color-accent-darker {
  color: hsla(var(--color-accent-darker-h), var(--color-accent-darker-s), var(--color-accent-darker-l), var(--color-opacity, 1));
}

.color-accent-dark {
  color: hsla(var(--color-accent-dark-h), var(--color-accent-dark-s), var(--color-accent-dark-l), var(--color-opacity, 1));
}

.color-accent {
  color: hsla(var(--color-accent-h), var(--color-accent-s), var(--color-accent-l), var(--color-opacity, 1));
}

.color-accent-light {
  color: hsla(var(--color-accent-light-h), var(--color-accent-light-s), var(--color-accent-light-l), var(--color-opacity, 1));
}

.color-accent-lighter {
  color: hsla(var(--color-accent-lighter-h), var(--color-accent-lighter-s), var(--color-accent-lighter-l), var(--color-opacity, 1));
}

.color-black {
  color: hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), var(--color-opacity, 1));
}

.color-white {
  color: hsla(var(--color-white-h), var(--color-white-s), var(--color-white-l), var(--color-opacity, 1));
}

.color-warning-darker {
  color: hsla(var(--color-warning-darker-h), var(--color-warning-darker-s), var(--color-warning-darker-l), var(--color-opacity, 1));
}

.color-warning-dark {
  color: hsla(var(--color-warning-dark-h), var(--color-warning-dark-s), var(--color-warning-dark-l), var(--color-opacity, 1));
}

.color-warning {
  color: hsla(var(--color-warning-h), var(--color-warning-s), var(--color-warning-l), var(--color-opacity, 1));
}

.color-warning-light {
  color: hsla(var(--color-warning-light-h), var(--color-warning-light-s), var(--color-warning-light-l), var(--color-opacity, 1));
}

.color-warning-lighter {
  color: hsla(var(--color-warning-lighter-h), var(--color-warning-lighter-s), var(--color-warning-lighter-l), var(--color-opacity, 1));
}

.color-success-darker {
  color: hsla(var(--color-success-darker-h), var(--color-success-darker-s), var(--color-success-darker-l), var(--color-opacity, 1));
}

.color-success-dark {
  color: hsla(var(--color-success-dark-h), var(--color-success-dark-s), var(--color-success-dark-l), var(--color-opacity, 1));
}

.color-success {
  color: hsla(var(--color-success-h), var(--color-success-s), var(--color-success-l), var(--color-opacity, 1));
}

.color-success-light {
  color: hsla(var(--color-success-light-h), var(--color-success-light-s), var(--color-success-light-l), var(--color-opacity, 1));
}

.color-success-lighter {
  color: hsla(var(--color-success-lighter-h), var(--color-success-lighter-s), var(--color-success-lighter-l), var(--color-opacity, 1));
}

.color-error-darker {
  color: hsla(var(--color-error-darker-h), var(--color-error-darker-s), var(--color-error-darker-l), var(--color-opacity, 1));
}

.color-error-dark {
  color: hsla(var(--color-error-dark-h), var(--color-error-dark-s), var(--color-error-dark-l), var(--color-opacity, 1));
}

.color-error {
  color: hsla(var(--color-error-h), var(--color-error-s), var(--color-error-l), var(--color-opacity, 1));
}

.color-error-light {
  color: hsla(var(--color-error-light-h), var(--color-error-light-s), var(--color-error-light-l), var(--color-opacity, 1));
}

.color-error-lighter {
  color: hsla(var(--color-error-lighter-h), var(--color-error-lighter-s), var(--color-error-lighter-l), var(--color-opacity, 1));
}

.color-bg-darker {
  color: hsla(var(--color-bg-darker-h), var(--color-bg-darker-s), var(--color-bg-darker-l), var(--color-opacity, 1));
}

.color-bg-dark {
  color: hsla(var(--color-bg-dark-h), var(--color-bg-dark-s), var(--color-bg-dark-l), var(--color-opacity, 1));
}

.color-bg {
  color: hsla(var(--color-bg-h), var(--color-bg-s), var(--color-bg-l), var(--color-opacity, 1));
}

.color-bg-light {
  color: hsla(var(--color-bg-light-h), var(--color-bg-light-s), var(--color-bg-light-l), var(--color-opacity, 1));
}

.color-bg-lighter {
  color: hsla(var(--color-bg-lighter-h), var(--color-bg-lighter-s), var(--color-bg-lighter-l), var(--color-opacity, 1));
}

.color-contrast-lower {
  color: hsla(var(--color-contrast-lower-h), var(--color-contrast-lower-s), var(--color-contrast-lower-l), var(--color-opacity, 1));
}

.color-contrast-low {
  color: hsla(var(--color-contrast-low-h), var(--color-contrast-low-s), var(--color-contrast-low-l), var(--color-opacity, 1));
}

.color-contrast-medium {
  color: hsla(var(--color-contrast-medium-h), var(--color-contrast-medium-s), var(--color-contrast-medium-l), var(--color-opacity, 1));
}

.color-contrast-high {
  color: hsla(var(--color-contrast-high-h), var(--color-contrast-high-s), var(--color-contrast-high-l), var(--color-opacity, 1));
}

.color-contrast-higher {
  color: hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), var(--color-opacity, 1));
}

.color-opacity-0 {
  --color-opacity: 0;
}

.color-opacity-5\% {
  --color-opacity: 0.05;
}

.color-opacity-10\% {
  --color-opacity: 0.1;
}

.color-opacity-15\% {
  --color-opacity: 0.15;
}

.color-opacity-20\% {
  --color-opacity: 0.2;
}

.color-opacity-25\% {
  --color-opacity: 0.25;
}

.color-opacity-30\% {
  --color-opacity: 0.3;
}

.color-opacity-40\% {
  --color-opacity: 0.4;
}

.color-opacity-50\% {
  --color-opacity: 0.5;
}

.color-opacity-60\% {
  --color-opacity: 0.6;
}

.color-opacity-70\% {
  --color-opacity: 0.7;
}

.color-opacity-75\% {
  --color-opacity: 0.75;
}

.color-opacity-80\% {
  --color-opacity: 0.8;
}

.color-opacity-85\% {
  --color-opacity: 0.85;
}

.color-opacity-90\% {
  --color-opacity: 0.9;
}

.color-opacity-95\% {
  --color-opacity: 0.95;
}

/* gradient */
[class^=color-gradient], [class*=" color-gradient"] {
  color: transparent !important;
  background-clip: text;
}

/* width */
.width-4xs {
  width: 0.25rem;
}

.width-3xs {
  width: 0.5rem;
}

.width-2xs {
  width: 0.75rem;
}

.width-xs {
  width: 1rem;
}

.width-sm {
  width: 1.5rem;
}

.width-md {
  width: 2rem;
}

.width-lg {
  width: 3rem;
}

.width-xl {
  width: 4rem;
}

.width-2xl {
  width: 6rem;
}

.width-3xl {
  width: 8rem;
}

.width-4xl {
  width: 16rem;
}

.width-0 {
  width: 0;
}

.width-10\% {
  width: 10%;
}

.width-20\% {
  width: 20%;
}

.width-25\% {
  width: 25%;
}

.width-30\% {
  width: 30%;
}

.width-33\% {
  width: 33%;
}

.width-40\% {
  width: 40%;
}

.width-50\% {
  width: 50%;
}

.width-60\% {
  width: 60%;
}

.width-70\% {
  width: 70%;
}

.width-75\% {
  width: 75%;
}

.width-80\% {
  width: 80%;
}

.width-90\% {
  width: 90%;
}

.width-100\% {
  width: 100%;
}

.width-100vw {
  width: 100vw;
}

.width-auto {
  width: auto;
}

.width-inherit {
  width: inherit;
}

/* height */
.height-4xs {
  height: 0.25rem;
}

.height-3xs {
  height: 0.5rem;
}

.height-2xs {
  height: 0.75rem;
}

.height-xs {
  height: 1rem;
}

.height-sm {
  height: 1.5rem;
}

.height-md {
  height: 2rem;
}

.height-lg {
  height: 3rem;
}

.height-xl {
  height: 4rem;
}

.height-2xl {
  height: 6rem;
}

.height-3xl {
  height: 8rem;
}

.height-4xl {
  height: 16rem;
}

.height-0 {
  height: 0;
}

.height-10\% {
  height: 10%;
}

.height-20\% {
  height: 20%;
}

.height-25\% {
  height: 25%;
}

.height-30\% {
  height: 30%;
}

.height-33\% {
  height: 33%;
}

.height-40\% {
  height: 40%;
}

.height-50\% {
  height: 50%;
}

.height-60\% {
  height: 60%;
}

.height-70\% {
  height: 70%;
}

.height-75\% {
  height: 75%;
}

.height-80\% {
  height: 80%;
}

.height-90\% {
  height: 90%;
}

.height-100\% {
  height: 100%;
}

.height-100vh {
  height: 100vh;
}

.height-auto {
  height: auto;
}

.height-inherit {
  height: inherit;
}

/* min-width */
.min-width-0 {
  min-width: 0;
}

.min-width-25\% {
  min-width: 25%;
}

.min-width-33\% {
  min-width: 33.3333333333%;
}

.min-width-50\% {
  min-width: 50%;
}

.min-width-66\% {
  min-width: 66.6666666667%;
}

.min-width-75\% {
  min-width: 75%;
}

.min-width-100\% {
  min-width: 100%;
}

.min-width-100vw {
  min-width: 100vw;
}

/* min-height */
.min-height-0 {
  min-height: 0;
}

.min-height-100\% {
  min-height: 100%;
}

.min-height-100vh {
  min-height: 100vh;
}

/* max-width */
.container {
  width: calc(100% - 2 * var(--container-margin-x));
  margin-left: auto;
  margin-right: auto;
}

.max-width-3xs {
  max-width: var(--max-width-3xs);
}

.max-width-2xs {
  max-width: var(--max-width-2xs);
}

.max-width-xs {
  max-width: var(--max-width-xs);
}

.max-width-sm {
  max-width: var(--max-width-sm);
}

.max-width-md {
  max-width: var(--max-width-md);
}

.max-width-lg {
  max-width: var(--max-width-lg);
}

.max-width-xl {
  max-width: var(--max-width-xl);
}

.max-width-100\% {
  max-width: 100%;
}

.max-width-none {
  max-width: none;
}

[class^=max-width-adaptive], [class*=" max-width-adaptive"] {
  max-width: 32rem;
}

@media (min-width: 48rem) {
  .max-width-adaptive-sm, .max-width-adaptive-md, .max-width-adaptive-lg, .max-width-adaptive-xl {
    max-width: 48rem;
  }
}
@media (min-width: 64rem) {
  .max-width-adaptive-md, .max-width-adaptive-lg, .max-width-adaptive-xl {
    max-width: 64rem;
  }
}
@media (min-width: 80rem) {
  .max-width-adaptive-lg, .max-width-adaptive-xl {
    max-width: 80rem;
  }
}
@media (min-width: 90rem) {
  .max-width-adaptive-xl {
    max-width: 90rem;
  }
}
/* max-height */
.max-height-100\% {
  max-height: 100%;
}

.max-height-100vh {
  max-height: 100vh;
}

/* box-shadow */
.shadow-ring {
  box-shadow: var(--shadow-ring);
}

.shadow-xs {
  box-shadow: var(--shadow-xs);
}

.shadow-xs.shadow-ring {
  box-shadow: var(--shadow-xs), var(--shadow-ring);
}

.shadow-sm {
  box-shadow: var(--shadow-sm);
}

.shadow-sm.shadow-ring {
  box-shadow: var(--shadow-sm), var(--shadow-ring);
}

.shadow-md {
  box-shadow: var(--shadow-md);
}

.shadow-md.shadow-ring {
  box-shadow: var(--shadow-md), var(--shadow-ring);
}

.shadow-lg {
  box-shadow: var(--shadow-lg);
}

.shadow-lg.shadow-ring {
  box-shadow: var(--shadow-lg), var(--shadow-ring);
}

.shadow-xl {
  box-shadow: var(--shadow-xl);
}

.shadow-xl.shadow-ring {
  box-shadow: var(--shadow-xl), var(--shadow-ring);
}

.shadow-none {
  box-shadow: none;
}

/* inner-glow */
:where(.inner-glow, .inner-glow-top) {
  position: relative;
}

.inner-glow::after, .inner-glow-top::after {
  content: "";
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  pointer-events: none;
}

.inner-glow::after {
  box-shadow: var(--inner-glow);
}

.inner-glow-top::after {
  box-shadow: var(--inner-glow-top);
}

/* position */
.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

.position-fixed {
  position: fixed;
}

.position-sticky {
  position: sticky;
}

.position-static {
  position: static;
}

.inset-0 {
  inset: 0;
}

.top-4xs {
  top: var(--space-4xs);
}

.right-4xs {
  right: var(--space-4xs);
}

.bottom-4xs {
  bottom: var(--space-4xs);
}

.left-4xs {
  left: var(--space-4xs);
}

.top-3xs {
  top: var(--space-3xs);
}

.right-3xs {
  right: var(--space-3xs);
}

.bottom-3xs {
  bottom: var(--space-3xs);
}

.left-3xs {
  left: var(--space-3xs);
}

.top-2xs {
  top: var(--space-2xs);
}

.right-2xs {
  right: var(--space-2xs);
}

.bottom-2xs {
  bottom: var(--space-2xs);
}

.left-2xs {
  left: var(--space-2xs);
}

.top-xs {
  top: var(--space-xs);
}

.right-xs {
  right: var(--space-xs);
}

.bottom-xs {
  bottom: var(--space-xs);
}

.left-xs {
  left: var(--space-xs);
}

.top-sm {
  top: var(--space-sm);
}

.right-sm {
  right: var(--space-sm);
}

.bottom-sm {
  bottom: var(--space-sm);
}

.left-sm {
  left: var(--space-sm);
}

.top-md {
  top: var(--space-md);
}

.right-md {
  right: var(--space-md);
}

.bottom-md {
  bottom: var(--space-md);
}

.left-md {
  left: var(--space-md);
}

.top-lg {
  top: var(--space-lg);
}

.right-lg {
  right: var(--space-lg);
}

.bottom-lg {
  bottom: var(--space-lg);
}

.left-lg {
  left: var(--space-lg);
}

.top-xl {
  top: var(--space-xl);
}

.right-xl {
  right: var(--space-xl);
}

.bottom-xl {
  bottom: var(--space-xl);
}

.left-xl {
  left: var(--space-xl);
}

.top-2xl {
  top: var(--space-2xl);
}

.right-2xl {
  right: var(--space-2xl);
}

.bottom-2xl {
  bottom: var(--space-2xl);
}

.left-2xl {
  left: var(--space-2xl);
}

.top-3xl {
  top: var(--space-3xl);
}

.right-3xl {
  right: var(--space-3xl);
}

.bottom-3xl {
  bottom: var(--space-3xl);
}

.left-3xl {
  left: var(--space-3xl);
}

.top-4xl {
  top: var(--space-4xl);
}

.right-4xl {
  right: var(--space-4xl);
}

.bottom-4xl {
  bottom: var(--space-4xl);
}

.left-4xl {
  left: var(--space-4xl);
}

.top-50\% {
  top: 50%;
}

.top-auto {
  top: auto;
}

.top-0 {
  top: 0;
}

.right-50\% {
  right: 50%;
}

.right-auto {
  right: auto;
}

.right-0 {
  right: 0;
}

.bottom-50\% {
  bottom: 50%;
}

.bottom-auto {
  bottom: auto;
}

.bottom-0 {
  bottom: 0;
}

.left-50\% {
  left: 50%;
}

.left-auto {
  left: auto;
}

.left-0 {
  left: 0;
}

/* z-index */
.z-index-1 {
  z-index: 1;
}

.z-index-2 {
  z-index: 2;
}

.z-index-3 {
  z-index: 3;
}

.z-index-header {
  z-index: var(--z-index-header);
}

.z-index-popover {
  z-index: var(--z-index-popover);
}

.z-index-fixed-element {
  z-index: var(--z-index-fixed-element);
}

.z-index-overlay {
  z-index: var(--z-index-overlay);
}

/* overflow */
.overflow-visible {
  overflow: visible;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-auto {
  overflow: auto;
}

.momentum-scrolling {
  -webkit-overflow-scrolling: touch;
}

/* overscroll-behavior */
.overscroll-contain {
  overscroll-behavior: contain;
}

/* scroll behavior */
.scroll-smooth {
  scroll-behavior: smooth;
}

.scroll-padding-4xs {
  scroll-padding: var(--space-4xs);
}

.scroll-padding-3xs {
  scroll-padding: var(--space-3xs);
}

.scroll-padding-2xs {
  scroll-padding: var(--space-2xs);
}

.scroll-padding-xs {
  scroll-padding: var(--space-xs);
}

.scroll-padding-sm {
  scroll-padding: var(--space-sm);
}

.scroll-padding-md {
  scroll-padding: var(--space-md);
}

.scroll-padding-lg {
  scroll-padding: var(--space-lg);
}

.scroll-padding-xl {
  scroll-padding: var(--space-xl);
}

.scroll-padding-2xl {
  scroll-padding: var(--space-2xl);
}

.scroll-padding-3xl {
  scroll-padding: var(--space-3xl);
}

.scroll-padding-4xl {
  scroll-padding: var(--space-4xl);
}

/* accessibility */
.sr-only, .sr-only-focusable:not(:focus):not(:focus-within) {
  position: absolute;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  width: 1px;
  height: 1px;
  overflow: hidden;
  padding: 0;
  border: 0;
  white-space: nowrap;
}

/* opacity */
.opacity-0 {
  opacity: 0;
}

.opacity-5\% {
  opacity: 0.05;
}

.opacity-10\% {
  opacity: 0.1;
}

.opacity-15\% {
  opacity: 0.15;
}

.opacity-20\% {
  opacity: 0.2;
}

.opacity-25\% {
  opacity: 0.25;
}

.opacity-30\% {
  opacity: 0.3;
}

.opacity-40\% {
  opacity: 0.4;
}

.opacity-50\% {
  opacity: 0.5;
}

.opacity-60\% {
  opacity: 0.6;
}

.opacity-70\% {
  opacity: 0.7;
}

.opacity-75\% {
  opacity: 0.75;
}

.opacity-80\% {
  opacity: 0.8;
}

.opacity-85\% {
  opacity: 0.85;
}

.opacity-90\% {
  opacity: 0.9;
}

.opacity-95\% {
  opacity: 0.95;
}

/* float */
.float-left {
  float: left;
}

.float-right {
  float: right;
}

.clearfix::after {
  content: "";
  display: table;
  clear: both;
}

/* border */
[class^=border-], [class*=" border-"] {
  --border-opacity: 1;
  --border-width: 1px;
  --border-style: solid;
}

.border {
  border: var(--border-width, 1px) var(--border-style, solid) hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), var(--border-opacity-base, 0.1));
}

.border-top {
  border-top: var(--border-width, 1px) var(--border-style, solid) hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), var(--border-opacity-base, 0.1));
}

.border-bottom {
  border-bottom: var(--border-width, 1px) var(--border-style, solid) hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), var(--border-opacity-base, 0.1));
}

.border-left {
  border-left: var(--border-width, 1px) var(--border-style, solid) hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), var(--border-opacity-base, 0.1));
}

.border-right {
  border-right: var(--border-width, 1px) var(--border-style, solid) hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), var(--border-opacity-base, 0.1));
}

.border-2 {
  --border-width: 2px;
}

.border-3 {
  --border-width: 3px;
}

.border-4 {
  --border-width: 4px;
}

.border-dotted {
  --border-style: dotted;
}

.border-dashed {
  --border-style: dashed;
}

.border-primary-darker {
  border-color: hsla(var(--color-primary-darker-h), var(--color-primary-darker-s), var(--color-primary-darker-l), var(--border-opacity, 1));
}

.border-primary-dark {
  border-color: hsla(var(--color-primary-dark-h), var(--color-primary-dark-s), var(--color-primary-dark-l), var(--border-opacity, 1));
}

.border-primary {
  border-color: hsla(var(--color-primary-h), var(--color-primary-s), var(--color-primary-l), var(--border-opacity, 1));
}

.border-primary-light {
  border-color: hsla(var(--color-primary-light-h), var(--color-primary-light-s), var(--color-primary-light-l), var(--border-opacity, 1));
}

.border-primary-lighter {
  border-color: hsla(var(--color-primary-lighter-h), var(--color-primary-lighter-s), var(--color-primary-lighter-l), var(--border-opacity, 1));
}

.border-accent-darker {
  border-color: hsla(var(--color-accent-darker-h), var(--color-accent-darker-s), var(--color-accent-darker-l), var(--border-opacity, 1));
}

.border-accent-dark {
  border-color: hsla(var(--color-accent-dark-h), var(--color-accent-dark-s), var(--color-accent-dark-l), var(--border-opacity, 1));
}

.border-accent {
  border-color: hsla(var(--color-accent-h), var(--color-accent-s), var(--color-accent-l), var(--border-opacity, 1));
}

.border-accent-light {
  border-color: hsla(var(--color-accent-light-h), var(--color-accent-light-s), var(--color-accent-light-l), var(--border-opacity, 1));
}

.border-accent-lighter {
  border-color: hsla(var(--color-accent-lighter-h), var(--color-accent-lighter-s), var(--color-accent-lighter-l), var(--border-opacity, 1));
}

.border-black {
  border-color: hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), var(--border-opacity, 1));
}

.border-white {
  border-color: hsla(var(--color-white-h), var(--color-white-s), var(--color-white-l), var(--border-opacity, 1));
}

.border-warning-darker {
  border-color: hsla(var(--color-warning-darker-h), var(--color-warning-darker-s), var(--color-warning-darker-l), var(--border-opacity, 1));
}

.border-warning-dark {
  border-color: hsla(var(--color-warning-dark-h), var(--color-warning-dark-s), var(--color-warning-dark-l), var(--border-opacity, 1));
}

.border-warning {
  border-color: hsla(var(--color-warning-h), var(--color-warning-s), var(--color-warning-l), var(--border-opacity, 1));
}

.border-warning-light {
  border-color: hsla(var(--color-warning-light-h), var(--color-warning-light-s), var(--color-warning-light-l), var(--border-opacity, 1));
}

.border-warning-lighter {
  border-color: hsla(var(--color-warning-lighter-h), var(--color-warning-lighter-s), var(--color-warning-lighter-l), var(--border-opacity, 1));
}

.border-success-darker {
  border-color: hsla(var(--color-success-darker-h), var(--color-success-darker-s), var(--color-success-darker-l), var(--border-opacity, 1));
}

.border-success-dark {
  border-color: hsla(var(--color-success-dark-h), var(--color-success-dark-s), var(--color-success-dark-l), var(--border-opacity, 1));
}

.border-success {
  border-color: hsla(var(--color-success-h), var(--color-success-s), var(--color-success-l), var(--border-opacity, 1));
}

.border-success-light {
  border-color: hsla(var(--color-success-light-h), var(--color-success-light-s), var(--color-success-light-l), var(--border-opacity, 1));
}

.border-success-lighter {
  border-color: hsla(var(--color-success-lighter-h), var(--color-success-lighter-s), var(--color-success-lighter-l), var(--border-opacity, 1));
}

.border-error-darker {
  border-color: hsla(var(--color-error-darker-h), var(--color-error-darker-s), var(--color-error-darker-l), var(--border-opacity, 1));
}

.border-error-dark {
  border-color: hsla(var(--color-error-dark-h), var(--color-error-dark-s), var(--color-error-dark-l), var(--border-opacity, 1));
}

.border-error {
  border-color: hsla(var(--color-error-h), var(--color-error-s), var(--color-error-l), var(--border-opacity, 1));
}

.border-error-light {
  border-color: hsla(var(--color-error-light-h), var(--color-error-light-s), var(--color-error-light-l), var(--border-opacity, 1));
}

.border-error-lighter {
  border-color: hsla(var(--color-error-lighter-h), var(--color-error-lighter-s), var(--color-error-lighter-l), var(--border-opacity, 1));
}

.border-bg-darker {
  border-color: hsla(var(--color-bg-darker-h), var(--color-bg-darker-s), var(--color-bg-darker-l), var(--border-opacity, 1));
}

.border-bg-dark {
  border-color: hsla(var(--color-bg-dark-h), var(--color-bg-dark-s), var(--color-bg-dark-l), var(--border-opacity, 1));
}

.border-bg {
  border-color: hsla(var(--color-bg-h), var(--color-bg-s), var(--color-bg-l), var(--border-opacity, 1));
}

.border-bg-light {
  border-color: hsla(var(--color-bg-light-h), var(--color-bg-light-s), var(--color-bg-light-l), var(--border-opacity, 1));
}

.border-bg-lighter {
  border-color: hsla(var(--color-bg-lighter-h), var(--color-bg-lighter-s), var(--color-bg-lighter-l), var(--border-opacity, 1));
}

.border-contrast-lower {
  border-color: hsla(var(--color-contrast-lower-h), var(--color-contrast-lower-s), var(--color-contrast-lower-l), var(--border-opacity, 1));
}

.border-contrast-low {
  border-color: hsla(var(--color-contrast-low-h), var(--color-contrast-low-s), var(--color-contrast-low-l), var(--border-opacity, 1));
}

.border-contrast-medium {
  border-color: hsla(var(--color-contrast-medium-h), var(--color-contrast-medium-s), var(--color-contrast-medium-l), var(--border-opacity, 1));
}

.border-contrast-high {
  border-color: hsla(var(--color-contrast-high-h), var(--color-contrast-high-s), var(--color-contrast-high-l), var(--border-opacity, 1));
}

.border-contrast-higher {
  border-color: hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), var(--border-opacity, 1));
}

.border-opacity-0 {
  --border-opacity: 0;
}

.border-opacity-5\% {
  --border-opacity: 0.05;
}

.border-opacity-10\% {
  --border-opacity: 0.1;
}

.border-opacity-15\% {
  --border-opacity: 0.15;
}

.border-opacity-20\% {
  --border-opacity: 0.2;
}

.border-opacity-25\% {
  --border-opacity: 0.25;
}

.border-opacity-30\% {
  --border-opacity: 0.3;
}

.border-opacity-40\% {
  --border-opacity: 0.4;
}

.border-opacity-50\% {
  --border-opacity: 0.5;
}

.border-opacity-60\% {
  --border-opacity: 0.6;
}

.border-opacity-70\% {
  --border-opacity: 0.7;
}

.border-opacity-75\% {
  --border-opacity: 0.75;
}

.border-opacity-80\% {
  --border-opacity: 0.8;
}

.border-opacity-85\% {
  --border-opacity: 0.85;
}

.border-opacity-90\% {
  --border-opacity: 0.9;
}

.border-opacity-95\% {
  --border-opacity: 0.95;
}

/* border-radius */
.radius-sm {
  border-radius: var(--radius-sm);
}

.radius-md {
  border-radius: var(--radius-md);
}

.radius-lg {
  border-radius: var(--radius-lg);
}

.radius-50\% {
  border-radius: 50%;
}

.radius-full {
  border-radius: 50em;
}

.radius-0 {
  border-radius: 0;
}

.radius-inherit {
  border-radius: inherit;
}

.radius-top-left-0 {
  border-top-left-radius: 0;
}

.radius-top-right-0 {
  border-top-right-radius: 0;
}

.radius-bottom-right-0 {
  border-bottom-right-radius: 0;
}

.radius-bottom-left-0 {
  border-bottom-left-radius: 0;
}

/* background */
.bg, [class^=bg-], [class*=" bg-"] {
  --bg-opacity: 1;
}

.bg-transparent {
  background-color: transparent;
}

.bg-inherit {
  background-color: inherit;
}

.bg-primary-darker {
  background-color: hsla(var(--color-primary-darker-h), var(--color-primary-darker-s), var(--color-primary-darker-l), var(--bg-opacity, 1));
}

.bg-primary-dark {
  background-color: hsla(var(--color-primary-dark-h), var(--color-primary-dark-s), var(--color-primary-dark-l), var(--bg-opacity, 1));
}

.bg-primary {
  background-color: hsla(var(--color-primary-h), var(--color-primary-s), var(--color-primary-l), var(--bg-opacity, 1));
}

.bg-primary-light {
  background-color: hsla(var(--color-primary-light-h), var(--color-primary-light-s), var(--color-primary-light-l), var(--bg-opacity, 1));
}

.bg-primary-lighter {
  background-color: hsla(var(--color-primary-lighter-h), var(--color-primary-lighter-s), var(--color-primary-lighter-l), var(--bg-opacity, 1));
}

.bg-accent-darker {
  background-color: hsla(var(--color-accent-darker-h), var(--color-accent-darker-s), var(--color-accent-darker-l), var(--bg-opacity, 1));
}

.bg-accent-dark {
  background-color: hsla(var(--color-accent-dark-h), var(--color-accent-dark-s), var(--color-accent-dark-l), var(--bg-opacity, 1));
}

.bg-accent {
  background-color: hsla(var(--color-accent-h), var(--color-accent-s), var(--color-accent-l), var(--bg-opacity, 1));
}

.bg-accent-light {
  background-color: hsla(var(--color-accent-light-h), var(--color-accent-light-s), var(--color-accent-light-l), var(--bg-opacity, 1));
}

.bg-accent-lighter {
  background-color: hsla(var(--color-accent-lighter-h), var(--color-accent-lighter-s), var(--color-accent-lighter-l), var(--bg-opacity, 1));
}

.bg-black {
  background-color: hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), var(--bg-opacity, 1));
}

.bg-white {
  background-color: hsla(var(--color-white-h), var(--color-white-s), var(--color-white-l), var(--bg-opacity, 1));
}

.bg-warning-darker {
  background-color: hsla(var(--color-warning-darker-h), var(--color-warning-darker-s), var(--color-warning-darker-l), var(--bg-opacity, 1));
}

.bg-warning-dark {
  background-color: hsla(var(--color-warning-dark-h), var(--color-warning-dark-s), var(--color-warning-dark-l), var(--bg-opacity, 1));
}

.bg-warning {
  background-color: hsla(var(--color-warning-h), var(--color-warning-s), var(--color-warning-l), var(--bg-opacity, 1));
}

.bg-warning-light {
  background-color: hsla(var(--color-warning-light-h), var(--color-warning-light-s), var(--color-warning-light-l), var(--bg-opacity, 1));
}

.bg-warning-lighter {
  background-color: hsla(var(--color-warning-lighter-h), var(--color-warning-lighter-s), var(--color-warning-lighter-l), var(--bg-opacity, 1));
}

.bg-success-darker {
  background-color: hsla(var(--color-success-darker-h), var(--color-success-darker-s), var(--color-success-darker-l), var(--bg-opacity, 1));
}

.bg-success-dark {
  background-color: hsla(var(--color-success-dark-h), var(--color-success-dark-s), var(--color-success-dark-l), var(--bg-opacity, 1));
}

.bg-success {
  background-color: hsla(var(--color-success-h), var(--color-success-s), var(--color-success-l), var(--bg-opacity, 1));
}

.bg-success-light {
  background-color: hsla(var(--color-success-light-h), var(--color-success-light-s), var(--color-success-light-l), var(--bg-opacity, 1));
}

.bg-success-lighter {
  background-color: hsla(var(--color-success-lighter-h), var(--color-success-lighter-s), var(--color-success-lighter-l), var(--bg-opacity, 1));
}

.bg-error-darker {
  background-color: hsla(var(--color-error-darker-h), var(--color-error-darker-s), var(--color-error-darker-l), var(--bg-opacity, 1));
}

.bg-error-dark {
  background-color: hsla(var(--color-error-dark-h), var(--color-error-dark-s), var(--color-error-dark-l), var(--bg-opacity, 1));
}

.bg-error {
  background-color: hsla(var(--color-error-h), var(--color-error-s), var(--color-error-l), var(--bg-opacity, 1));
}

.bg-error-light {
  background-color: hsla(var(--color-error-light-h), var(--color-error-light-s), var(--color-error-light-l), var(--bg-opacity, 1));
}

.bg-error-lighter {
  background-color: hsla(var(--color-error-lighter-h), var(--color-error-lighter-s), var(--color-error-lighter-l), var(--bg-opacity, 1));
}

.bg-darker {
  background-color: hsla(var(--color-bg-darker-h), var(--color-bg-darker-s), var(--color-bg-darker-l), var(--bg-opacity, 1));
}

.bg-dark {
  background-color: hsla(var(--color-bg-dark-h), var(--color-bg-dark-s), var(--color-bg-dark-l), var(--bg-opacity, 1));
}

.bg {
  background-color: hsla(var(--color-bg-h), var(--color-bg-s), var(--color-bg-l), var(--bg-opacity, 1));
}

.bg-light {
  background-color: hsla(var(--color-bg-light-h), var(--color-bg-light-s), var(--color-bg-light-l), var(--bg-opacity, 1));
}

.bg-lighter {
  background-color: hsla(var(--color-bg-lighter-h), var(--color-bg-lighter-s), var(--color-bg-lighter-l), var(--bg-opacity, 1));
}

.bg-contrast-lower {
  background-color: hsla(var(--color-contrast-lower-h), var(--color-contrast-lower-s), var(--color-contrast-lower-l), var(--bg-opacity, 1));
}

.bg-contrast-low {
  background-color: hsla(var(--color-contrast-low-h), var(--color-contrast-low-s), var(--color-contrast-low-l), var(--bg-opacity, 1));
}

.bg-contrast-medium {
  background-color: hsla(var(--color-contrast-medium-h), var(--color-contrast-medium-s), var(--color-contrast-medium-l), var(--bg-opacity, 1));
}

.bg-contrast-high {
  background-color: hsla(var(--color-contrast-high-h), var(--color-contrast-high-s), var(--color-contrast-high-l), var(--bg-opacity, 1));
}

.bg-contrast-higher {
  background-color: hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), var(--bg-opacity, 1));
}

.bg-opacity-0 {
  --bg-opacity: 0;
}

.bg-opacity-5\% {
  --bg-opacity: 0.05;
}

.bg-opacity-10\% {
  --bg-opacity: 0.1;
}

.bg-opacity-15\% {
  --bg-opacity: 0.15;
}

.bg-opacity-20\% {
  --bg-opacity: 0.2;
}

.bg-opacity-25\% {
  --bg-opacity: 0.25;
}

.bg-opacity-30\% {
  --bg-opacity: 0.3;
}

.bg-opacity-40\% {
  --bg-opacity: 0.4;
}

.bg-opacity-50\% {
  --bg-opacity: 0.5;
}

.bg-opacity-60\% {
  --bg-opacity: 0.6;
}

.bg-opacity-70\% {
  --bg-opacity: 0.7;
}

.bg-opacity-75\% {
  --bg-opacity: 0.75;
}

.bg-opacity-80\% {
  --bg-opacity: 0.8;
}

.bg-opacity-85\% {
  --bg-opacity: 0.85;
}

.bg-opacity-90\% {
  --bg-opacity: 0.9;
}

.bg-opacity-95\% {
  --bg-opacity: 0.95;
}

.bg-center {
  background-position: center;
}

.bg-top {
  background-position: center top;
}

.bg-right {
  background-position: right center;
}

.bg-bottom {
  background-position: center bottom;
}

.bg-left {
  background-position: left center;
}

.bg-top-left {
  background-position: left top;
}

.bg-top-right {
  background-position: right top;
}

.bg-bottom-left {
  background-position: left bottom;
}

.bg-bottom-right {
  background-position: right bottom;
}

.bg-cover {
  background-size: cover;
}

.bg-no-repeat {
  background-repeat: no-repeat;
}

/* backdrop-filter */
.backdrop-blur-10 {
  backdrop-filter: blur(10px);
}

.backdrop-blur-20 {
  backdrop-filter: blur(20px);
}

/* mix-blend-mode */
.isolate {
  isolation: isolate;
}

.blend-multiply {
  mix-blend-mode: multiply;
}

.blend-overlay {
  mix-blend-mode: overlay;
}

.blend-difference {
  mix-blend-mode: difference;
}

/* object-fit */
.object-contain {
  object-fit: contain;
}

.object-cover {
  object-fit: cover;
}

/* perspective */
.perspective-xs {
  perspective: 250px;
}

.perspective-sm {
  perspective: 500px;
}

.perspective-md {
  perspective: 1000px;
}

.perspective-lg {
  perspective: 1500px;
}

.perspective-xl {
  perspective: 3000px;
}

/* transform */
[class^=flip], [class*=" flip"],
[class^=-rotate], [class*=" -rotate"],
[class^=rotate], [class*=" rotate"],
[class^=-translate], [class*=" -translate"],
[class^=translate], [class*=" translate"],
[class^=-scale], [class*=" -scale"],
[class^=scale], [class*=" scale"],
[class^=-skew], [class*=" -skew"] [class^=skew],
[class*=" skew"] {
  --translate: 0;
  --rotate: 0;
  --skew: 0;
  --scale: 1;
  transform: translate3d(var(--translate-x, var(--translate)), var(--translate-y, var(--translate)), var(--translate-z, 0)) rotateX(var(--rotate-x, 0)) rotateY(var(--rotate-y, 0)) rotateZ(var(--rotate-z, var(--rotate))) skewX(var(--skew-x, var(--skew))) skewY(var(--skew-y, 0)) scaleX(var(--scale-x, var(--scale))) scaleY(var(--scale-y, var(--scale)));
}

.flip {
  --scale: -1;
}

.flip-x {
  --scale-x: -1;
}

.flip-y {
  --scale-y: -1;
}

.rotate-90 {
  --rotate: 90deg;
}

.rotate-180 {
  --rotate: 180deg;
}

.rotate-270 {
  --rotate: 270deg;
}

.-translate-50\% {
  --translate: -50%;
}

.-translate-x-50\% {
  --translate-x: -50%;
}

.-translate-y-50\% {
  --translate-y: -50%;
}

.-translate-y-slight {
  --translate-y: -0.1em;
}

.translate-50\% {
  --translate: 50%;
}

.translate-x-50\% {
  --translate-x: 50%;
}

.translate-y-50\% {
  --translate-y: 50%;
}

/* transform-origin */
.origin-center {
  transform-origin: center;
}

.origin-top {
  transform-origin: center top;
}

.origin-right {
  transform-origin: right center;
}

.origin-bottom {
  transform-origin: center bottom;
}

.origin-left {
  transform-origin: left center;
}

.origin-top-left {
  transform-origin: left top;
}

.origin-top-right {
  transform-origin: right top;
}

.origin-bottom-left {
  transform-origin: left bottom;
}

.origin-bottom-right {
  transform-origin: right bottom;
}

/* transition */
.transition {
  transition-property: var(--transition-property, all);
  transition-duration: var(--transition-duration, 0.2s);
  transition-delay: var(--transition-delay, 0s);
}

/* SVG */
.fill-current {
  fill: currentColor;
}

.stroke-current {
  stroke: currentColor;
}

.stroke-1 {
  stroke-width: 1px;
}

.stroke-2 {
  stroke-width: 2px;
}

.stroke-3 {
  stroke-width: 3px;
}

.stroke-4 {
  stroke-width: 4px;
}

/* visibility */
.visible {
  visibility: visible;
}

.invisible {
  visibility: hidden;
}

/* print */
@media print {
  .print\:hide, .print\:is-hidden {
    display: none !important;
  }
  .print\:shadow-none {
    box-shadow: none;
  }
}
/* appearance */
.appearance-none {
  appearance: none;
}

.appearance-auto {
  appearance: auto;
}

@media (min-width: 32rem) {
  /* grid */
  .grid-col-1\@xs {
    --grid-columns: 1;
  }
  .col-1\@xs {
    --column-span: 1;
  }
  .grid-col-2\@xs {
    --grid-columns: 2;
  }
  .col-2\@xs {
    --column-span: 2;
  }
  .grid-col-3\@xs {
    --grid-columns: 3;
  }
  .col-3\@xs {
    --column-span: 3;
  }
  .grid-col-4\@xs {
    --grid-columns: 4;
  }
  .col-4\@xs {
    --column-span: 4;
  }
  .grid-col-5\@xs {
    --grid-columns: 5;
  }
  .col-5\@xs {
    --column-span: 5;
  }
  .grid-col-6\@xs {
    --grid-columns: 6;
  }
  .col-6\@xs {
    --column-span: 6;
  }
  .grid-col-7\@xs {
    --grid-columns: 7;
  }
  .col-7\@xs {
    --column-span: 7;
  }
  .grid-col-8\@xs {
    --grid-columns: 8;
  }
  .col-8\@xs {
    --column-span: 8;
  }
  .grid-col-9\@xs {
    --grid-columns: 9;
  }
  .col-9\@xs {
    --column-span: 9;
  }
  .grid-col-10\@xs {
    --grid-columns: 10;
  }
  .col-10\@xs {
    --column-span: 10;
  }
  .grid-col-11\@xs {
    --grid-columns: 11;
  }
  .col-11\@xs {
    --column-span: 11;
  }
  .grid-col-12\@xs {
    --grid-columns: 12;
  }
  .col-12\@xs {
    --column-span: 12;
  }
  .col-1\@xs, .col-2\@xs, .col-3\@xs, .col-4\@xs, .col-5\@xs, .col-6\@xs, .col-7\@xs, .col-8\@xs, .col-9\@xs, .col-10\@xs, .col-11\@xs, .col-12\@xs {
    width: calc((100% - (var(--grid-columns) - 1) * var(--column-gap)) * var(--column-span) / var(--grid-columns) + (var(--column-span) - 1) * var(--column-gap));
    flex: initial;
  }
  .col\@xs {
    width: auto;
    flex: 1 1 0;
  }
  .col-content\@xs {
    width: auto;
    flex: 0 1 auto;
  }
  .offset-1\@xs {
    --column-offset: 1;
  }
  .offset-2\@xs {
    --column-offset: 2;
  }
  .offset-3\@xs {
    --column-offset: 3;
  }
  .offset-4\@xs {
    --column-offset: 4;
  }
  .offset-5\@xs {
    --column-offset: 5;
  }
  .offset-6\@xs {
    --column-offset: 6;
  }
  .offset-7\@xs {
    --column-offset: 7;
  }
  .offset-8\@xs {
    --column-offset: 8;
  }
  .offset-9\@xs {
    --column-offset: 9;
  }
  .offset-10\@xs {
    --column-offset: 10;
  }
  .offset-11\@xs {
    --column-offset: 11;
  }
  .offset-1\@xs, .offset-2\@xs, .offset-3\@xs, .offset-4\@xs, .offset-5\@xs, .offset-6\@xs, .offset-7\@xs, .offset-8\@xs, .offset-9\@xs, .offset-10\@xs, .offset-11\@xs {
    margin-left: calc((100% - (var(--grid-columns) - 1) * var(--column-gap)) * var(--column-offset) / var(--grid-columns) + var(--column-offset) * var(--column-gap));
  }
  .offset-0\@xs {
    margin-left: 0;
  }
  /* gap */
  .gap-4xs\@xs {
    --row-gap: var(--space-4xs);
    --column-gap: var(--space-4xs);
    gap: var(--row-gap) var(--column-gap);
  }
  .gap-x-4xs\@xs {
    --column-gap: var(--space-4xs);
    column-gap: var(--column-gap);
  }
  .gap-y-4xs\@xs {
    --row-gap: var(--space-4xs);
    row-gap: var(--row-gap);
  }
  .gap-3xs\@xs {
    --row-gap: var(--space-3xs);
    --column-gap: var(--space-3xs);
    gap: var(--row-gap) var(--column-gap);
  }
  .gap-x-3xs\@xs {
    --column-gap: var(--space-3xs);
    column-gap: var(--column-gap);
  }
  .gap-y-3xs\@xs {
    --row-gap: var(--space-3xs);
    row-gap: var(--row-gap);
  }
  .gap-2xs\@xs {
    --row-gap: var(--space-2xs);
    --column-gap: var(--space-2xs);
    gap: var(--row-gap) var(--column-gap);
  }
  .gap-x-2xs\@xs {
    --column-gap: var(--space-2xs);
    column-gap: var(--column-gap);
  }
  .gap-y-2xs\@xs {
    --row-gap: var(--space-2xs);
    row-gap: var(--row-gap);
  }
  .gap-xs\@xs {
    --row-gap: var(--space-xs);
    --column-gap: var(--space-xs);
    gap: var(--row-gap) var(--column-gap);
  }
  .gap-x-xs\@xs {
    --column-gap: var(--space-xs);
    column-gap: var(--column-gap);
  }
  .gap-y-xs\@xs {
    --row-gap: var(--space-xs);
    row-gap: var(--row-gap);
  }
  .gap-sm\@xs {
    --row-gap: var(--space-sm);
    --column-gap: var(--space-sm);
    gap: var(--row-gap) var(--column-gap);
  }
  .gap-x-sm\@xs {
    --column-gap: var(--space-sm);
    column-gap: var(--column-gap);
  }
  .gap-y-sm\@xs {
    --row-gap: var(--space-sm);
    row-gap: var(--row-gap);
  }
  .gap-md\@xs {
    --row-gap: var(--space-md);
    --column-gap: var(--space-md);
    gap: var(--row-gap) var(--column-gap);
  }
  .gap-x-md\@xs {
    --column-gap: var(--space-md);
    column-gap: var(--column-gap);
  }
  .gap-y-md\@xs {
    --row-gap: var(--space-md);
    row-gap: var(--row-gap);
  }
  .gap-lg\@xs {
    --row-gap: var(--space-lg);
    --column-gap: var(--space-lg);
    gap: var(--row-gap) var(--column-gap);
  }
  .gap-x-lg\@xs {
    --column-gap: var(--space-lg);
    column-gap: var(--column-gap);
  }
  .gap-y-lg\@xs {
    --row-gap: var(--space-lg);
    row-gap: var(--row-gap);
  }
  .gap-xl\@xs {
    --row-gap: var(--space-xl);
    --column-gap: var(--space-xl);
    gap: var(--row-gap) var(--column-gap);
  }
  .gap-x-xl\@xs {
    --column-gap: var(--space-xl);
    column-gap: var(--column-gap);
  }
  .gap-y-xl\@xs {
    --row-gap: var(--space-xl);
    row-gap: var(--row-gap);
  }
  .gap-2xl\@xs {
    --row-gap: var(--space-2xl);
    --column-gap: var(--space-2xl);
    gap: var(--row-gap) var(--column-gap);
  }
  .gap-x-2xl\@xs {
    --column-gap: var(--space-2xl);
    column-gap: var(--column-gap);
  }
  .gap-y-2xl\@xs {
    --row-gap: var(--space-2xl);
    row-gap: var(--row-gap);
  }
  .gap-3xl\@xs {
    --row-gap: var(--space-3xl);
    --column-gap: var(--space-3xl);
    gap: var(--row-gap) var(--column-gap);
  }
  .gap-x-3xl\@xs {
    --column-gap: var(--space-3xl);
    column-gap: var(--column-gap);
  }
  .gap-y-3xl\@xs {
    --row-gap: var(--space-3xl);
    row-gap: var(--row-gap);
  }
  .gap-4xl\@xs {
    --row-gap: var(--space-4xl);
    --column-gap: var(--space-4xl);
    gap: var(--row-gap) var(--column-gap);
  }
  .gap-x-4xl\@xs {
    --column-gap: var(--space-4xl);
    column-gap: var(--column-gap);
  }
  .gap-y-4xl\@xs {
    --row-gap: var(--space-4xl);
    row-gap: var(--row-gap);
  }
  .gap-0\@xs {
    --row-gap: 0px;
    --column-gap: 0px;
    gap: 0px;
  }
  .gap-x-0\@xs {
    --column-gap: 0px;
    column-gap: 0px;
  }
  .gap-y-0\@xs {
    --row-gap: 0px;
    row-gap: 0px;
  }
  /* flexbox */
  .flex\@xs {
    display: flex;
  }
  .inline-flex\@xs {
    display: inline-flex;
  }
  .flex-wrap\@xs {
    flex-wrap: wrap;
  }
  .flex-nowrap\@xs {
    flex-wrap: nowrap;
  }
  .flex-column\@xs {
    flex-direction: column;
  }
  .flex-column-reverse\@xs {
    flex-direction: column-reverse;
  }
  .flex-row\@xs {
    flex-direction: row;
  }
  .flex-row-reverse\@xs {
    flex-direction: row-reverse;
  }
  .flex-center\@xs {
    justify-content: center;
    align-items: center;
  }
  .flex-grow\@xs {
    flex-grow: 1;
  }
  .flex-grow-0\@xs {
    flex-grow: 0;
  }
  .flex-shrink\@xs {
    flex-shrink: 1;
  }
  .flex-shrink-0\@xs {
    flex-shrink: 0;
  }
  .flex-basis-0\@xs {
    flex-basis: 0;
  }
  /* justify-content */
  .justify-start\@xs {
    justify-content: start;
  }
  .justify-end\@xs {
    justify-content: end;
  }
  .justify-center\@xs {
    justify-content: center;
  }
  .justify-between\@xs {
    justify-content: space-between;
  }
  /* align-items */
  .items-center\@xs {
    align-items: center;
  }
  .items-start\@xs {
    align-items: start;
  }
  .items-end\@xs {
    align-items: end;
  }
  .items-baseline\@xs {
    align-items: baseline;
  }
  .items-stretch\@xs {
    align-items: stretch;
  }
  /* align-content */
  .content-start\@xs {
    align-content: start;
  }
  .content-end\@xs {
    align-content: end;
  }
  .content-center\@xs {
    align-content: center;
  }
  .content-between\@xs {
    align-content: space-between;
  }
  /* order */
  .order-1\@xs {
    order: 1;
  }
  .order-2\@xs {
    order: 2;
  }
  .order-3\@xs {
    order: 3;
  }
  /* display */
  .block\@xs {
    display: block;
  }
  .inline-block\@xs {
    display: inline-block;
  }
  .inline\@xs {
    display: inline;
  }
  .contents\@xs {
    display: contents;
  }
  .css-grid\@xs {
    display: grid;
  }
  .css-inline-grid\@xs {
    display: inline-grid;
  }
  .hide\@xs {
    display: none;
  }
  /* margin */
  .margin-4xs\@xs {
    margin: var(--space-4xs);
  }
  .margin-top-4xs\@xs {
    margin-top: var(--space-4xs);
  }
  .margin-right-4xs\@xs {
    margin-right: var(--space-4xs);
  }
  .margin-bottom-4xs\@xs {
    margin-bottom: var(--space-4xs);
  }
  .margin-left-4xs\@xs {
    margin-left: var(--space-4xs);
  }
  .margin-y-4xs\@xs {
    margin-top: var(--space-4xs);
    margin-bottom: var(--space-4xs);
  }
  .margin-x-4xs\@xs {
    margin-right: var(--space-4xs);
    margin-left: var(--space-4xs);
  }
  .margin-3xs\@xs {
    margin: var(--space-3xs);
  }
  .margin-top-3xs\@xs {
    margin-top: var(--space-3xs);
  }
  .margin-right-3xs\@xs {
    margin-right: var(--space-3xs);
  }
  .margin-bottom-3xs\@xs {
    margin-bottom: var(--space-3xs);
  }
  .margin-left-3xs\@xs {
    margin-left: var(--space-3xs);
  }
  .margin-y-3xs\@xs {
    margin-top: var(--space-3xs);
    margin-bottom: var(--space-3xs);
  }
  .margin-x-3xs\@xs {
    margin-right: var(--space-3xs);
    margin-left: var(--space-3xs);
  }
  .margin-2xs\@xs {
    margin: var(--space-2xs);
  }
  .margin-top-2xs\@xs {
    margin-top: var(--space-2xs);
  }
  .margin-right-2xs\@xs {
    margin-right: var(--space-2xs);
  }
  .margin-bottom-2xs\@xs {
    margin-bottom: var(--space-2xs);
  }
  .margin-left-2xs\@xs {
    margin-left: var(--space-2xs);
  }
  .margin-y-2xs\@xs {
    margin-top: var(--space-2xs);
    margin-bottom: var(--space-2xs);
  }
  .margin-x-2xs\@xs {
    margin-right: var(--space-2xs);
    margin-left: var(--space-2xs);
  }
  .margin-xs\@xs {
    margin: var(--space-xs);
  }
  .margin-top-xs\@xs {
    margin-top: var(--space-xs);
  }
  .margin-right-xs\@xs {
    margin-right: var(--space-xs);
  }
  .margin-bottom-xs\@xs {
    margin-bottom: var(--space-xs);
  }
  .margin-left-xs\@xs {
    margin-left: var(--space-xs);
  }
  .margin-y-xs\@xs {
    margin-top: var(--space-xs);
    margin-bottom: var(--space-xs);
  }
  .margin-x-xs\@xs {
    margin-right: var(--space-xs);
    margin-left: var(--space-xs);
  }
  .margin-sm\@xs {
    margin: var(--space-sm);
  }
  .margin-top-sm\@xs {
    margin-top: var(--space-sm);
  }
  .margin-right-sm\@xs {
    margin-right: var(--space-sm);
  }
  .margin-bottom-sm\@xs {
    margin-bottom: var(--space-sm);
  }
  .margin-left-sm\@xs {
    margin-left: var(--space-sm);
  }
  .margin-y-sm\@xs {
    margin-top: var(--space-sm);
    margin-bottom: var(--space-sm);
  }
  .margin-x-sm\@xs {
    margin-right: var(--space-sm);
    margin-left: var(--space-sm);
  }
  .margin-md\@xs {
    margin: var(--space-md);
  }
  .margin-top-md\@xs {
    margin-top: var(--space-md);
  }
  .margin-right-md\@xs {
    margin-right: var(--space-md);
  }
  .margin-bottom-md\@xs {
    margin-bottom: var(--space-md);
  }
  .margin-left-md\@xs {
    margin-left: var(--space-md);
  }
  .margin-y-md\@xs {
    margin-top: var(--space-md);
    margin-bottom: var(--space-md);
  }
  .margin-x-md\@xs {
    margin-right: var(--space-md);
    margin-left: var(--space-md);
  }
  .margin-lg\@xs {
    margin: var(--space-lg);
  }
  .margin-top-lg\@xs {
    margin-top: var(--space-lg);
  }
  .margin-right-lg\@xs {
    margin-right: var(--space-lg);
  }
  .margin-bottom-lg\@xs {
    margin-bottom: var(--space-lg);
  }
  .margin-left-lg\@xs {
    margin-left: var(--space-lg);
  }
  .margin-y-lg\@xs {
    margin-top: var(--space-lg);
    margin-bottom: var(--space-lg);
  }
  .margin-x-lg\@xs {
    margin-right: var(--space-lg);
    margin-left: var(--space-lg);
  }
  .margin-xl\@xs {
    margin: var(--space-xl);
  }
  .margin-top-xl\@xs {
    margin-top: var(--space-xl);
  }
  .margin-right-xl\@xs {
    margin-right: var(--space-xl);
  }
  .margin-bottom-xl\@xs {
    margin-bottom: var(--space-xl);
  }
  .margin-left-xl\@xs {
    margin-left: var(--space-xl);
  }
  .margin-y-xl\@xs {
    margin-top: var(--space-xl);
    margin-bottom: var(--space-xl);
  }
  .margin-x-xl\@xs {
    margin-right: var(--space-xl);
    margin-left: var(--space-xl);
  }
  .margin-2xl\@xs {
    margin: var(--space-2xl);
  }
  .margin-top-2xl\@xs {
    margin-top: var(--space-2xl);
  }
  .margin-right-2xl\@xs {
    margin-right: var(--space-2xl);
  }
  .margin-bottom-2xl\@xs {
    margin-bottom: var(--space-2xl);
  }
  .margin-left-2xl\@xs {
    margin-left: var(--space-2xl);
  }
  .margin-y-2xl\@xs {
    margin-top: var(--space-2xl);
    margin-bottom: var(--space-2xl);
  }
  .margin-x-2xl\@xs {
    margin-right: var(--space-2xl);
    margin-left: var(--space-2xl);
  }
  .margin-3xl\@xs {
    margin: var(--space-3xl);
  }
  .margin-top-3xl\@xs {
    margin-top: var(--space-3xl);
  }
  .margin-right-3xl\@xs {
    margin-right: var(--space-3xl);
  }
  .margin-bottom-3xl\@xs {
    margin-bottom: var(--space-3xl);
  }
  .margin-left-3xl\@xs {
    margin-left: var(--space-3xl);
  }
  .margin-y-3xl\@xs {
    margin-top: var(--space-3xl);
    margin-bottom: var(--space-3xl);
  }
  .margin-x-3xl\@xs {
    margin-right: var(--space-3xl);
    margin-left: var(--space-3xl);
  }
  .margin-4xl\@xs {
    margin: var(--space-4xl);
  }
  .margin-top-4xl\@xs {
    margin-top: var(--space-4xl);
  }
  .margin-right-4xl\@xs {
    margin-right: var(--space-4xl);
  }
  .margin-bottom-4xl\@xs {
    margin-bottom: var(--space-4xl);
  }
  .margin-left-4xl\@xs {
    margin-left: var(--space-4xl);
  }
  .margin-y-4xl\@xs {
    margin-top: var(--space-4xl);
    margin-bottom: var(--space-4xl);
  }
  .margin-x-4xl\@xs {
    margin-right: var(--space-4xl);
    margin-left: var(--space-4xl);
  }
  .margin-auto\@xs {
    margin: auto;
  }
  .margin-0\@xs {
    margin: 0;
  }
  .margin-y-auto\@xs {
    margin-top: auto;
    margin-bottom: auto;
  }
  .margin-y-0\@xs {
    margin-top: 0;
    margin-bottom: 0;
  }
  .margin-x-auto\@xs {
    margin-left: auto;
    margin-right: auto;
  }
  .margin-x-0\@xs {
    margin-left: 0;
    margin-right: 0;
  }
  .margin-top-auto\@xs {
    margin-top: auto;
  }
  .margin-top-0\@xs {
    margin-top: 0;
  }
  .margin-right-auto\@xs {
    margin-right: auto;
  }
  .margin-right-0\@xs {
    margin-right: 0;
  }
  .margin-bottom-auto\@xs {
    margin-bottom: auto;
  }
  .margin-bottom-0\@xs {
    margin-bottom: 0;
  }
  .margin-left-auto\@xs {
    margin-left: auto;
  }
  .margin-left-0\@xs {
    margin-left: 0;
  }
  /* padding */
  .padding-4xs\@xs {
    padding: var(--space-4xs);
  }
  .padding-top-4xs\@xs {
    padding-top: var(--space-4xs);
  }
  .padding-right-4xs\@xs {
    padding-right: var(--space-4xs);
  }
  .padding-bottom-4xs\@xs {
    padding-bottom: var(--space-4xs);
  }
  .padding-left-4xs\@xs {
    padding-left: var(--space-4xs);
  }
  .padding-y-4xs\@xs {
    padding-top: var(--space-4xs);
    padding-bottom: var(--space-4xs);
  }
  .padding-x-4xs\@xs {
    padding-right: var(--space-4xs);
    padding-left: var(--space-4xs);
  }
  .padding-3xs\@xs {
    padding: var(--space-3xs);
  }
  .padding-top-3xs\@xs {
    padding-top: var(--space-3xs);
  }
  .padding-right-3xs\@xs {
    padding-right: var(--space-3xs);
  }
  .padding-bottom-3xs\@xs {
    padding-bottom: var(--space-3xs);
  }
  .padding-left-3xs\@xs {
    padding-left: var(--space-3xs);
  }
  .padding-y-3xs\@xs {
    padding-top: var(--space-3xs);
    padding-bottom: var(--space-3xs);
  }
  .padding-x-3xs\@xs {
    padding-right: var(--space-3xs);
    padding-left: var(--space-3xs);
  }
  .padding-2xs\@xs {
    padding: var(--space-2xs);
  }
  .padding-top-2xs\@xs {
    padding-top: var(--space-2xs);
  }
  .padding-right-2xs\@xs {
    padding-right: var(--space-2xs);
  }
  .padding-bottom-2xs\@xs {
    padding-bottom: var(--space-2xs);
  }
  .padding-left-2xs\@xs {
    padding-left: var(--space-2xs);
  }
  .padding-y-2xs\@xs {
    padding-top: var(--space-2xs);
    padding-bottom: var(--space-2xs);
  }
  .padding-x-2xs\@xs {
    padding-right: var(--space-2xs);
    padding-left: var(--space-2xs);
  }
  .padding-xs\@xs {
    padding: var(--space-xs);
  }
  .padding-top-xs\@xs {
    padding-top: var(--space-xs);
  }
  .padding-right-xs\@xs {
    padding-right: var(--space-xs);
  }
  .padding-bottom-xs\@xs {
    padding-bottom: var(--space-xs);
  }
  .padding-left-xs\@xs {
    padding-left: var(--space-xs);
  }
  .padding-y-xs\@xs {
    padding-top: var(--space-xs);
    padding-bottom: var(--space-xs);
  }
  .padding-x-xs\@xs {
    padding-right: var(--space-xs);
    padding-left: var(--space-xs);
  }
  .padding-sm\@xs {
    padding: var(--space-sm);
  }
  .padding-top-sm\@xs {
    padding-top: var(--space-sm);
  }
  .padding-right-sm\@xs {
    padding-right: var(--space-sm);
  }
  .padding-bottom-sm\@xs {
    padding-bottom: var(--space-sm);
  }
  .padding-left-sm\@xs {
    padding-left: var(--space-sm);
  }
  .padding-y-sm\@xs {
    padding-top: var(--space-sm);
    padding-bottom: var(--space-sm);
  }
  .padding-x-sm\@xs {
    padding-right: var(--space-sm);
    padding-left: var(--space-sm);
  }
  .padding-md\@xs {
    padding: var(--space-md);
  }
  .padding-top-md\@xs {
    padding-top: var(--space-md);
  }
  .padding-right-md\@xs {
    padding-right: var(--space-md);
  }
  .padding-bottom-md\@xs {
    padding-bottom: var(--space-md);
  }
  .padding-left-md\@xs {
    padding-left: var(--space-md);
  }
  .padding-y-md\@xs {
    padding-top: var(--space-md);
    padding-bottom: var(--space-md);
  }
  .padding-x-md\@xs {
    padding-right: var(--space-md);
    padding-left: var(--space-md);
  }
  .padding-lg\@xs {
    padding: var(--space-lg);
  }
  .padding-top-lg\@xs {
    padding-top: var(--space-lg);
  }
  .padding-right-lg\@xs {
    padding-right: var(--space-lg);
  }
  .padding-bottom-lg\@xs {
    padding-bottom: var(--space-lg);
  }
  .padding-left-lg\@xs {
    padding-left: var(--space-lg);
  }
  .padding-y-lg\@xs {
    padding-top: var(--space-lg);
    padding-bottom: var(--space-lg);
  }
  .padding-x-lg\@xs {
    padding-right: var(--space-lg);
    padding-left: var(--space-lg);
  }
  .padding-xl\@xs {
    padding: var(--space-xl);
  }
  .padding-top-xl\@xs {
    padding-top: var(--space-xl);
  }
  .padding-right-xl\@xs {
    padding-right: var(--space-xl);
  }
  .padding-bottom-xl\@xs {
    padding-bottom: var(--space-xl);
  }
  .padding-left-xl\@xs {
    padding-left: var(--space-xl);
  }
  .padding-y-xl\@xs {
    padding-top: var(--space-xl);
    padding-bottom: var(--space-xl);
  }
  .padding-x-xl\@xs {
    padding-right: var(--space-xl);
    padding-left: var(--space-xl);
  }
  .padding-2xl\@xs {
    padding: var(--space-2xl);
  }
  .padding-top-2xl\@xs {
    padding-top: var(--space-2xl);
  }
  .padding-right-2xl\@xs {
    padding-right: var(--space-2xl);
  }
  .padding-bottom-2xl\@xs {
    padding-bottom: var(--space-2xl);
  }
  .padding-left-2xl\@xs {
    padding-left: var(--space-2xl);
  }
  .padding-y-2xl\@xs {
    padding-top: var(--space-2xl);
    padding-bottom: var(--space-2xl);
  }
  .padding-x-2xl\@xs {
    padding-right: var(--space-2xl);
    padding-left: var(--space-2xl);
  }
  .padding-3xl\@xs {
    padding: var(--space-3xl);
  }
  .padding-top-3xl\@xs {
    padding-top: var(--space-3xl);
  }
  .padding-right-3xl\@xs {
    padding-right: var(--space-3xl);
  }
  .padding-bottom-3xl\@xs {
    padding-bottom: var(--space-3xl);
  }
  .padding-left-3xl\@xs {
    padding-left: var(--space-3xl);
  }
  .padding-y-3xl\@xs {
    padding-top: var(--space-3xl);
    padding-bottom: var(--space-3xl);
  }
  .padding-x-3xl\@xs {
    padding-right: var(--space-3xl);
    padding-left: var(--space-3xl);
  }
  .padding-4xl\@xs {
    padding: var(--space-4xl);
  }
  .padding-top-4xl\@xs {
    padding-top: var(--space-4xl);
  }
  .padding-right-4xl\@xs {
    padding-right: var(--space-4xl);
  }
  .padding-bottom-4xl\@xs {
    padding-bottom: var(--space-4xl);
  }
  .padding-left-4xl\@xs {
    padding-left: var(--space-4xl);
  }
  .padding-y-4xl\@xs {
    padding-top: var(--space-4xl);
    padding-bottom: var(--space-4xl);
  }
  .padding-x-4xl\@xs {
    padding-right: var(--space-4xl);
    padding-left: var(--space-4xl);
  }
  .padding-0\@xs {
    padding: 0;
  }
  .padding-y-0\@xs {
    padding-top: 0;
    padding-bottom: 0;
  }
  .padding-x-0\@xs {
    padding-left: 0;
    padding-right: 0;
  }
  .padding-top-0\@xs {
    padding-top: 0;
  }
  .padding-right-0\@xs {
    padding-right: 0;
  }
  .padding-bottom-0\@xs {
    padding-bottom: 0;
  }
  .padding-left-0\@xs {
    padding-left: 0;
  }
  /* text-align */
  .text-center\@xs {
    text-align: center;
  }
  .text-left\@xs {
    text-align: left;
  }
  .text-right\@xs {
    text-align: right;
  }
  .text-justify\@xs {
    text-align: justify;
  }
  /* font-size */
  .text-xs\@xs {
    font-size: var(--text-xs);
  }
  .text-sm\@xs {
    font-size: var(--text-sm);
  }
  .text-base\@xs {
    font-size: var(--text-base);
  }
  .text-md\@xs {
    font-size: var(--text-md);
  }
  .text-lg\@xs {
    font-size: var(--text-lg);
  }
  .text-xl\@xs {
    font-size: var(--text-xl);
  }
  .text-2xl\@xs {
    font-size: var(--text-2xl);
  }
  .text-3xl\@xs {
    font-size: var(--text-3xl);
  }
  .text-4xl\@xs {
    font-size: var(--text-4xl);
  }
  /* column-count */
  .column-count-1\@xs {
    column-count: 1;
  }
  .column-count-2\@xs {
    column-count: 2;
  }
  .column-count-3\@xs {
    column-count: 3;
  }
  .column-count-4\@xs {
    column-count: 4;
  }
  /* width */
  .width-4xs\@xs {
    width: 0.25rem;
  }
  .width-3xs\@xs {
    width: 0.5rem;
  }
  .width-2xs\@xs {
    width: 0.75rem;
  }
  .width-xs\@xs {
    width: 1rem;
  }
  .width-sm\@xs {
    width: 1.5rem;
  }
  .width-md\@xs {
    width: 2rem;
  }
  .width-lg\@xs {
    width: 3rem;
  }
  .width-xl\@xs {
    width: 4rem;
  }
  .width-2xl\@xs {
    width: 6rem;
  }
  .width-3xl\@xs {
    width: 8rem;
  }
  .width-4xl\@xs {
    width: 16rem;
  }
  .width-0\@xs {
    width: 0;
  }
  .width-10\%\@xs {
    width: 10%;
  }
  .width-20\%\@xs {
    width: 20%;
  }
  .width-25\%\@xs {
    width: 25%;
  }
  .width-30\%\@xs {
    width: 30%;
  }
  .width-33\%\@xs {
    width: 33%;
  }
  .width-40\%\@xs {
    width: 40%;
  }
  .width-50\%\@xs {
    width: 50%;
  }
  .width-60\%\@xs {
    width: 60%;
  }
  .width-70\%\@xs {
    width: 70%;
  }
  .width-75\%\@xs {
    width: 75%;
  }
  .width-80\%\@xs {
    width: 80%;
  }
  .width-90\%\@xs {
    width: 90%;
  }
  .width-100\%\@xs {
    width: 100%;
  }
  .width-100\%\@xs {
    width: 100%;
  }
  .width-100vw\@xs {
    width: 100vw;
  }
  .width-auto\@xs {
    width: auto;
  }
  .width-inherit\@xs {
    width: inherit;
  }
  .width-0\@xs {
    width: 0;
  }
  /* height */
  .height-4xs\@xs {
    height: 0.25rem;
  }
  .height-3xs\@xs {
    height: 0.5rem;
  }
  .height-2xs\@xs {
    height: 0.75rem;
  }
  .height-xs\@xs {
    height: 1rem;
  }
  .height-sm\@xs {
    height: 1.5rem;
  }
  .height-md\@xs {
    height: 2rem;
  }
  .height-lg\@xs {
    height: 3rem;
  }
  .height-xl\@xs {
    height: 4rem;
  }
  .height-2xl\@xs {
    height: 6rem;
  }
  .height-3xl\@xs {
    height: 8rem;
  }
  .height-4xl\@xs {
    height: 16rem;
  }
  .height-0\@xs {
    height: 0;
  }
  .height-10\%\@xs {
    height: 10%;
  }
  .height-20\%\@xs {
    height: 20%;
  }
  .height-25\%\@xs {
    height: 25%;
  }
  .height-30\%\@xs {
    height: 30%;
  }
  .height-33\%\@xs {
    height: 33%;
  }
  .height-40\%\@xs {
    height: 40%;
  }
  .height-50\%\@xs {
    height: 50%;
  }
  .height-60\%\@xs {
    height: 60%;
  }
  .height-70\%\@xs {
    height: 70%;
  }
  .height-75\%\@xs {
    height: 75%;
  }
  .height-80\%\@xs {
    height: 80%;
  }
  .height-90\%\@xs {
    height: 90%;
  }
  .height-100\%\@xs {
    height: 100%;
  }
  .height-100\%\@xs {
    height: 100%;
  }
  .height-100vh\@xs {
    height: 100vh;
  }
  .height-auto\@xs {
    height: auto;
  }
  .height-inherit\@xs {
    height: inherit;
  }
  .height-0\@xs {
    height: 0;
  }
  /* max-width */
  .max-width-3xs\@xs {
    max-width: var(--max-width-3xs);
  }
  .max-width-2xs\@xs {
    max-width: var(--max-width-2xs);
  }
  .max-width-xs\@xs {
    max-width: var(--max-width-xs);
  }
  .max-width-sm\@xs {
    max-width: var(--max-width-sm);
  }
  .max-width-md\@xs {
    max-width: var(--max-width-md);
  }
  .max-width-lg\@xs {
    max-width: var(--max-width-lg);
  }
  .max-width-xl\@xs {
    max-width: var(--max-width-xl);
  }
  .max-width-100\%\@xs {
    max-width: 100%;
  }
  .max-width-none\@xs {
    max-width: none;
  }
  /* position */
  .position-relative\@xs {
    position: relative;
  }
  .position-absolute\@xs {
    position: absolute;
  }
  .position-fixed\@xs {
    position: fixed;
  }
  .position-sticky\@xs {
    position: sticky;
  }
  .position-static\@xs {
    position: static;
  }
  .inset-0\@xs {
    inset: 0;
  }
  .top-4xs\@xs {
    top: var(--space-4xs);
  }
  .right-4xs\@xs {
    right: var(--space-4xs);
  }
  .bottom-4xs\@xs {
    bottom: var(--space-4xs);
  }
  .left-4xs\@xs {
    left: var(--space-4xs);
  }
  .top-3xs\@xs {
    top: var(--space-3xs);
  }
  .right-3xs\@xs {
    right: var(--space-3xs);
  }
  .bottom-3xs\@xs {
    bottom: var(--space-3xs);
  }
  .left-3xs\@xs {
    left: var(--space-3xs);
  }
  .top-2xs\@xs {
    top: var(--space-2xs);
  }
  .right-2xs\@xs {
    right: var(--space-2xs);
  }
  .bottom-2xs\@xs {
    bottom: var(--space-2xs);
  }
  .left-2xs\@xs {
    left: var(--space-2xs);
  }
  .top-xs\@xs {
    top: var(--space-xs);
  }
  .right-xs\@xs {
    right: var(--space-xs);
  }
  .bottom-xs\@xs {
    bottom: var(--space-xs);
  }
  .left-xs\@xs {
    left: var(--space-xs);
  }
  .top-sm\@xs {
    top: var(--space-sm);
  }
  .right-sm\@xs {
    right: var(--space-sm);
  }
  .bottom-sm\@xs {
    bottom: var(--space-sm);
  }
  .left-sm\@xs {
    left: var(--space-sm);
  }
  .top-md\@xs {
    top: var(--space-md);
  }
  .right-md\@xs {
    right: var(--space-md);
  }
  .bottom-md\@xs {
    bottom: var(--space-md);
  }
  .left-md\@xs {
    left: var(--space-md);
  }
  .top-lg\@xs {
    top: var(--space-lg);
  }
  .right-lg\@xs {
    right: var(--space-lg);
  }
  .bottom-lg\@xs {
    bottom: var(--space-lg);
  }
  .left-lg\@xs {
    left: var(--space-lg);
  }
  .top-xl\@xs {
    top: var(--space-xl);
  }
  .right-xl\@xs {
    right: var(--space-xl);
  }
  .bottom-xl\@xs {
    bottom: var(--space-xl);
  }
  .left-xl\@xs {
    left: var(--space-xl);
  }
  .top-2xl\@xs {
    top: var(--space-2xl);
  }
  .right-2xl\@xs {
    right: var(--space-2xl);
  }
  .bottom-2xl\@xs {
    bottom: var(--space-2xl);
  }
  .left-2xl\@xs {
    left: var(--space-2xl);
  }
  .top-3xl\@xs {
    top: var(--space-3xl);
  }
  .right-3xl\@xs {
    right: var(--space-3xl);
  }
  .bottom-3xl\@xs {
    bottom: var(--space-3xl);
  }
  .left-3xl\@xs {
    left: var(--space-3xl);
  }
  .top-4xl\@xs {
    top: var(--space-4xl);
  }
  .right-4xl\@xs {
    right: var(--space-4xl);
  }
  .bottom-4xl\@xs {
    bottom: var(--space-4xl);
  }
  .left-4xl\@xs {
    left: var(--space-4xl);
  }
  .top-50\%\@xs {
    top: 50%;
  }
  .top-auto\@xs {
    top: auto;
  }
  .top-0\@xs {
    top: 0;
  }
  .right-50\%\@xs {
    right: 50%;
  }
  .right-auto\@xs {
    right: auto;
  }
  .right-0\@xs {
    right: 0;
  }
  .bottom-50\%\@xs {
    bottom: 50%;
  }
  .bottom-auto\@xs {
    bottom: auto;
  }
  .bottom-0\@xs {
    bottom: 0;
  }
  .left-50\%\@xs {
    left: 50%;
  }
  .left-auto\@xs {
    left: auto;
  }
  .left-0\@xs {
    left: 0;
  }
  /* overflow */
  .overflow-visible\@xs {
    overflow: visible;
  }
  .overflow-hidden\@xs {
    overflow: hidden;
  }
  .overflow-auto\@xs {
    overflow: auto;
  }
  .momentum-scrolling\@xs {
    -webkit-overflow-scrolling: touch;
  }
  /* visibility */
  .visible\@xs {
    visibility: visible;
  }
  .invisible\@xs {
    visibility: hidden;
  }
}
@media not all and (min-width: 32rem) {
  .display\@xs {
    display: none !important;
  }
}
@media (min-width: 48rem) {
  /* grid */
  .grid-col-1\@sm {
    --grid-columns: 1;
  }
  .col-1\@sm {
    --column-span: 1;
  }
  .grid-col-2\@sm {
    --grid-columns: 2;
  }
  .col-2\@sm {
    --column-span: 2;
  }
  .grid-col-3\@sm {
    --grid-columns: 3;
  }
  .col-3\@sm {
    --column-span: 3;
  }
  .grid-col-4\@sm {
    --grid-columns: 4;
  }
  .col-4\@sm {
    --column-span: 4;
  }
  .grid-col-5\@sm {
    --grid-columns: 5;
  }
  .col-5\@sm {
    --column-span: 5;
  }
  .grid-col-6\@sm {
    --grid-columns: 6;
  }
  .col-6\@sm {
    --column-span: 6;
  }
  .grid-col-7\@sm {
    --grid-columns: 7;
  }
  .col-7\@sm {
    --column-span: 7;
  }
  .grid-col-8\@sm {
    --grid-columns: 8;
  }
  .col-8\@sm {
    --column-span: 8;
  }
  .grid-col-9\@sm {
    --grid-columns: 9;
  }
  .col-9\@sm {
    --column-span: 9;
  }
  .grid-col-10\@sm {
    --grid-columns: 10;
  }
  .col-10\@sm {
    --column-span: 10;
  }
  .grid-col-11\@sm {
    --grid-columns: 11;
  }
  .col-11\@sm {
    --column-span: 11;
  }
  .grid-col-12\@sm {
    --grid-columns: 12;
  }
  .col-12\@sm {
    --column-span: 12;
  }
  .col-1\@sm, .col-2\@sm, .col-3\@sm, .col-4\@sm, .col-5\@sm, .col-6\@sm, .col-7\@sm, .col-8\@sm, .col-9\@sm, .col-10\@sm, .col-11\@sm, .col-12\@sm {
    width: calc((100% - (var(--grid-columns) - 1) * var(--column-gap)) * var(--column-span) / var(--grid-columns) + (var(--column-span) - 1) * var(--column-gap));
    flex: initial;
  }
  .col\@sm {
    width: auto;
    flex: 1 1 0;
  }
  .col-content\@sm {
    width: auto;
    flex: 0 1 auto;
  }
  .offset-1\@sm {
    --column-offset: 1;
  }
  .offset-2\@sm {
    --column-offset: 2;
  }
  .offset-3\@sm {
    --column-offset: 3;
  }
  .offset-4\@sm {
    --column-offset: 4;
  }
  .offset-5\@sm {
    --column-offset: 5;
  }
  .offset-6\@sm {
    --column-offset: 6;
  }
  .offset-7\@sm {
    --column-offset: 7;
  }
  .offset-8\@sm {
    --column-offset: 8;
  }
  .offset-9\@sm {
    --column-offset: 9;
  }
  .offset-10\@sm {
    --column-offset: 10;
  }
  .offset-11\@sm {
    --column-offset: 11;
  }
  .offset-1\@sm, .offset-2\@sm, .offset-3\@sm, .offset-4\@sm, .offset-5\@sm, .offset-6\@sm, .offset-7\@sm, .offset-8\@sm, .offset-9\@sm, .offset-10\@sm, .offset-11\@sm {
    margin-left: calc((100% - (var(--grid-columns) - 1) * var(--column-gap)) * var(--column-offset) / var(--grid-columns) + var(--column-offset) * var(--column-gap));
  }
  .offset-0\@sm {
    margin-left: 0;
  }
  /* gap */
  .gap-4xs\@sm {
    --row-gap: var(--space-4xs);
    --column-gap: var(--space-4xs);
    gap: var(--row-gap) var(--column-gap);
  }
  .gap-x-4xs\@sm {
    --column-gap: var(--space-4xs);
    column-gap: var(--column-gap);
  }
  .gap-y-4xs\@sm {
    --row-gap: var(--space-4xs);
    row-gap: var(--row-gap);
  }
  .gap-3xs\@sm {
    --row-gap: var(--space-3xs);
    --column-gap: var(--space-3xs);
    gap: var(--row-gap) var(--column-gap);
  }
  .gap-x-3xs\@sm {
    --column-gap: var(--space-3xs);
    column-gap: var(--column-gap);
  }
  .gap-y-3xs\@sm {
    --row-gap: var(--space-3xs);
    row-gap: var(--row-gap);
  }
  .gap-2xs\@sm {
    --row-gap: var(--space-2xs);
    --column-gap: var(--space-2xs);
    gap: var(--row-gap) var(--column-gap);
  }
  .gap-x-2xs\@sm {
    --column-gap: var(--space-2xs);
    column-gap: var(--column-gap);
  }
  .gap-y-2xs\@sm {
    --row-gap: var(--space-2xs);
    row-gap: var(--row-gap);
  }
  .gap-xs\@sm {
    --row-gap: var(--space-xs);
    --column-gap: var(--space-xs);
    gap: var(--row-gap) var(--column-gap);
  }
  .gap-x-xs\@sm {
    --column-gap: var(--space-xs);
    column-gap: var(--column-gap);
  }
  .gap-y-xs\@sm {
    --row-gap: var(--space-xs);
    row-gap: var(--row-gap);
  }
  .gap-sm\@sm {
    --row-gap: var(--space-sm);
    --column-gap: var(--space-sm);
    gap: var(--row-gap) var(--column-gap);
  }
  .gap-x-sm\@sm {
    --column-gap: var(--space-sm);
    column-gap: var(--column-gap);
  }
  .gap-y-sm\@sm {
    --row-gap: var(--space-sm);
    row-gap: var(--row-gap);
  }
  .gap-md\@sm {
    --row-gap: var(--space-md);
    --column-gap: var(--space-md);
    gap: var(--row-gap) var(--column-gap);
  }
  .gap-x-md\@sm {
    --column-gap: var(--space-md);
    column-gap: var(--column-gap);
  }
  .gap-y-md\@sm {
    --row-gap: var(--space-md);
    row-gap: var(--row-gap);
  }
  .gap-lg\@sm {
    --row-gap: var(--space-lg);
    --column-gap: var(--space-lg);
    gap: var(--row-gap) var(--column-gap);
  }
  .gap-x-lg\@sm {
    --column-gap: var(--space-lg);
    column-gap: var(--column-gap);
  }
  .gap-y-lg\@sm {
    --row-gap: var(--space-lg);
    row-gap: var(--row-gap);
  }
  .gap-xl\@sm {
    --row-gap: var(--space-xl);
    --column-gap: var(--space-xl);
    gap: var(--row-gap) var(--column-gap);
  }
  .gap-x-xl\@sm {
    --column-gap: var(--space-xl);
    column-gap: var(--column-gap);
  }
  .gap-y-xl\@sm {
    --row-gap: var(--space-xl);
    row-gap: var(--row-gap);
  }
  .gap-2xl\@sm {
    --row-gap: var(--space-2xl);
    --column-gap: var(--space-2xl);
    gap: var(--row-gap) var(--column-gap);
  }
  .gap-x-2xl\@sm {
    --column-gap: var(--space-2xl);
    column-gap: var(--column-gap);
  }
  .gap-y-2xl\@sm {
    --row-gap: var(--space-2xl);
    row-gap: var(--row-gap);
  }
  .gap-3xl\@sm {
    --row-gap: var(--space-3xl);
    --column-gap: var(--space-3xl);
    gap: var(--row-gap) var(--column-gap);
  }
  .gap-x-3xl\@sm {
    --column-gap: var(--space-3xl);
    column-gap: var(--column-gap);
  }
  .gap-y-3xl\@sm {
    --row-gap: var(--space-3xl);
    row-gap: var(--row-gap);
  }
  .gap-4xl\@sm {
    --row-gap: var(--space-4xl);
    --column-gap: var(--space-4xl);
    gap: var(--row-gap) var(--column-gap);
  }
  .gap-x-4xl\@sm {
    --column-gap: var(--space-4xl);
    column-gap: var(--column-gap);
  }
  .gap-y-4xl\@sm {
    --row-gap: var(--space-4xl);
    row-gap: var(--row-gap);
  }
  .gap-0\@sm {
    --row-gap: 0px;
    --column-gap: 0px;
    gap: 0px;
  }
  .gap-x-0\@sm {
    --column-gap: 0px;
    column-gap: 0px;
  }
  .gap-y-0\@sm {
    --row-gap: 0px;
    row-gap: 0px;
  }
  /* flexbox */
  .flex\@sm {
    display: flex;
  }
  .inline-flex\@sm {
    display: inline-flex;
  }
  .flex-wrap\@sm {
    flex-wrap: wrap;
  }
  .flex-nowrap\@sm {
    flex-wrap: nowrap;
  }
  .flex-column\@sm {
    flex-direction: column;
  }
  .flex-column-reverse\@sm {
    flex-direction: column-reverse;
  }
  .flex-row\@sm {
    flex-direction: row;
  }
  .flex-row-reverse\@sm {
    flex-direction: row-reverse;
  }
  .flex-center\@sm {
    justify-content: center;
    align-items: center;
  }
  .flex-grow\@sm {
    flex-grow: 1;
  }
  .flex-grow-0\@sm {
    flex-grow: 0;
  }
  .flex-shrink\@sm {
    flex-shrink: 1;
  }
  .flex-shrink-0\@sm {
    flex-shrink: 0;
  }
  .flex-basis-0\@sm {
    flex-basis: 0;
  }
  /* justify-content */
  .justify-start\@sm {
    justify-content: start;
  }
  .justify-end\@sm {
    justify-content: end;
  }
  .justify-center\@sm {
    justify-content: center;
  }
  .justify-between\@sm {
    justify-content: space-between;
  }
  /* align-items */
  .items-center\@sm {
    align-items: center;
  }
  .items-start\@sm {
    align-items: start;
  }
  .items-end\@sm {
    align-items: end;
  }
  .items-baseline\@sm {
    align-items: baseline;
  }
  .items-stretch\@sm {
    align-items: stretch;
  }
  /* align-content */
  .content-start\@sm {
    align-content: start;
  }
  .content-end\@sm {
    align-content: end;
  }
  .content-center\@sm {
    align-content: center;
  }
  .content-between\@sm {
    align-content: space-between;
  }
  /* order */
  .order-1\@sm {
    order: 1;
  }
  .order-2\@sm {
    order: 2;
  }
  .order-3\@sm {
    order: 3;
  }
  /* display */
  .block\@sm {
    display: block;
  }
  .inline-block\@sm {
    display: inline-block;
  }
  .inline\@sm {
    display: inline;
  }
  .contents\@sm {
    display: contents;
  }
  .css-grid\@sm {
    display: grid;
  }
  .css-inline-grid\@sm {
    display: inline-grid;
  }
  .hide\@sm {
    display: none;
  }
  /* margin */
  .margin-4xs\@sm {
    margin: var(--space-4xs);
  }
  .margin-top-4xs\@sm {
    margin-top: var(--space-4xs);
  }
  .margin-right-4xs\@sm {
    margin-right: var(--space-4xs);
  }
  .margin-bottom-4xs\@sm {
    margin-bottom: var(--space-4xs);
  }
  .margin-left-4xs\@sm {
    margin-left: var(--space-4xs);
  }
  .margin-y-4xs\@sm {
    margin-top: var(--space-4xs);
    margin-bottom: var(--space-4xs);
  }
  .margin-x-4xs\@sm {
    margin-right: var(--space-4xs);
    margin-left: var(--space-4xs);
  }
  .margin-3xs\@sm {
    margin: var(--space-3xs);
  }
  .margin-top-3xs\@sm {
    margin-top: var(--space-3xs);
  }
  .margin-right-3xs\@sm {
    margin-right: var(--space-3xs);
  }
  .margin-bottom-3xs\@sm {
    margin-bottom: var(--space-3xs);
  }
  .margin-left-3xs\@sm {
    margin-left: var(--space-3xs);
  }
  .margin-y-3xs\@sm {
    margin-top: var(--space-3xs);
    margin-bottom: var(--space-3xs);
  }
  .margin-x-3xs\@sm {
    margin-right: var(--space-3xs);
    margin-left: var(--space-3xs);
  }
  .margin-2xs\@sm {
    margin: var(--space-2xs);
  }
  .margin-top-2xs\@sm {
    margin-top: var(--space-2xs);
  }
  .margin-right-2xs\@sm {
    margin-right: var(--space-2xs);
  }
  .margin-bottom-2xs\@sm {
    margin-bottom: var(--space-2xs);
  }
  .margin-left-2xs\@sm {
    margin-left: var(--space-2xs);
  }
  .margin-y-2xs\@sm {
    margin-top: var(--space-2xs);
    margin-bottom: var(--space-2xs);
  }
  .margin-x-2xs\@sm {
    margin-right: var(--space-2xs);
    margin-left: var(--space-2xs);
  }
  .margin-xs\@sm {
    margin: var(--space-xs);
  }
  .margin-top-xs\@sm {
    margin-top: var(--space-xs);
  }
  .margin-right-xs\@sm {
    margin-right: var(--space-xs);
  }
  .margin-bottom-xs\@sm {
    margin-bottom: var(--space-xs);
  }
  .margin-left-xs\@sm {
    margin-left: var(--space-xs);
  }
  .margin-y-xs\@sm {
    margin-top: var(--space-xs);
    margin-bottom: var(--space-xs);
  }
  .margin-x-xs\@sm {
    margin-right: var(--space-xs);
    margin-left: var(--space-xs);
  }
  .margin-sm\@sm {
    margin: var(--space-sm);
  }
  .margin-top-sm\@sm {
    margin-top: var(--space-sm);
  }
  .margin-right-sm\@sm {
    margin-right: var(--space-sm);
  }
  .margin-bottom-sm\@sm {
    margin-bottom: var(--space-sm);
  }
  .margin-left-sm\@sm {
    margin-left: var(--space-sm);
  }
  .margin-y-sm\@sm {
    margin-top: var(--space-sm);
    margin-bottom: var(--space-sm);
  }
  .margin-x-sm\@sm {
    margin-right: var(--space-sm);
    margin-left: var(--space-sm);
  }
  .margin-md\@sm {
    margin: var(--space-md);
  }
  .margin-top-md\@sm {
    margin-top: var(--space-md);
  }
  .margin-right-md\@sm {
    margin-right: var(--space-md);
  }
  .margin-bottom-md\@sm {
    margin-bottom: var(--space-md);
  }
  .margin-left-md\@sm {
    margin-left: var(--space-md);
  }
  .margin-y-md\@sm {
    margin-top: var(--space-md);
    margin-bottom: var(--space-md);
  }
  .margin-x-md\@sm {
    margin-right: var(--space-md);
    margin-left: var(--space-md);
  }
  .margin-lg\@sm {
    margin: var(--space-lg);
  }
  .margin-top-lg\@sm {
    margin-top: var(--space-lg);
  }
  .margin-right-lg\@sm {
    margin-right: var(--space-lg);
  }
  .margin-bottom-lg\@sm {
    margin-bottom: var(--space-lg);
  }
  .margin-left-lg\@sm {
    margin-left: var(--space-lg);
  }
  .margin-y-lg\@sm {
    margin-top: var(--space-lg);
    margin-bottom: var(--space-lg);
  }
  .margin-x-lg\@sm {
    margin-right: var(--space-lg);
    margin-left: var(--space-lg);
  }
  .margin-xl\@sm {
    margin: var(--space-xl);
  }
  .margin-top-xl\@sm {
    margin-top: var(--space-xl);
  }
  .margin-right-xl\@sm {
    margin-right: var(--space-xl);
  }
  .margin-bottom-xl\@sm {
    margin-bottom: var(--space-xl);
  }
  .margin-left-xl\@sm {
    margin-left: var(--space-xl);
  }
  .margin-y-xl\@sm {
    margin-top: var(--space-xl);
    margin-bottom: var(--space-xl);
  }
  .margin-x-xl\@sm {
    margin-right: var(--space-xl);
    margin-left: var(--space-xl);
  }
  .margin-2xl\@sm {
    margin: var(--space-2xl);
  }
  .margin-top-2xl\@sm {
    margin-top: var(--space-2xl);
  }
  .margin-right-2xl\@sm {
    margin-right: var(--space-2xl);
  }
  .margin-bottom-2xl\@sm {
    margin-bottom: var(--space-2xl);
  }
  .margin-left-2xl\@sm {
    margin-left: var(--space-2xl);
  }
  .margin-y-2xl\@sm {
    margin-top: var(--space-2xl);
    margin-bottom: var(--space-2xl);
  }
  .margin-x-2xl\@sm {
    margin-right: var(--space-2xl);
    margin-left: var(--space-2xl);
  }
  .margin-3xl\@sm {
    margin: var(--space-3xl);
  }
  .margin-top-3xl\@sm {
    margin-top: var(--space-3xl);
  }
  .margin-right-3xl\@sm {
    margin-right: var(--space-3xl);
  }
  .margin-bottom-3xl\@sm {
    margin-bottom: var(--space-3xl);
  }
  .margin-left-3xl\@sm {
    margin-left: var(--space-3xl);
  }
  .margin-y-3xl\@sm {
    margin-top: var(--space-3xl);
    margin-bottom: var(--space-3xl);
  }
  .margin-x-3xl\@sm {
    margin-right: var(--space-3xl);
    margin-left: var(--space-3xl);
  }
  .margin-4xl\@sm {
    margin: var(--space-4xl);
  }
  .margin-top-4xl\@sm {
    margin-top: var(--space-4xl);
  }
  .margin-right-4xl\@sm {
    margin-right: var(--space-4xl);
  }
  .margin-bottom-4xl\@sm {
    margin-bottom: var(--space-4xl);
  }
  .margin-left-4xl\@sm {
    margin-left: var(--space-4xl);
  }
  .margin-y-4xl\@sm {
    margin-top: var(--space-4xl);
    margin-bottom: var(--space-4xl);
  }
  .margin-x-4xl\@sm {
    margin-right: var(--space-4xl);
    margin-left: var(--space-4xl);
  }
  .margin-auto\@sm {
    margin: auto;
  }
  .margin-0\@sm {
    margin: 0;
  }
  .margin-y-auto\@sm {
    margin-top: auto;
    margin-bottom: auto;
  }
  .margin-y-0\@sm {
    margin-top: 0;
    margin-bottom: 0;
  }
  .margin-x-auto\@sm {
    margin-left: auto;
    margin-right: auto;
  }
  .margin-x-0\@sm {
    margin-left: 0;
    margin-right: 0;
  }
  .margin-top-auto\@sm {
    margin-top: auto;
  }
  .margin-top-0\@sm {
    margin-top: 0;
  }
  .margin-right-auto\@sm {
    margin-right: auto;
  }
  .margin-right-0\@sm {
    margin-right: 0;
  }
  .margin-bottom-auto\@sm {
    margin-bottom: auto;
  }
  .margin-bottom-0\@sm {
    margin-bottom: 0;
  }
  .margin-left-auto\@sm {
    margin-left: auto;
  }
  .margin-left-0\@sm {
    margin-left: 0;
  }
  /* padding */
  .padding-4xs\@sm {
    padding: var(--space-4xs);
  }
  .padding-top-4xs\@sm {
    padding-top: var(--space-4xs);
  }
  .padding-right-4xs\@sm {
    padding-right: var(--space-4xs);
  }
  .padding-bottom-4xs\@sm {
    padding-bottom: var(--space-4xs);
  }
  .padding-left-4xs\@sm {
    padding-left: var(--space-4xs);
  }
  .padding-y-4xs\@sm {
    padding-top: var(--space-4xs);
    padding-bottom: var(--space-4xs);
  }
  .padding-x-4xs\@sm {
    padding-right: var(--space-4xs);
    padding-left: var(--space-4xs);
  }
  .padding-3xs\@sm {
    padding: var(--space-3xs);
  }
  .padding-top-3xs\@sm {
    padding-top: var(--space-3xs);
  }
  .padding-right-3xs\@sm {
    padding-right: var(--space-3xs);
  }
  .padding-bottom-3xs\@sm {
    padding-bottom: var(--space-3xs);
  }
  .padding-left-3xs\@sm {
    padding-left: var(--space-3xs);
  }
  .padding-y-3xs\@sm {
    padding-top: var(--space-3xs);
    padding-bottom: var(--space-3xs);
  }
  .padding-x-3xs\@sm {
    padding-right: var(--space-3xs);
    padding-left: var(--space-3xs);
  }
  .padding-2xs\@sm {
    padding: var(--space-2xs);
  }
  .padding-top-2xs\@sm {
    padding-top: var(--space-2xs);
  }
  .padding-right-2xs\@sm {
    padding-right: var(--space-2xs);
  }
  .padding-bottom-2xs\@sm {
    padding-bottom: var(--space-2xs);
  }
  .padding-left-2xs\@sm {
    padding-left: var(--space-2xs);
  }
  .padding-y-2xs\@sm {
    padding-top: var(--space-2xs);
    padding-bottom: var(--space-2xs);
  }
  .padding-x-2xs\@sm {
    padding-right: var(--space-2xs);
    padding-left: var(--space-2xs);
  }
  .padding-xs\@sm {
    padding: var(--space-xs);
  }
  .padding-top-xs\@sm {
    padding-top: var(--space-xs);
  }
  .padding-right-xs\@sm {
    padding-right: var(--space-xs);
  }
  .padding-bottom-xs\@sm {
    padding-bottom: var(--space-xs);
  }
  .padding-left-xs\@sm {
    padding-left: var(--space-xs);
  }
  .padding-y-xs\@sm {
    padding-top: var(--space-xs);
    padding-bottom: var(--space-xs);
  }
  .padding-x-xs\@sm {
    padding-right: var(--space-xs);
    padding-left: var(--space-xs);
  }
  .padding-sm\@sm {
    padding: var(--space-sm);
  }
  .padding-top-sm\@sm {
    padding-top: var(--space-sm);
  }
  .padding-right-sm\@sm {
    padding-right: var(--space-sm);
  }
  .padding-bottom-sm\@sm {
    padding-bottom: var(--space-sm);
  }
  .padding-left-sm\@sm {
    padding-left: var(--space-sm);
  }
  .padding-y-sm\@sm {
    padding-top: var(--space-sm);
    padding-bottom: var(--space-sm);
  }
  .padding-x-sm\@sm {
    padding-right: var(--space-sm);
    padding-left: var(--space-sm);
  }
  .padding-md\@sm {
    padding: var(--space-md);
  }
  .padding-top-md\@sm {
    padding-top: var(--space-md);
  }
  .padding-right-md\@sm {
    padding-right: var(--space-md);
  }
  .padding-bottom-md\@sm {
    padding-bottom: var(--space-md);
  }
  .padding-left-md\@sm {
    padding-left: var(--space-md);
  }
  .padding-y-md\@sm {
    padding-top: var(--space-md);
    padding-bottom: var(--space-md);
  }
  .padding-x-md\@sm {
    padding-right: var(--space-md);
    padding-left: var(--space-md);
  }
  .padding-lg\@sm {
    padding: var(--space-lg);
  }
  .padding-top-lg\@sm {
    padding-top: var(--space-lg);
  }
  .padding-right-lg\@sm {
    padding-right: var(--space-lg);
  }
  .padding-bottom-lg\@sm {
    padding-bottom: var(--space-lg);
  }
  .padding-left-lg\@sm {
    padding-left: var(--space-lg);
  }
  .padding-y-lg\@sm {
    padding-top: var(--space-lg);
    padding-bottom: var(--space-lg);
  }
  .padding-x-lg\@sm {
    padding-right: var(--space-lg);
    padding-left: var(--space-lg);
  }
  .padding-xl\@sm {
    padding: var(--space-xl);
  }
  .padding-top-xl\@sm {
    padding-top: var(--space-xl);
  }
  .padding-right-xl\@sm {
    padding-right: var(--space-xl);
  }
  .padding-bottom-xl\@sm {
    padding-bottom: var(--space-xl);
  }
  .padding-left-xl\@sm {
    padding-left: var(--space-xl);
  }
  .padding-y-xl\@sm {
    padding-top: var(--space-xl);
    padding-bottom: var(--space-xl);
  }
  .padding-x-xl\@sm {
    padding-right: var(--space-xl);
    padding-left: var(--space-xl);
  }
  .padding-2xl\@sm {
    padding: var(--space-2xl);
  }
  .padding-top-2xl\@sm {
    padding-top: var(--space-2xl);
  }
  .padding-right-2xl\@sm {
    padding-right: var(--space-2xl);
  }
  .padding-bottom-2xl\@sm {
    padding-bottom: var(--space-2xl);
  }
  .padding-left-2xl\@sm {
    padding-left: var(--space-2xl);
  }
  .padding-y-2xl\@sm {
    padding-top: var(--space-2xl);
    padding-bottom: var(--space-2xl);
  }
  .padding-x-2xl\@sm {
    padding-right: var(--space-2xl);
    padding-left: var(--space-2xl);
  }
  .padding-3xl\@sm {
    padding: var(--space-3xl);
  }
  .padding-top-3xl\@sm {
    padding-top: var(--space-3xl);
  }
  .padding-right-3xl\@sm {
    padding-right: var(--space-3xl);
  }
  .padding-bottom-3xl\@sm {
    padding-bottom: var(--space-3xl);
  }
  .padding-left-3xl\@sm {
    padding-left: var(--space-3xl);
  }
  .padding-y-3xl\@sm {
    padding-top: var(--space-3xl);
    padding-bottom: var(--space-3xl);
  }
  .padding-x-3xl\@sm {
    padding-right: var(--space-3xl);
    padding-left: var(--space-3xl);
  }
  .padding-4xl\@sm {
    padding: var(--space-4xl);
  }
  .padding-top-4xl\@sm {
    padding-top: var(--space-4xl);
  }
  .padding-right-4xl\@sm {
    padding-right: var(--space-4xl);
  }
  .padding-bottom-4xl\@sm {
    padding-bottom: var(--space-4xl);
  }
  .padding-left-4xl\@sm {
    padding-left: var(--space-4xl);
  }
  .padding-y-4xl\@sm {
    padding-top: var(--space-4xl);
    padding-bottom: var(--space-4xl);
  }
  .padding-x-4xl\@sm {
    padding-right: var(--space-4xl);
    padding-left: var(--space-4xl);
  }
  .padding-0\@sm {
    padding: 0;
  }
  .padding-y-0\@sm {
    padding-top: 0;
    padding-bottom: 0;
  }
  .padding-x-0\@sm {
    padding-left: 0;
    padding-right: 0;
  }
  .padding-top-0\@sm {
    padding-top: 0;
  }
  .padding-right-0\@sm {
    padding-right: 0;
  }
  .padding-bottom-0\@sm {
    padding-bottom: 0;
  }
  .padding-left-0\@sm {
    padding-left: 0;
  }
  /* text-align */
  .text-center\@sm {
    text-align: center;
  }
  .text-left\@sm {
    text-align: left;
  }
  .text-right\@sm {
    text-align: right;
  }
  .text-justify\@sm {
    text-align: justify;
  }
  /* font-size */
  .text-xs\@sm {
    font-size: var(--text-xs);
  }
  .text-sm\@sm {
    font-size: var(--text-sm);
  }
  .text-base\@sm {
    font-size: var(--text-base);
  }
  .text-md\@sm {
    font-size: var(--text-md);
  }
  .text-lg\@sm {
    font-size: var(--text-lg);
  }
  .text-xl\@sm {
    font-size: var(--text-xl);
  }
  .text-2xl\@sm {
    font-size: var(--text-2xl);
  }
  .text-3xl\@sm {
    font-size: var(--text-3xl);
  }
  .text-4xl\@sm {
    font-size: var(--text-4xl);
  }
  /* column-count */
  .column-count-1\@sm {
    column-count: 1;
  }
  .column-count-2\@sm {
    column-count: 2;
  }
  .column-count-3\@sm {
    column-count: 3;
  }
  .column-count-4\@sm {
    column-count: 4;
  }
  /* width */
  .width-4xs\@sm {
    width: 0.25rem;
  }
  .width-3xs\@sm {
    width: 0.5rem;
  }
  .width-2xs\@sm {
    width: 0.75rem;
  }
  .width-xs\@sm {
    width: 1rem;
  }
  .width-sm\@sm {
    width: 1.5rem;
  }
  .width-md\@sm {
    width: 2rem;
  }
  .width-lg\@sm {
    width: 3rem;
  }
  .width-xl\@sm {
    width: 4rem;
  }
  .width-2xl\@sm {
    width: 6rem;
  }
  .width-3xl\@sm {
    width: 8rem;
  }
  .width-4xl\@sm {
    width: 16rem;
  }
  .width-0\@sm {
    width: 0;
  }
  .width-10\%\@sm {
    width: 10%;
  }
  .width-20\%\@sm {
    width: 20%;
  }
  .width-25\%\@sm {
    width: 25%;
  }
  .width-30\%\@sm {
    width: 30%;
  }
  .width-33\%\@sm {
    width: 33%;
  }
  .width-40\%\@sm {
    width: 40%;
  }
  .width-50\%\@sm {
    width: 50%;
  }
  .width-60\%\@sm {
    width: 60%;
  }
  .width-70\%\@sm {
    width: 70%;
  }
  .width-75\%\@sm {
    width: 75%;
  }
  .width-80\%\@sm {
    width: 80%;
  }
  .width-90\%\@sm {
    width: 90%;
  }
  .width-100\%\@sm {
    width: 100%;
  }
  .width-100\%\@sm {
    width: 100%;
  }
  .width-100vw\@sm {
    width: 100vw;
  }
  .width-auto\@sm {
    width: auto;
  }
  .width-inherit\@sm {
    width: inherit;
  }
  .width-0\@sm {
    width: 0;
  }
  /* height */
  .height-4xs\@sm {
    height: 0.25rem;
  }
  .height-3xs\@sm {
    height: 0.5rem;
  }
  .height-2xs\@sm {
    height: 0.75rem;
  }
  .height-xs\@sm {
    height: 1rem;
  }
  .height-sm\@sm {
    height: 1.5rem;
  }
  .height-md\@sm {
    height: 2rem;
  }
  .height-lg\@sm {
    height: 3rem;
  }
  .height-xl\@sm {
    height: 4rem;
  }
  .height-2xl\@sm {
    height: 6rem;
  }
  .height-3xl\@sm {
    height: 8rem;
  }
  .height-4xl\@sm {
    height: 16rem;
  }
  .height-0\@sm {
    height: 0;
  }
  .height-10\%\@sm {
    height: 10%;
  }
  .height-20\%\@sm {
    height: 20%;
  }
  .height-25\%\@sm {
    height: 25%;
  }
  .height-30\%\@sm {
    height: 30%;
  }
  .height-33\%\@sm {
    height: 33%;
  }
  .height-40\%\@sm {
    height: 40%;
  }
  .height-50\%\@sm {
    height: 50%;
  }
  .height-60\%\@sm {
    height: 60%;
  }
  .height-70\%\@sm {
    height: 70%;
  }
  .height-75\%\@sm {
    height: 75%;
  }
  .height-80\%\@sm {
    height: 80%;
  }
  .height-90\%\@sm {
    height: 90%;
  }
  .height-100\%\@sm {
    height: 100%;
  }
  .height-100\%\@sm {
    height: 100%;
  }
  .height-100vh\@sm {
    height: 100vh;
  }
  .height-auto\@sm {
    height: auto;
  }
  .height-inherit\@sm {
    height: inherit;
  }
  .height-0\@sm {
    height: 0;
  }
  /* max-width */
  .max-width-3xs\@sm {
    max-width: var(--max-width-3xs);
  }
  .max-width-2xs\@sm {
    max-width: var(--max-width-2xs);
  }
  .max-width-xs\@sm {
    max-width: var(--max-width-xs);
  }
  .max-width-sm\@sm {
    max-width: var(--max-width-sm);
  }
  .max-width-md\@sm {
    max-width: var(--max-width-md);
  }
  .max-width-lg\@sm {
    max-width: var(--max-width-lg);
  }
  .max-width-xl\@sm {
    max-width: var(--max-width-xl);
  }
  .max-width-100\%\@sm {
    max-width: 100%;
  }
  .max-width-none\@sm {
    max-width: none;
  }
  /* position */
  .position-relative\@sm {
    position: relative;
  }
  .position-absolute\@sm {
    position: absolute;
  }
  .position-fixed\@sm {
    position: fixed;
  }
  .position-sticky\@sm {
    position: sticky;
  }
  .position-static\@sm {
    position: static;
  }
  .inset-0\@sm {
    inset: 0;
  }
  .top-4xs\@sm {
    top: var(--space-4xs);
  }
  .right-4xs\@sm {
    right: var(--space-4xs);
  }
  .bottom-4xs\@sm {
    bottom: var(--space-4xs);
  }
  .left-4xs\@sm {
    left: var(--space-4xs);
  }
  .top-3xs\@sm {
    top: var(--space-3xs);
  }
  .right-3xs\@sm {
    right: var(--space-3xs);
  }
  .bottom-3xs\@sm {
    bottom: var(--space-3xs);
  }
  .left-3xs\@sm {
    left: var(--space-3xs);
  }
  .top-2xs\@sm {
    top: var(--space-2xs);
  }
  .right-2xs\@sm {
    right: var(--space-2xs);
  }
  .bottom-2xs\@sm {
    bottom: var(--space-2xs);
  }
  .left-2xs\@sm {
    left: var(--space-2xs);
  }
  .top-xs\@sm {
    top: var(--space-xs);
  }
  .right-xs\@sm {
    right: var(--space-xs);
  }
  .bottom-xs\@sm {
    bottom: var(--space-xs);
  }
  .left-xs\@sm {
    left: var(--space-xs);
  }
  .top-sm\@sm {
    top: var(--space-sm);
  }
  .right-sm\@sm {
    right: var(--space-sm);
  }
  .bottom-sm\@sm {
    bottom: var(--space-sm);
  }
  .left-sm\@sm {
    left: var(--space-sm);
  }
  .top-md\@sm {
    top: var(--space-md);
  }
  .right-md\@sm {
    right: var(--space-md);
  }
  .bottom-md\@sm {
    bottom: var(--space-md);
  }
  .left-md\@sm {
    left: var(--space-md);
  }
  .top-lg\@sm {
    top: var(--space-lg);
  }
  .right-lg\@sm {
    right: var(--space-lg);
  }
  .bottom-lg\@sm {
    bottom: var(--space-lg);
  }
  .left-lg\@sm {
    left: var(--space-lg);
  }
  .top-xl\@sm {
    top: var(--space-xl);
  }
  .right-xl\@sm {
    right: var(--space-xl);
  }
  .bottom-xl\@sm {
    bottom: var(--space-xl);
  }
  .left-xl\@sm {
    left: var(--space-xl);
  }
  .top-2xl\@sm {
    top: var(--space-2xl);
  }
  .right-2xl\@sm {
    right: var(--space-2xl);
  }
  .bottom-2xl\@sm {
    bottom: var(--space-2xl);
  }
  .left-2xl\@sm {
    left: var(--space-2xl);
  }
  .top-3xl\@sm {
    top: var(--space-3xl);
  }
  .right-3xl\@sm {
    right: var(--space-3xl);
  }
  .bottom-3xl\@sm {
    bottom: var(--space-3xl);
  }
  .left-3xl\@sm {
    left: var(--space-3xl);
  }
  .top-4xl\@sm {
    top: var(--space-4xl);
  }
  .right-4xl\@sm {
    right: var(--space-4xl);
  }
  .bottom-4xl\@sm {
    bottom: var(--space-4xl);
  }
  .left-4xl\@sm {
    left: var(--space-4xl);
  }
  .top-50\%\@sm {
    top: 50%;
  }
  .top-auto\@sm {
    top: auto;
  }
  .top-0\@sm {
    top: 0;
  }
  .right-50\%\@sm {
    right: 50%;
  }
  .right-auto\@sm {
    right: auto;
  }
  .right-0\@sm {
    right: 0;
  }
  .bottom-50\%\@sm {
    bottom: 50%;
  }
  .bottom-auto\@sm {
    bottom: auto;
  }
  .bottom-0\@sm {
    bottom: 0;
  }
  .left-50\%\@sm {
    left: 50%;
  }
  .left-auto\@sm {
    left: auto;
  }
  .left-0\@sm {
    left: 0;
  }
  /* overflow */
  .overflow-visible\@sm {
    overflow: visible;
  }
  .overflow-hidden\@sm {
    overflow: hidden;
  }
  .overflow-auto\@sm {
    overflow: auto;
  }
  .momentum-scrolling\@sm {
    -webkit-overflow-scrolling: touch;
  }
  /* visibility */
  .visible\@sm {
    visibility: visible;
  }
  .invisible\@sm {
    visibility: hidden;
  }
}
@media not all and (min-width: 48rem) {
  .display\@sm {
    display: none !important;
  }
}
@media (min-width: 64rem) {
  :root {
    /* spacing */
    --space-4xs: 0.1875rem;
    --space-3xs: 0.375rem;
    --space-2xs: 0.5625rem;
    --space-xs: 0.75rem;
    --space-sm: 1.125rem;
    --space-md: 2rem;
    --space-lg: 3.125rem;
    --space-xl: 5.125rem;
    --space-2xl: 8.25rem;
    --space-3xl: 13.25rem;
    --space-4xl: 21.5rem;
  }
  /* grid */
  .grid-col-1\@md {
    --grid-columns: 1;
  }
  .col-1\@md {
    --column-span: 1;
  }
  .grid-col-2\@md {
    --grid-columns: 2;
  }
  .col-2\@md {
    --column-span: 2;
  }
  .grid-col-3\@md {
    --grid-columns: 3;
  }
  .col-3\@md {
    --column-span: 3;
  }
  .grid-col-4\@md {
    --grid-columns: 4;
  }
  .col-4\@md {
    --column-span: 4;
  }
  .grid-col-5\@md {
    --grid-columns: 5;
  }
  .col-5\@md {
    --column-span: 5;
  }
  .grid-col-6\@md {
    --grid-columns: 6;
  }
  .col-6\@md {
    --column-span: 6;
  }
  .grid-col-7\@md {
    --grid-columns: 7;
  }
  .col-7\@md {
    --column-span: 7;
  }
  .grid-col-8\@md {
    --grid-columns: 8;
  }
  .col-8\@md {
    --column-span: 8;
  }
  .grid-col-9\@md {
    --grid-columns: 9;
  }
  .col-9\@md {
    --column-span: 9;
  }
  .grid-col-10\@md {
    --grid-columns: 10;
  }
  .col-10\@md {
    --column-span: 10;
  }
  .grid-col-11\@md {
    --grid-columns: 11;
  }
  .col-11\@md {
    --column-span: 11;
  }
  .grid-col-12\@md {
    --grid-columns: 12;
  }
  .col-12\@md {
    --column-span: 12;
  }
  .col-1\@md, .col-2\@md, .col-3\@md, .col-4\@md, .col-5\@md, .col-6\@md, .col-7\@md, .col-8\@md, .col-9\@md, .col-10\@md, .col-11\@md, .col-12\@md {
    width: calc((100% - (var(--grid-columns) - 1) * var(--column-gap)) * var(--column-span) / var(--grid-columns) + (var(--column-span) - 1) * var(--column-gap));
    flex: initial;
  }
  .col\@md {
    width: auto;
    flex: 1 1 0;
  }
  .col-content\@md {
    width: auto;
    flex: 0 1 auto;
  }
  .offset-1\@md {
    --column-offset: 1;
  }
  .offset-2\@md {
    --column-offset: 2;
  }
  .offset-3\@md {
    --column-offset: 3;
  }
  .offset-4\@md {
    --column-offset: 4;
  }
  .offset-5\@md {
    --column-offset: 5;
  }
  .offset-6\@md {
    --column-offset: 6;
  }
  .offset-7\@md {
    --column-offset: 7;
  }
  .offset-8\@md {
    --column-offset: 8;
  }
  .offset-9\@md {
    --column-offset: 9;
  }
  .offset-10\@md {
    --column-offset: 10;
  }
  .offset-11\@md {
    --column-offset: 11;
  }
  .offset-1\@md, .offset-2\@md, .offset-3\@md, .offset-4\@md, .offset-5\@md, .offset-6\@md, .offset-7\@md, .offset-8\@md, .offset-9\@md, .offset-10\@md, .offset-11\@md {
    margin-left: calc((100% - (var(--grid-columns) - 1) * var(--column-gap)) * var(--column-offset) / var(--grid-columns) + var(--column-offset) * var(--column-gap));
  }
  .offset-0\@md {
    margin-left: 0;
  }
  /* gap */
  .gap-4xs\@md {
    --row-gap: var(--space-4xs);
    --column-gap: var(--space-4xs);
    gap: var(--row-gap) var(--column-gap);
  }
  .gap-x-4xs\@md {
    --column-gap: var(--space-4xs);
    column-gap: var(--column-gap);
  }
  .gap-y-4xs\@md {
    --row-gap: var(--space-4xs);
    row-gap: var(--row-gap);
  }
  .gap-3xs\@md {
    --row-gap: var(--space-3xs);
    --column-gap: var(--space-3xs);
    gap: var(--row-gap) var(--column-gap);
  }
  .gap-x-3xs\@md {
    --column-gap: var(--space-3xs);
    column-gap: var(--column-gap);
  }
  .gap-y-3xs\@md {
    --row-gap: var(--space-3xs);
    row-gap: var(--row-gap);
  }
  .gap-2xs\@md {
    --row-gap: var(--space-2xs);
    --column-gap: var(--space-2xs);
    gap: var(--row-gap) var(--column-gap);
  }
  .gap-x-2xs\@md {
    --column-gap: var(--space-2xs);
    column-gap: var(--column-gap);
  }
  .gap-y-2xs\@md {
    --row-gap: var(--space-2xs);
    row-gap: var(--row-gap);
  }
  .gap-xs\@md {
    --row-gap: var(--space-xs);
    --column-gap: var(--space-xs);
    gap: var(--row-gap) var(--column-gap);
  }
  .gap-x-xs\@md {
    --column-gap: var(--space-xs);
    column-gap: var(--column-gap);
  }
  .gap-y-xs\@md {
    --row-gap: var(--space-xs);
    row-gap: var(--row-gap);
  }
  .gap-sm\@md {
    --row-gap: var(--space-sm);
    --column-gap: var(--space-sm);
    gap: var(--row-gap) var(--column-gap);
  }
  .gap-x-sm\@md {
    --column-gap: var(--space-sm);
    column-gap: var(--column-gap);
  }
  .gap-y-sm\@md {
    --row-gap: var(--space-sm);
    row-gap: var(--row-gap);
  }
  .gap-md\@md {
    --row-gap: var(--space-md);
    --column-gap: var(--space-md);
    gap: var(--row-gap) var(--column-gap);
  }
  .gap-x-md\@md {
    --column-gap: var(--space-md);
    column-gap: var(--column-gap);
  }
  .gap-y-md\@md {
    --row-gap: var(--space-md);
    row-gap: var(--row-gap);
  }
  .gap-lg\@md {
    --row-gap: var(--space-lg);
    --column-gap: var(--space-lg);
    gap: var(--row-gap) var(--column-gap);
  }
  .gap-x-lg\@md {
    --column-gap: var(--space-lg);
    column-gap: var(--column-gap);
  }
  .gap-y-lg\@md {
    --row-gap: var(--space-lg);
    row-gap: var(--row-gap);
  }
  .gap-xl\@md {
    --row-gap: var(--space-xl);
    --column-gap: var(--space-xl);
    gap: var(--row-gap) var(--column-gap);
  }
  .gap-x-xl\@md {
    --column-gap: var(--space-xl);
    column-gap: var(--column-gap);
  }
  .gap-y-xl\@md {
    --row-gap: var(--space-xl);
    row-gap: var(--row-gap);
  }
  .gap-2xl\@md {
    --row-gap: var(--space-2xl);
    --column-gap: var(--space-2xl);
    gap: var(--row-gap) var(--column-gap);
  }
  .gap-x-2xl\@md {
    --column-gap: var(--space-2xl);
    column-gap: var(--column-gap);
  }
  .gap-y-2xl\@md {
    --row-gap: var(--space-2xl);
    row-gap: var(--row-gap);
  }
  .gap-3xl\@md {
    --row-gap: var(--space-3xl);
    --column-gap: var(--space-3xl);
    gap: var(--row-gap) var(--column-gap);
  }
  .gap-x-3xl\@md {
    --column-gap: var(--space-3xl);
    column-gap: var(--column-gap);
  }
  .gap-y-3xl\@md {
    --row-gap: var(--space-3xl);
    row-gap: var(--row-gap);
  }
  .gap-4xl\@md {
    --row-gap: var(--space-4xl);
    --column-gap: var(--space-4xl);
    gap: var(--row-gap) var(--column-gap);
  }
  .gap-x-4xl\@md {
    --column-gap: var(--space-4xl);
    column-gap: var(--column-gap);
  }
  .gap-y-4xl\@md {
    --row-gap: var(--space-4xl);
    row-gap: var(--row-gap);
  }
  .gap-0\@md {
    --row-gap: 0px;
    --column-gap: 0px;
    gap: 0px;
  }
  .gap-x-0\@md {
    --column-gap: 0px;
    column-gap: 0px;
  }
  .gap-y-0\@md {
    --row-gap: 0px;
    row-gap: 0px;
  }
  /* flexbox */
  .flex\@md {
    display: flex;
  }
  .inline-flex\@md {
    display: inline-flex;
  }
  .flex-wrap\@md {
    flex-wrap: wrap;
  }
  .flex-nowrap\@md {
    flex-wrap: nowrap;
  }
  .flex-column\@md {
    flex-direction: column;
  }
  .flex-column-reverse\@md {
    flex-direction: column-reverse;
  }
  .flex-row\@md {
    flex-direction: row;
  }
  .flex-row-reverse\@md {
    flex-direction: row-reverse;
  }
  .flex-center\@md {
    justify-content: center;
    align-items: center;
  }
  .flex-grow\@md {
    flex-grow: 1;
  }
  .flex-grow-0\@md {
    flex-grow: 0;
  }
  .flex-shrink\@md {
    flex-shrink: 1;
  }
  .flex-shrink-0\@md {
    flex-shrink: 0;
  }
  .flex-basis-0\@md {
    flex-basis: 0;
  }
  /* justify-content */
  .justify-start\@md {
    justify-content: start;
  }
  .justify-end\@md {
    justify-content: end;
  }
  .justify-center\@md {
    justify-content: center;
  }
  .justify-between\@md {
    justify-content: space-between;
  }
  /* align-items */
  .items-center\@md {
    align-items: center;
  }
  .items-start\@md {
    align-items: start;
  }
  .items-end\@md {
    align-items: end;
  }
  .items-baseline\@md {
    align-items: baseline;
  }
  .items-stretch\@md {
    align-items: stretch;
  }
  /* align-content */
  .content-start\@md {
    align-content: start;
  }
  .content-end\@md {
    align-content: end;
  }
  .content-center\@md {
    align-content: center;
  }
  .content-between\@md {
    align-content: space-between;
  }
  /* order */
  .order-1\@md {
    order: 1;
  }
  .order-2\@md {
    order: 2;
  }
  .order-3\@md {
    order: 3;
  }
  /* display */
  .block\@md {
    display: block;
  }
  .inline-block\@md {
    display: inline-block;
  }
  .inline\@md {
    display: inline;
  }
  .contents\@md {
    display: contents;
  }
  .css-grid\@md {
    display: grid;
  }
  .css-inline-grid\@md {
    display: inline-grid;
  }
  .hide\@md {
    display: none;
  }
  /* margin */
  .margin-4xs\@md {
    margin: var(--space-4xs);
  }
  .margin-top-4xs\@md {
    margin-top: var(--space-4xs);
  }
  .margin-right-4xs\@md {
    margin-right: var(--space-4xs);
  }
  .margin-bottom-4xs\@md {
    margin-bottom: var(--space-4xs);
  }
  .margin-left-4xs\@md {
    margin-left: var(--space-4xs);
  }
  .margin-y-4xs\@md {
    margin-top: var(--space-4xs);
    margin-bottom: var(--space-4xs);
  }
  .margin-x-4xs\@md {
    margin-right: var(--space-4xs);
    margin-left: var(--space-4xs);
  }
  .margin-3xs\@md {
    margin: var(--space-3xs);
  }
  .margin-top-3xs\@md {
    margin-top: var(--space-3xs);
  }
  .margin-right-3xs\@md {
    margin-right: var(--space-3xs);
  }
  .margin-bottom-3xs\@md {
    margin-bottom: var(--space-3xs);
  }
  .margin-left-3xs\@md {
    margin-left: var(--space-3xs);
  }
  .margin-y-3xs\@md {
    margin-top: var(--space-3xs);
    margin-bottom: var(--space-3xs);
  }
  .margin-x-3xs\@md {
    margin-right: var(--space-3xs);
    margin-left: var(--space-3xs);
  }
  .margin-2xs\@md {
    margin: var(--space-2xs);
  }
  .margin-top-2xs\@md {
    margin-top: var(--space-2xs);
  }
  .margin-right-2xs\@md {
    margin-right: var(--space-2xs);
  }
  .margin-bottom-2xs\@md {
    margin-bottom: var(--space-2xs);
  }
  .margin-left-2xs\@md {
    margin-left: var(--space-2xs);
  }
  .margin-y-2xs\@md {
    margin-top: var(--space-2xs);
    margin-bottom: var(--space-2xs);
  }
  .margin-x-2xs\@md {
    margin-right: var(--space-2xs);
    margin-left: var(--space-2xs);
  }
  .margin-xs\@md {
    margin: var(--space-xs);
  }
  .margin-top-xs\@md {
    margin-top: var(--space-xs);
  }
  .margin-right-xs\@md {
    margin-right: var(--space-xs);
  }
  .margin-bottom-xs\@md {
    margin-bottom: var(--space-xs);
  }
  .margin-left-xs\@md {
    margin-left: var(--space-xs);
  }
  .margin-y-xs\@md {
    margin-top: var(--space-xs);
    margin-bottom: var(--space-xs);
  }
  .margin-x-xs\@md {
    margin-right: var(--space-xs);
    margin-left: var(--space-xs);
  }
  .margin-sm\@md {
    margin: var(--space-sm);
  }
  .margin-top-sm\@md {
    margin-top: var(--space-sm);
  }
  .margin-right-sm\@md {
    margin-right: var(--space-sm);
  }
  .margin-bottom-sm\@md {
    margin-bottom: var(--space-sm);
  }
  .margin-left-sm\@md {
    margin-left: var(--space-sm);
  }
  .margin-y-sm\@md {
    margin-top: var(--space-sm);
    margin-bottom: var(--space-sm);
  }
  .margin-x-sm\@md {
    margin-right: var(--space-sm);
    margin-left: var(--space-sm);
  }
  .margin-md\@md {
    margin: var(--space-md);
  }
  .margin-top-md\@md {
    margin-top: var(--space-md);
  }
  .margin-right-md\@md {
    margin-right: var(--space-md);
  }
  .margin-bottom-md\@md {
    margin-bottom: var(--space-md);
  }
  .margin-left-md\@md {
    margin-left: var(--space-md);
  }
  .margin-y-md\@md {
    margin-top: var(--space-md);
    margin-bottom: var(--space-md);
  }
  .margin-x-md\@md {
    margin-right: var(--space-md);
    margin-left: var(--space-md);
  }
  .margin-lg\@md {
    margin: var(--space-lg);
  }
  .margin-top-lg\@md {
    margin-top: var(--space-lg);
  }
  .margin-right-lg\@md {
    margin-right: var(--space-lg);
  }
  .margin-bottom-lg\@md {
    margin-bottom: var(--space-lg);
  }
  .margin-left-lg\@md {
    margin-left: var(--space-lg);
  }
  .margin-y-lg\@md {
    margin-top: var(--space-lg);
    margin-bottom: var(--space-lg);
  }
  .margin-x-lg\@md {
    margin-right: var(--space-lg);
    margin-left: var(--space-lg);
  }
  .margin-xl\@md {
    margin: var(--space-xl);
  }
  .margin-top-xl\@md {
    margin-top: var(--space-xl);
  }
  .margin-right-xl\@md {
    margin-right: var(--space-xl);
  }
  .margin-bottom-xl\@md {
    margin-bottom: var(--space-xl);
  }
  .margin-left-xl\@md {
    margin-left: var(--space-xl);
  }
  .margin-y-xl\@md {
    margin-top: var(--space-xl);
    margin-bottom: var(--space-xl);
  }
  .margin-x-xl\@md {
    margin-right: var(--space-xl);
    margin-left: var(--space-xl);
  }
  .margin-2xl\@md {
    margin: var(--space-2xl);
  }
  .margin-top-2xl\@md {
    margin-top: var(--space-2xl);
  }
  .margin-right-2xl\@md {
    margin-right: var(--space-2xl);
  }
  .margin-bottom-2xl\@md {
    margin-bottom: var(--space-2xl);
  }
  .margin-left-2xl\@md {
    margin-left: var(--space-2xl);
  }
  .margin-y-2xl\@md {
    margin-top: var(--space-2xl);
    margin-bottom: var(--space-2xl);
  }
  .margin-x-2xl\@md {
    margin-right: var(--space-2xl);
    margin-left: var(--space-2xl);
  }
  .margin-3xl\@md {
    margin: var(--space-3xl);
  }
  .margin-top-3xl\@md {
    margin-top: var(--space-3xl);
  }
  .margin-right-3xl\@md {
    margin-right: var(--space-3xl);
  }
  .margin-bottom-3xl\@md {
    margin-bottom: var(--space-3xl);
  }
  .margin-left-3xl\@md {
    margin-left: var(--space-3xl);
  }
  .margin-y-3xl\@md {
    margin-top: var(--space-3xl);
    margin-bottom: var(--space-3xl);
  }
  .margin-x-3xl\@md {
    margin-right: var(--space-3xl);
    margin-left: var(--space-3xl);
  }
  .margin-4xl\@md {
    margin: var(--space-4xl);
  }
  .margin-top-4xl\@md {
    margin-top: var(--space-4xl);
  }
  .margin-right-4xl\@md {
    margin-right: var(--space-4xl);
  }
  .margin-bottom-4xl\@md {
    margin-bottom: var(--space-4xl);
  }
  .margin-left-4xl\@md {
    margin-left: var(--space-4xl);
  }
  .margin-y-4xl\@md {
    margin-top: var(--space-4xl);
    margin-bottom: var(--space-4xl);
  }
  .margin-x-4xl\@md {
    margin-right: var(--space-4xl);
    margin-left: var(--space-4xl);
  }
  .margin-auto\@md {
    margin: auto;
  }
  .margin-0\@md {
    margin: 0;
  }
  .margin-y-auto\@md {
    margin-top: auto;
    margin-bottom: auto;
  }
  .margin-y-0\@md {
    margin-top: 0;
    margin-bottom: 0;
  }
  .margin-x-auto\@md {
    margin-left: auto;
    margin-right: auto;
  }
  .margin-x-0\@md {
    margin-left: 0;
    margin-right: 0;
  }
  .margin-top-auto\@md {
    margin-top: auto;
  }
  .margin-top-0\@md {
    margin-top: 0;
  }
  .margin-right-auto\@md {
    margin-right: auto;
  }
  .margin-right-0\@md {
    margin-right: 0;
  }
  .margin-bottom-auto\@md {
    margin-bottom: auto;
  }
  .margin-bottom-0\@md {
    margin-bottom: 0;
  }
  .margin-left-auto\@md {
    margin-left: auto;
  }
  .margin-left-0\@md {
    margin-left: 0;
  }
  /* padding */
  .padding-4xs\@md {
    padding: var(--space-4xs);
  }
  .padding-top-4xs\@md {
    padding-top: var(--space-4xs);
  }
  .padding-right-4xs\@md {
    padding-right: var(--space-4xs);
  }
  .padding-bottom-4xs\@md {
    padding-bottom: var(--space-4xs);
  }
  .padding-left-4xs\@md {
    padding-left: var(--space-4xs);
  }
  .padding-y-4xs\@md {
    padding-top: var(--space-4xs);
    padding-bottom: var(--space-4xs);
  }
  .padding-x-4xs\@md {
    padding-right: var(--space-4xs);
    padding-left: var(--space-4xs);
  }
  .padding-3xs\@md {
    padding: var(--space-3xs);
  }
  .padding-top-3xs\@md {
    padding-top: var(--space-3xs);
  }
  .padding-right-3xs\@md {
    padding-right: var(--space-3xs);
  }
  .padding-bottom-3xs\@md {
    padding-bottom: var(--space-3xs);
  }
  .padding-left-3xs\@md {
    padding-left: var(--space-3xs);
  }
  .padding-y-3xs\@md {
    padding-top: var(--space-3xs);
    padding-bottom: var(--space-3xs);
  }
  .padding-x-3xs\@md {
    padding-right: var(--space-3xs);
    padding-left: var(--space-3xs);
  }
  .padding-2xs\@md {
    padding: var(--space-2xs);
  }
  .padding-top-2xs\@md {
    padding-top: var(--space-2xs);
  }
  .padding-right-2xs\@md {
    padding-right: var(--space-2xs);
  }
  .padding-bottom-2xs\@md {
    padding-bottom: var(--space-2xs);
  }
  .padding-left-2xs\@md {
    padding-left: var(--space-2xs);
  }
  .padding-y-2xs\@md {
    padding-top: var(--space-2xs);
    padding-bottom: var(--space-2xs);
  }
  .padding-x-2xs\@md {
    padding-right: var(--space-2xs);
    padding-left: var(--space-2xs);
  }
  .padding-xs\@md {
    padding: var(--space-xs);
  }
  .padding-top-xs\@md {
    padding-top: var(--space-xs);
  }
  .padding-right-xs\@md {
    padding-right: var(--space-xs);
  }
  .padding-bottom-xs\@md {
    padding-bottom: var(--space-xs);
  }
  .padding-left-xs\@md {
    padding-left: var(--space-xs);
  }
  .padding-y-xs\@md {
    padding-top: var(--space-xs);
    padding-bottom: var(--space-xs);
  }
  .padding-x-xs\@md {
    padding-right: var(--space-xs);
    padding-left: var(--space-xs);
  }
  .padding-sm\@md {
    padding: var(--space-sm);
  }
  .padding-top-sm\@md {
    padding-top: var(--space-sm);
  }
  .padding-right-sm\@md {
    padding-right: var(--space-sm);
  }
  .padding-bottom-sm\@md {
    padding-bottom: var(--space-sm);
  }
  .padding-left-sm\@md {
    padding-left: var(--space-sm);
  }
  .padding-y-sm\@md {
    padding-top: var(--space-sm);
    padding-bottom: var(--space-sm);
  }
  .padding-x-sm\@md {
    padding-right: var(--space-sm);
    padding-left: var(--space-sm);
  }
  .padding-md\@md {
    padding: var(--space-md);
  }
  .padding-top-md\@md {
    padding-top: var(--space-md);
  }
  .padding-right-md\@md {
    padding-right: var(--space-md);
  }
  .padding-bottom-md\@md {
    padding-bottom: var(--space-md);
  }
  .padding-left-md\@md {
    padding-left: var(--space-md);
  }
  .padding-y-md\@md {
    padding-top: var(--space-md);
    padding-bottom: var(--space-md);
  }
  .padding-x-md\@md {
    padding-right: var(--space-md);
    padding-left: var(--space-md);
  }
  .padding-lg\@md {
    padding: var(--space-lg);
  }
  .padding-top-lg\@md {
    padding-top: var(--space-lg);
  }
  .padding-right-lg\@md {
    padding-right: var(--space-lg);
  }
  .padding-bottom-lg\@md {
    padding-bottom: var(--space-lg);
  }
  .padding-left-lg\@md {
    padding-left: var(--space-lg);
  }
  .padding-y-lg\@md {
    padding-top: var(--space-lg);
    padding-bottom: var(--space-lg);
  }
  .padding-x-lg\@md {
    padding-right: var(--space-lg);
    padding-left: var(--space-lg);
  }
  .padding-xl\@md {
    padding: var(--space-xl);
  }
  .padding-top-xl\@md {
    padding-top: var(--space-xl);
  }
  .padding-right-xl\@md {
    padding-right: var(--space-xl);
  }
  .padding-bottom-xl\@md {
    padding-bottom: var(--space-xl);
  }
  .padding-left-xl\@md {
    padding-left: var(--space-xl);
  }
  .padding-y-xl\@md {
    padding-top: var(--space-xl);
    padding-bottom: var(--space-xl);
  }
  .padding-x-xl\@md {
    padding-right: var(--space-xl);
    padding-left: var(--space-xl);
  }
  .padding-2xl\@md {
    padding: var(--space-2xl);
  }
  .padding-top-2xl\@md {
    padding-top: var(--space-2xl);
  }
  .padding-right-2xl\@md {
    padding-right: var(--space-2xl);
  }
  .padding-bottom-2xl\@md {
    padding-bottom: var(--space-2xl);
  }
  .padding-left-2xl\@md {
    padding-left: var(--space-2xl);
  }
  .padding-y-2xl\@md {
    padding-top: var(--space-2xl);
    padding-bottom: var(--space-2xl);
  }
  .padding-x-2xl\@md {
    padding-right: var(--space-2xl);
    padding-left: var(--space-2xl);
  }
  .padding-3xl\@md {
    padding: var(--space-3xl);
  }
  .padding-top-3xl\@md {
    padding-top: var(--space-3xl);
  }
  .padding-right-3xl\@md {
    padding-right: var(--space-3xl);
  }
  .padding-bottom-3xl\@md {
    padding-bottom: var(--space-3xl);
  }
  .padding-left-3xl\@md {
    padding-left: var(--space-3xl);
  }
  .padding-y-3xl\@md {
    padding-top: var(--space-3xl);
    padding-bottom: var(--space-3xl);
  }
  .padding-x-3xl\@md {
    padding-right: var(--space-3xl);
    padding-left: var(--space-3xl);
  }
  .padding-4xl\@md {
    padding: var(--space-4xl);
  }
  .padding-top-4xl\@md {
    padding-top: var(--space-4xl);
  }
  .padding-right-4xl\@md {
    padding-right: var(--space-4xl);
  }
  .padding-bottom-4xl\@md {
    padding-bottom: var(--space-4xl);
  }
  .padding-left-4xl\@md {
    padding-left: var(--space-4xl);
  }
  .padding-y-4xl\@md {
    padding-top: var(--space-4xl);
    padding-bottom: var(--space-4xl);
  }
  .padding-x-4xl\@md {
    padding-right: var(--space-4xl);
    padding-left: var(--space-4xl);
  }
  .padding-0\@md {
    padding: 0;
  }
  .padding-y-0\@md {
    padding-top: 0;
    padding-bottom: 0;
  }
  .padding-x-0\@md {
    padding-left: 0;
    padding-right: 0;
  }
  .padding-top-0\@md {
    padding-top: 0;
  }
  .padding-right-0\@md {
    padding-right: 0;
  }
  .padding-bottom-0\@md {
    padding-bottom: 0;
  }
  .padding-left-0\@md {
    padding-left: 0;
  }
  /* text-align */
  .text-center\@md {
    text-align: center;
  }
  .text-left\@md {
    text-align: left;
  }
  .text-right\@md {
    text-align: right;
  }
  .text-justify\@md {
    text-align: justify;
  }
  /* font-size */
  .text-xs\@md {
    font-size: var(--text-xs);
  }
  .text-sm\@md {
    font-size: var(--text-sm);
  }
  .text-base\@md {
    font-size: var(--text-base);
  }
  .text-md\@md {
    font-size: var(--text-md);
  }
  .text-lg\@md {
    font-size: var(--text-lg);
  }
  .text-xl\@md {
    font-size: var(--text-xl);
  }
  .text-2xl\@md {
    font-size: var(--text-2xl);
  }
  .text-3xl\@md {
    font-size: var(--text-3xl);
  }
  .text-4xl\@md {
    font-size: var(--text-4xl);
  }
  /* column-count */
  .column-count-1\@md {
    column-count: 1;
  }
  .column-count-2\@md {
    column-count: 2;
  }
  .column-count-3\@md {
    column-count: 3;
  }
  .column-count-4\@md {
    column-count: 4;
  }
  /* width */
  .width-4xs\@md {
    width: 0.25rem;
  }
  .width-3xs\@md {
    width: 0.5rem;
  }
  .width-2xs\@md {
    width: 0.75rem;
  }
  .width-xs\@md {
    width: 1rem;
  }
  .width-sm\@md {
    width: 1.5rem;
  }
  .width-md\@md {
    width: 2rem;
  }
  .width-lg\@md {
    width: 3rem;
  }
  .width-xl\@md {
    width: 4rem;
  }
  .width-2xl\@md {
    width: 6rem;
  }
  .width-3xl\@md {
    width: 8rem;
  }
  .width-4xl\@md {
    width: 16rem;
  }
  .width-0\@md {
    width: 0;
  }
  .width-10\%\@md {
    width: 10%;
  }
  .width-20\%\@md {
    width: 20%;
  }
  .width-25\%\@md {
    width: 25%;
  }
  .width-30\%\@md {
    width: 30%;
  }
  .width-33\%\@md {
    width: 33%;
  }
  .width-40\%\@md {
    width: 40%;
  }
  .width-50\%\@md {
    width: 50%;
  }
  .width-60\%\@md {
    width: 60%;
  }
  .width-70\%\@md {
    width: 70%;
  }
  .width-75\%\@md {
    width: 75%;
  }
  .width-80\%\@md {
    width: 80%;
  }
  .width-90\%\@md {
    width: 90%;
  }
  .width-100\%\@md {
    width: 100%;
  }
  .width-100\%\@md {
    width: 100%;
  }
  .width-100vw\@md {
    width: 100vw;
  }
  .width-auto\@md {
    width: auto;
  }
  .width-inherit\@md {
    width: inherit;
  }
  .width-0\@md {
    width: 0;
  }
  /* height */
  .height-4xs\@md {
    height: 0.25rem;
  }
  .height-3xs\@md {
    height: 0.5rem;
  }
  .height-2xs\@md {
    height: 0.75rem;
  }
  .height-xs\@md {
    height: 1rem;
  }
  .height-sm\@md {
    height: 1.5rem;
  }
  .height-md\@md {
    height: 2rem;
  }
  .height-lg\@md {
    height: 3rem;
  }
  .height-xl\@md {
    height: 4rem;
  }
  .height-2xl\@md {
    height: 6rem;
  }
  .height-3xl\@md {
    height: 8rem;
  }
  .height-4xl\@md {
    height: 16rem;
  }
  .height-0\@md {
    height: 0;
  }
  .height-10\%\@md {
    height: 10%;
  }
  .height-20\%\@md {
    height: 20%;
  }
  .height-25\%\@md {
    height: 25%;
  }
  .height-30\%\@md {
    height: 30%;
  }
  .height-33\%\@md {
    height: 33%;
  }
  .height-40\%\@md {
    height: 40%;
  }
  .height-50\%\@md {
    height: 50%;
  }
  .height-60\%\@md {
    height: 60%;
  }
  .height-70\%\@md {
    height: 70%;
  }
  .height-75\%\@md {
    height: 75%;
  }
  .height-80\%\@md {
    height: 80%;
  }
  .height-90\%\@md {
    height: 90%;
  }
  .height-100\%\@md {
    height: 100%;
  }
  .height-100\%\@md {
    height: 100%;
  }
  .height-100vh\@md {
    height: 100vh;
  }
  .height-auto\@md {
    height: auto;
  }
  .height-inherit\@md {
    height: inherit;
  }
  .height-0\@md {
    height: 0;
  }
  /* max-width */
  .max-width-3xs\@md {
    max-width: var(--max-width-3xs);
  }
  .max-width-2xs\@md {
    max-width: var(--max-width-2xs);
  }
  .max-width-xs\@md {
    max-width: var(--max-width-xs);
  }
  .max-width-sm\@md {
    max-width: var(--max-width-sm);
  }
  .max-width-md\@md {
    max-width: var(--max-width-md);
  }
  .max-width-lg\@md {
    max-width: var(--max-width-lg);
  }
  .max-width-xl\@md {
    max-width: var(--max-width-xl);
  }
  .max-width-100\%\@md {
    max-width: 100%;
  }
  .max-width-none\@md {
    max-width: none;
  }
  /* position */
  .position-relative\@md {
    position: relative;
  }
  .position-absolute\@md {
    position: absolute;
  }
  .position-fixed\@md {
    position: fixed;
  }
  .position-sticky\@md {
    position: sticky;
  }
  .position-static\@md {
    position: static;
  }
  .inset-0\@md {
    inset: 0;
  }
  .top-4xs\@md {
    top: var(--space-4xs);
  }
  .right-4xs\@md {
    right: var(--space-4xs);
  }
  .bottom-4xs\@md {
    bottom: var(--space-4xs);
  }
  .left-4xs\@md {
    left: var(--space-4xs);
  }
  .top-3xs\@md {
    top: var(--space-3xs);
  }
  .right-3xs\@md {
    right: var(--space-3xs);
  }
  .bottom-3xs\@md {
    bottom: var(--space-3xs);
  }
  .left-3xs\@md {
    left: var(--space-3xs);
  }
  .top-2xs\@md {
    top: var(--space-2xs);
  }
  .right-2xs\@md {
    right: var(--space-2xs);
  }
  .bottom-2xs\@md {
    bottom: var(--space-2xs);
  }
  .left-2xs\@md {
    left: var(--space-2xs);
  }
  .top-xs\@md {
    top: var(--space-xs);
  }
  .right-xs\@md {
    right: var(--space-xs);
  }
  .bottom-xs\@md {
    bottom: var(--space-xs);
  }
  .left-xs\@md {
    left: var(--space-xs);
  }
  .top-sm\@md {
    top: var(--space-sm);
  }
  .right-sm\@md {
    right: var(--space-sm);
  }
  .bottom-sm\@md {
    bottom: var(--space-sm);
  }
  .left-sm\@md {
    left: var(--space-sm);
  }
  .top-md\@md {
    top: var(--space-md);
  }
  .right-md\@md {
    right: var(--space-md);
  }
  .bottom-md\@md {
    bottom: var(--space-md);
  }
  .left-md\@md {
    left: var(--space-md);
  }
  .top-lg\@md {
    top: var(--space-lg);
  }
  .right-lg\@md {
    right: var(--space-lg);
  }
  .bottom-lg\@md {
    bottom: var(--space-lg);
  }
  .left-lg\@md {
    left: var(--space-lg);
  }
  .top-xl\@md {
    top: var(--space-xl);
  }
  .right-xl\@md {
    right: var(--space-xl);
  }
  .bottom-xl\@md {
    bottom: var(--space-xl);
  }
  .left-xl\@md {
    left: var(--space-xl);
  }
  .top-2xl\@md {
    top: var(--space-2xl);
  }
  .right-2xl\@md {
    right: var(--space-2xl);
  }
  .bottom-2xl\@md {
    bottom: var(--space-2xl);
  }
  .left-2xl\@md {
    left: var(--space-2xl);
  }
  .top-3xl\@md {
    top: var(--space-3xl);
  }
  .right-3xl\@md {
    right: var(--space-3xl);
  }
  .bottom-3xl\@md {
    bottom: var(--space-3xl);
  }
  .left-3xl\@md {
    left: var(--space-3xl);
  }
  .top-4xl\@md {
    top: var(--space-4xl);
  }
  .right-4xl\@md {
    right: var(--space-4xl);
  }
  .bottom-4xl\@md {
    bottom: var(--space-4xl);
  }
  .left-4xl\@md {
    left: var(--space-4xl);
  }
  .top-50\%\@md {
    top: 50%;
  }
  .top-auto\@md {
    top: auto;
  }
  .top-0\@md {
    top: 0;
  }
  .right-50\%\@md {
    right: 50%;
  }
  .right-auto\@md {
    right: auto;
  }
  .right-0\@md {
    right: 0;
  }
  .bottom-50\%\@md {
    bottom: 50%;
  }
  .bottom-auto\@md {
    bottom: auto;
  }
  .bottom-0\@md {
    bottom: 0;
  }
  .left-50\%\@md {
    left: 50%;
  }
  .left-auto\@md {
    left: auto;
  }
  .left-0\@md {
    left: 0;
  }
  /* overflow */
  .overflow-visible\@md {
    overflow: visible;
  }
  .overflow-hidden\@md {
    overflow: hidden;
  }
  .overflow-auto\@md {
    overflow: auto;
  }
  .momentum-scrolling\@md {
    -webkit-overflow-scrolling: touch;
  }
  /* visibility */
  .visible\@md {
    visibility: visible;
  }
  .invisible\@md {
    visibility: hidden;
  }
}
@media not all and (min-width: 64rem) {
  .display\@md {
    display: none !important;
  }
}
@media (min-width: 80rem) {
  /* grid */
  .grid-col-1\@lg {
    --grid-columns: 1;
  }
  .col-1\@lg {
    --column-span: 1;
  }
  .grid-col-2\@lg {
    --grid-columns: 2;
  }
  .col-2\@lg {
    --column-span: 2;
  }
  .grid-col-3\@lg {
    --grid-columns: 3;
  }
  .col-3\@lg {
    --column-span: 3;
  }
  .grid-col-4\@lg {
    --grid-columns: 4;
  }
  .col-4\@lg {
    --column-span: 4;
  }
  .grid-col-5\@lg {
    --grid-columns: 5;
  }
  .col-5\@lg {
    --column-span: 5;
  }
  .grid-col-6\@lg {
    --grid-columns: 6;
  }
  .col-6\@lg {
    --column-span: 6;
  }
  .grid-col-7\@lg {
    --grid-columns: 7;
  }
  .col-7\@lg {
    --column-span: 7;
  }
  .grid-col-8\@lg {
    --grid-columns: 8;
  }
  .col-8\@lg {
    --column-span: 8;
  }
  .grid-col-9\@lg {
    --grid-columns: 9;
  }
  .col-9\@lg {
    --column-span: 9;
  }
  .grid-col-10\@lg {
    --grid-columns: 10;
  }
  .col-10\@lg {
    --column-span: 10;
  }
  .grid-col-11\@lg {
    --grid-columns: 11;
  }
  .col-11\@lg {
    --column-span: 11;
  }
  .grid-col-12\@lg {
    --grid-columns: 12;
  }
  .col-12\@lg {
    --column-span: 12;
  }
  .col-1\@lg, .col-2\@lg, .col-3\@lg, .col-4\@lg, .col-5\@lg, .col-6\@lg, .col-7\@lg, .col-8\@lg, .col-9\@lg, .col-10\@lg, .col-11\@lg, .col-12\@lg {
    width: calc((100% - (var(--grid-columns) - 1) * var(--column-gap)) * var(--column-span) / var(--grid-columns) + (var(--column-span) - 1) * var(--column-gap));
    flex: initial;
  }
  .col\@lg {
    width: auto;
    flex: 1 1 0;
  }
  .col-content\@lg {
    width: auto;
    flex: 0 1 auto;
  }
  .offset-1\@lg {
    --column-offset: 1;
  }
  .offset-2\@lg {
    --column-offset: 2;
  }
  .offset-3\@lg {
    --column-offset: 3;
  }
  .offset-4\@lg {
    --column-offset: 4;
  }
  .offset-5\@lg {
    --column-offset: 5;
  }
  .offset-6\@lg {
    --column-offset: 6;
  }
  .offset-7\@lg {
    --column-offset: 7;
  }
  .offset-8\@lg {
    --column-offset: 8;
  }
  .offset-9\@lg {
    --column-offset: 9;
  }
  .offset-10\@lg {
    --column-offset: 10;
  }
  .offset-11\@lg {
    --column-offset: 11;
  }
  .offset-1\@lg, .offset-2\@lg, .offset-3\@lg, .offset-4\@lg, .offset-5\@lg, .offset-6\@lg, .offset-7\@lg, .offset-8\@lg, .offset-9\@lg, .offset-10\@lg, .offset-11\@lg {
    margin-left: calc((100% - (var(--grid-columns) - 1) * var(--column-gap)) * var(--column-offset) / var(--grid-columns) + var(--column-offset) * var(--column-gap));
  }
  .offset-0\@lg {
    margin-left: 0;
  }
  /* gap */
  .gap-4xs\@lg {
    --row-gap: var(--space-4xs);
    --column-gap: var(--space-4xs);
    gap: var(--row-gap) var(--column-gap);
  }
  .gap-x-4xs\@lg {
    --column-gap: var(--space-4xs);
    column-gap: var(--column-gap);
  }
  .gap-y-4xs\@lg {
    --row-gap: var(--space-4xs);
    row-gap: var(--row-gap);
  }
  .gap-3xs\@lg {
    --row-gap: var(--space-3xs);
    --column-gap: var(--space-3xs);
    gap: var(--row-gap) var(--column-gap);
  }
  .gap-x-3xs\@lg {
    --column-gap: var(--space-3xs);
    column-gap: var(--column-gap);
  }
  .gap-y-3xs\@lg {
    --row-gap: var(--space-3xs);
    row-gap: var(--row-gap);
  }
  .gap-2xs\@lg {
    --row-gap: var(--space-2xs);
    --column-gap: var(--space-2xs);
    gap: var(--row-gap) var(--column-gap);
  }
  .gap-x-2xs\@lg {
    --column-gap: var(--space-2xs);
    column-gap: var(--column-gap);
  }
  .gap-y-2xs\@lg {
    --row-gap: var(--space-2xs);
    row-gap: var(--row-gap);
  }
  .gap-xs\@lg {
    --row-gap: var(--space-xs);
    --column-gap: var(--space-xs);
    gap: var(--row-gap) var(--column-gap);
  }
  .gap-x-xs\@lg {
    --column-gap: var(--space-xs);
    column-gap: var(--column-gap);
  }
  .gap-y-xs\@lg {
    --row-gap: var(--space-xs);
    row-gap: var(--row-gap);
  }
  .gap-sm\@lg {
    --row-gap: var(--space-sm);
    --column-gap: var(--space-sm);
    gap: var(--row-gap) var(--column-gap);
  }
  .gap-x-sm\@lg {
    --column-gap: var(--space-sm);
    column-gap: var(--column-gap);
  }
  .gap-y-sm\@lg {
    --row-gap: var(--space-sm);
    row-gap: var(--row-gap);
  }
  .gap-md\@lg {
    --row-gap: var(--space-md);
    --column-gap: var(--space-md);
    gap: var(--row-gap) var(--column-gap);
  }
  .gap-x-md\@lg {
    --column-gap: var(--space-md);
    column-gap: var(--column-gap);
  }
  .gap-y-md\@lg {
    --row-gap: var(--space-md);
    row-gap: var(--row-gap);
  }
  .gap-lg\@lg {
    --row-gap: var(--space-lg);
    --column-gap: var(--space-lg);
    gap: var(--row-gap) var(--column-gap);
  }
  .gap-x-lg\@lg {
    --column-gap: var(--space-lg);
    column-gap: var(--column-gap);
  }
  .gap-y-lg\@lg {
    --row-gap: var(--space-lg);
    row-gap: var(--row-gap);
  }
  .gap-xl\@lg {
    --row-gap: var(--space-xl);
    --column-gap: var(--space-xl);
    gap: var(--row-gap) var(--column-gap);
  }
  .gap-x-xl\@lg {
    --column-gap: var(--space-xl);
    column-gap: var(--column-gap);
  }
  .gap-y-xl\@lg {
    --row-gap: var(--space-xl);
    row-gap: var(--row-gap);
  }
  .gap-2xl\@lg {
    --row-gap: var(--space-2xl);
    --column-gap: var(--space-2xl);
    gap: var(--row-gap) var(--column-gap);
  }
  .gap-x-2xl\@lg {
    --column-gap: var(--space-2xl);
    column-gap: var(--column-gap);
  }
  .gap-y-2xl\@lg {
    --row-gap: var(--space-2xl);
    row-gap: var(--row-gap);
  }
  .gap-3xl\@lg {
    --row-gap: var(--space-3xl);
    --column-gap: var(--space-3xl);
    gap: var(--row-gap) var(--column-gap);
  }
  .gap-x-3xl\@lg {
    --column-gap: var(--space-3xl);
    column-gap: var(--column-gap);
  }
  .gap-y-3xl\@lg {
    --row-gap: var(--space-3xl);
    row-gap: var(--row-gap);
  }
  .gap-4xl\@lg {
    --row-gap: var(--space-4xl);
    --column-gap: var(--space-4xl);
    gap: var(--row-gap) var(--column-gap);
  }
  .gap-x-4xl\@lg {
    --column-gap: var(--space-4xl);
    column-gap: var(--column-gap);
  }
  .gap-y-4xl\@lg {
    --row-gap: var(--space-4xl);
    row-gap: var(--row-gap);
  }
  .gap-0\@lg {
    --row-gap: 0px;
    --column-gap: 0px;
    gap: 0px;
  }
  .gap-x-0\@lg {
    --column-gap: 0px;
    column-gap: 0px;
  }
  .gap-y-0\@lg {
    --row-gap: 0px;
    row-gap: 0px;
  }
  /* flexbox */
  .flex\@lg {
    display: flex;
  }
  .inline-flex\@lg {
    display: inline-flex;
  }
  .flex-wrap\@lg {
    flex-wrap: wrap;
  }
  .flex-nowrap\@lg {
    flex-wrap: nowrap;
  }
  .flex-column\@lg {
    flex-direction: column;
  }
  .flex-column-reverse\@lg {
    flex-direction: column-reverse;
  }
  .flex-row\@lg {
    flex-direction: row;
  }
  .flex-row-reverse\@lg {
    flex-direction: row-reverse;
  }
  .flex-center\@lg {
    justify-content: center;
    align-items: center;
  }
  .flex-grow\@lg {
    flex-grow: 1;
  }
  .flex-grow-0\@lg {
    flex-grow: 0;
  }
  .flex-shrink\@lg {
    flex-shrink: 1;
  }
  .flex-shrink-0\@lg {
    flex-shrink: 0;
  }
  .flex-basis-0\@lg {
    flex-basis: 0;
  }
  /* justify-content */
  .justify-start\@lg {
    justify-content: start;
  }
  .justify-end\@lg {
    justify-content: end;
  }
  .justify-center\@lg {
    justify-content: center;
  }
  .justify-between\@lg {
    justify-content: space-between;
  }
  /* align-items */
  .items-center\@lg {
    align-items: center;
  }
  .items-start\@lg {
    align-items: start;
  }
  .items-end\@lg {
    align-items: end;
  }
  .items-baseline\@lg {
    align-items: baseline;
  }
  .items-stretch\@lg {
    align-items: stretch;
  }
  /* align-content */
  .content-start\@lg {
    align-content: start;
  }
  .content-end\@lg {
    align-content: end;
  }
  .content-center\@lg {
    align-content: center;
  }
  .content-between\@lg {
    align-content: space-between;
  }
  /* order */
  .order-1\@lg {
    order: 1;
  }
  .order-2\@lg {
    order: 2;
  }
  .order-3\@lg {
    order: 3;
  }
  /* display */
  .block\@lg {
    display: block;
  }
  .inline-block\@lg {
    display: inline-block;
  }
  .inline\@lg {
    display: inline;
  }
  .contents\@lg {
    display: contents;
  }
  .css-grid\@lg {
    display: grid;
  }
  .css-inline-grid\@lg {
    display: inline-grid;
  }
  .hide\@lg {
    display: none;
  }
  /* margin */
  .margin-4xs\@lg {
    margin: var(--space-4xs);
  }
  .margin-top-4xs\@lg {
    margin-top: var(--space-4xs);
  }
  .margin-right-4xs\@lg {
    margin-right: var(--space-4xs);
  }
  .margin-bottom-4xs\@lg {
    margin-bottom: var(--space-4xs);
  }
  .margin-left-4xs\@lg {
    margin-left: var(--space-4xs);
  }
  .margin-y-4xs\@lg {
    margin-top: var(--space-4xs);
    margin-bottom: var(--space-4xs);
  }
  .margin-x-4xs\@lg {
    margin-right: var(--space-4xs);
    margin-left: var(--space-4xs);
  }
  .margin-3xs\@lg {
    margin: var(--space-3xs);
  }
  .margin-top-3xs\@lg {
    margin-top: var(--space-3xs);
  }
  .margin-right-3xs\@lg {
    margin-right: var(--space-3xs);
  }
  .margin-bottom-3xs\@lg {
    margin-bottom: var(--space-3xs);
  }
  .margin-left-3xs\@lg {
    margin-left: var(--space-3xs);
  }
  .margin-y-3xs\@lg {
    margin-top: var(--space-3xs);
    margin-bottom: var(--space-3xs);
  }
  .margin-x-3xs\@lg {
    margin-right: var(--space-3xs);
    margin-left: var(--space-3xs);
  }
  .margin-2xs\@lg {
    margin: var(--space-2xs);
  }
  .margin-top-2xs\@lg {
    margin-top: var(--space-2xs);
  }
  .margin-right-2xs\@lg {
    margin-right: var(--space-2xs);
  }
  .margin-bottom-2xs\@lg {
    margin-bottom: var(--space-2xs);
  }
  .margin-left-2xs\@lg {
    margin-left: var(--space-2xs);
  }
  .margin-y-2xs\@lg {
    margin-top: var(--space-2xs);
    margin-bottom: var(--space-2xs);
  }
  .margin-x-2xs\@lg {
    margin-right: var(--space-2xs);
    margin-left: var(--space-2xs);
  }
  .margin-xs\@lg {
    margin: var(--space-xs);
  }
  .margin-top-xs\@lg {
    margin-top: var(--space-xs);
  }
  .margin-right-xs\@lg {
    margin-right: var(--space-xs);
  }
  .margin-bottom-xs\@lg {
    margin-bottom: var(--space-xs);
  }
  .margin-left-xs\@lg {
    margin-left: var(--space-xs);
  }
  .margin-y-xs\@lg {
    margin-top: var(--space-xs);
    margin-bottom: var(--space-xs);
  }
  .margin-x-xs\@lg {
    margin-right: var(--space-xs);
    margin-left: var(--space-xs);
  }
  .margin-sm\@lg {
    margin: var(--space-sm);
  }
  .margin-top-sm\@lg {
    margin-top: var(--space-sm);
  }
  .margin-right-sm\@lg {
    margin-right: var(--space-sm);
  }
  .margin-bottom-sm\@lg {
    margin-bottom: var(--space-sm);
  }
  .margin-left-sm\@lg {
    margin-left: var(--space-sm);
  }
  .margin-y-sm\@lg {
    margin-top: var(--space-sm);
    margin-bottom: var(--space-sm);
  }
  .margin-x-sm\@lg {
    margin-right: var(--space-sm);
    margin-left: var(--space-sm);
  }
  .margin-md\@lg {
    margin: var(--space-md);
  }
  .margin-top-md\@lg {
    margin-top: var(--space-md);
  }
  .margin-right-md\@lg {
    margin-right: var(--space-md);
  }
  .margin-bottom-md\@lg {
    margin-bottom: var(--space-md);
  }
  .margin-left-md\@lg {
    margin-left: var(--space-md);
  }
  .margin-y-md\@lg {
    margin-top: var(--space-md);
    margin-bottom: var(--space-md);
  }
  .margin-x-md\@lg {
    margin-right: var(--space-md);
    margin-left: var(--space-md);
  }
  .margin-lg\@lg {
    margin: var(--space-lg);
  }
  .margin-top-lg\@lg {
    margin-top: var(--space-lg);
  }
  .margin-right-lg\@lg {
    margin-right: var(--space-lg);
  }
  .margin-bottom-lg\@lg {
    margin-bottom: var(--space-lg);
  }
  .margin-left-lg\@lg {
    margin-left: var(--space-lg);
  }
  .margin-y-lg\@lg {
    margin-top: var(--space-lg);
    margin-bottom: var(--space-lg);
  }
  .margin-x-lg\@lg {
    margin-right: var(--space-lg);
    margin-left: var(--space-lg);
  }
  .margin-xl\@lg {
    margin: var(--space-xl);
  }
  .margin-top-xl\@lg {
    margin-top: var(--space-xl);
  }
  .margin-right-xl\@lg {
    margin-right: var(--space-xl);
  }
  .margin-bottom-xl\@lg {
    margin-bottom: var(--space-xl);
  }
  .margin-left-xl\@lg {
    margin-left: var(--space-xl);
  }
  .margin-y-xl\@lg {
    margin-top: var(--space-xl);
    margin-bottom: var(--space-xl);
  }
  .margin-x-xl\@lg {
    margin-right: var(--space-xl);
    margin-left: var(--space-xl);
  }
  .margin-2xl\@lg {
    margin: var(--space-2xl);
  }
  .margin-top-2xl\@lg {
    margin-top: var(--space-2xl);
  }
  .margin-right-2xl\@lg {
    margin-right: var(--space-2xl);
  }
  .margin-bottom-2xl\@lg {
    margin-bottom: var(--space-2xl);
  }
  .margin-left-2xl\@lg {
    margin-left: var(--space-2xl);
  }
  .margin-y-2xl\@lg {
    margin-top: var(--space-2xl);
    margin-bottom: var(--space-2xl);
  }
  .margin-x-2xl\@lg {
    margin-right: var(--space-2xl);
    margin-left: var(--space-2xl);
  }
  .margin-3xl\@lg {
    margin: var(--space-3xl);
  }
  .margin-top-3xl\@lg {
    margin-top: var(--space-3xl);
  }
  .margin-right-3xl\@lg {
    margin-right: var(--space-3xl);
  }
  .margin-bottom-3xl\@lg {
    margin-bottom: var(--space-3xl);
  }
  .margin-left-3xl\@lg {
    margin-left: var(--space-3xl);
  }
  .margin-y-3xl\@lg {
    margin-top: var(--space-3xl);
    margin-bottom: var(--space-3xl);
  }
  .margin-x-3xl\@lg {
    margin-right: var(--space-3xl);
    margin-left: var(--space-3xl);
  }
  .margin-4xl\@lg {
    margin: var(--space-4xl);
  }
  .margin-top-4xl\@lg {
    margin-top: var(--space-4xl);
  }
  .margin-right-4xl\@lg {
    margin-right: var(--space-4xl);
  }
  .margin-bottom-4xl\@lg {
    margin-bottom: var(--space-4xl);
  }
  .margin-left-4xl\@lg {
    margin-left: var(--space-4xl);
  }
  .margin-y-4xl\@lg {
    margin-top: var(--space-4xl);
    margin-bottom: var(--space-4xl);
  }
  .margin-x-4xl\@lg {
    margin-right: var(--space-4xl);
    margin-left: var(--space-4xl);
  }
  .margin-auto\@lg {
    margin: auto;
  }
  .margin-0\@lg {
    margin: 0;
  }
  .margin-y-auto\@lg {
    margin-top: auto;
    margin-bottom: auto;
  }
  .margin-y-0\@lg {
    margin-top: 0;
    margin-bottom: 0;
  }
  .margin-x-auto\@lg {
    margin-left: auto;
    margin-right: auto;
  }
  .margin-x-0\@lg {
    margin-left: 0;
    margin-right: 0;
  }
  .margin-top-auto\@lg {
    margin-top: auto;
  }
  .margin-top-0\@lg {
    margin-top: 0;
  }
  .margin-right-auto\@lg {
    margin-right: auto;
  }
  .margin-right-0\@lg {
    margin-right: 0;
  }
  .margin-bottom-auto\@lg {
    margin-bottom: auto;
  }
  .margin-bottom-0\@lg {
    margin-bottom: 0;
  }
  .margin-left-auto\@lg {
    margin-left: auto;
  }
  .margin-left-0\@lg {
    margin-left: 0;
  }
  /* padding */
  .padding-4xs\@lg {
    padding: var(--space-4xs);
  }
  .padding-top-4xs\@lg {
    padding-top: var(--space-4xs);
  }
  .padding-right-4xs\@lg {
    padding-right: var(--space-4xs);
  }
  .padding-bottom-4xs\@lg {
    padding-bottom: var(--space-4xs);
  }
  .padding-left-4xs\@lg {
    padding-left: var(--space-4xs);
  }
  .padding-y-4xs\@lg {
    padding-top: var(--space-4xs);
    padding-bottom: var(--space-4xs);
  }
  .padding-x-4xs\@lg {
    padding-right: var(--space-4xs);
    padding-left: var(--space-4xs);
  }
  .padding-3xs\@lg {
    padding: var(--space-3xs);
  }
  .padding-top-3xs\@lg {
    padding-top: var(--space-3xs);
  }
  .padding-right-3xs\@lg {
    padding-right: var(--space-3xs);
  }
  .padding-bottom-3xs\@lg {
    padding-bottom: var(--space-3xs);
  }
  .padding-left-3xs\@lg {
    padding-left: var(--space-3xs);
  }
  .padding-y-3xs\@lg {
    padding-top: var(--space-3xs);
    padding-bottom: var(--space-3xs);
  }
  .padding-x-3xs\@lg {
    padding-right: var(--space-3xs);
    padding-left: var(--space-3xs);
  }
  .padding-2xs\@lg {
    padding: var(--space-2xs);
  }
  .padding-top-2xs\@lg {
    padding-top: var(--space-2xs);
  }
  .padding-right-2xs\@lg {
    padding-right: var(--space-2xs);
  }
  .padding-bottom-2xs\@lg {
    padding-bottom: var(--space-2xs);
  }
  .padding-left-2xs\@lg {
    padding-left: var(--space-2xs);
  }
  .padding-y-2xs\@lg {
    padding-top: var(--space-2xs);
    padding-bottom: var(--space-2xs);
  }
  .padding-x-2xs\@lg {
    padding-right: var(--space-2xs);
    padding-left: var(--space-2xs);
  }
  .padding-xs\@lg {
    padding: var(--space-xs);
  }
  .padding-top-xs\@lg {
    padding-top: var(--space-xs);
  }
  .padding-right-xs\@lg {
    padding-right: var(--space-xs);
  }
  .padding-bottom-xs\@lg {
    padding-bottom: var(--space-xs);
  }
  .padding-left-xs\@lg {
    padding-left: var(--space-xs);
  }
  .padding-y-xs\@lg {
    padding-top: var(--space-xs);
    padding-bottom: var(--space-xs);
  }
  .padding-x-xs\@lg {
    padding-right: var(--space-xs);
    padding-left: var(--space-xs);
  }
  .padding-sm\@lg {
    padding: var(--space-sm);
  }
  .padding-top-sm\@lg {
    padding-top: var(--space-sm);
  }
  .padding-right-sm\@lg {
    padding-right: var(--space-sm);
  }
  .padding-bottom-sm\@lg {
    padding-bottom: var(--space-sm);
  }
  .padding-left-sm\@lg {
    padding-left: var(--space-sm);
  }
  .padding-y-sm\@lg {
    padding-top: var(--space-sm);
    padding-bottom: var(--space-sm);
  }
  .padding-x-sm\@lg {
    padding-right: var(--space-sm);
    padding-left: var(--space-sm);
  }
  .padding-md\@lg {
    padding: var(--space-md);
  }
  .padding-top-md\@lg {
    padding-top: var(--space-md);
  }
  .padding-right-md\@lg {
    padding-right: var(--space-md);
  }
  .padding-bottom-md\@lg {
    padding-bottom: var(--space-md);
  }
  .padding-left-md\@lg {
    padding-left: var(--space-md);
  }
  .padding-y-md\@lg {
    padding-top: var(--space-md);
    padding-bottom: var(--space-md);
  }
  .padding-x-md\@lg {
    padding-right: var(--space-md);
    padding-left: var(--space-md);
  }
  .padding-lg\@lg {
    padding: var(--space-lg);
  }
  .padding-top-lg\@lg {
    padding-top: var(--space-lg);
  }
  .padding-right-lg\@lg {
    padding-right: var(--space-lg);
  }
  .padding-bottom-lg\@lg {
    padding-bottom: var(--space-lg);
  }
  .padding-left-lg\@lg {
    padding-left: var(--space-lg);
  }
  .padding-y-lg\@lg {
    padding-top: var(--space-lg);
    padding-bottom: var(--space-lg);
  }
  .padding-x-lg\@lg {
    padding-right: var(--space-lg);
    padding-left: var(--space-lg);
  }
  .padding-xl\@lg {
    padding: var(--space-xl);
  }
  .padding-top-xl\@lg {
    padding-top: var(--space-xl);
  }
  .padding-right-xl\@lg {
    padding-right: var(--space-xl);
  }
  .padding-bottom-xl\@lg {
    padding-bottom: var(--space-xl);
  }
  .padding-left-xl\@lg {
    padding-left: var(--space-xl);
  }
  .padding-y-xl\@lg {
    padding-top: var(--space-xl);
    padding-bottom: var(--space-xl);
  }
  .padding-x-xl\@lg {
    padding-right: var(--space-xl);
    padding-left: var(--space-xl);
  }
  .padding-2xl\@lg {
    padding: var(--space-2xl);
  }
  .padding-top-2xl\@lg {
    padding-top: var(--space-2xl);
  }
  .padding-right-2xl\@lg {
    padding-right: var(--space-2xl);
  }
  .padding-bottom-2xl\@lg {
    padding-bottom: var(--space-2xl);
  }
  .padding-left-2xl\@lg {
    padding-left: var(--space-2xl);
  }
  .padding-y-2xl\@lg {
    padding-top: var(--space-2xl);
    padding-bottom: var(--space-2xl);
  }
  .padding-x-2xl\@lg {
    padding-right: var(--space-2xl);
    padding-left: var(--space-2xl);
  }
  .padding-3xl\@lg {
    padding: var(--space-3xl);
  }
  .padding-top-3xl\@lg {
    padding-top: var(--space-3xl);
  }
  .padding-right-3xl\@lg {
    padding-right: var(--space-3xl);
  }
  .padding-bottom-3xl\@lg {
    padding-bottom: var(--space-3xl);
  }
  .padding-left-3xl\@lg {
    padding-left: var(--space-3xl);
  }
  .padding-y-3xl\@lg {
    padding-top: var(--space-3xl);
    padding-bottom: var(--space-3xl);
  }
  .padding-x-3xl\@lg {
    padding-right: var(--space-3xl);
    padding-left: var(--space-3xl);
  }
  .padding-4xl\@lg {
    padding: var(--space-4xl);
  }
  .padding-top-4xl\@lg {
    padding-top: var(--space-4xl);
  }
  .padding-right-4xl\@lg {
    padding-right: var(--space-4xl);
  }
  .padding-bottom-4xl\@lg {
    padding-bottom: var(--space-4xl);
  }
  .padding-left-4xl\@lg {
    padding-left: var(--space-4xl);
  }
  .padding-y-4xl\@lg {
    padding-top: var(--space-4xl);
    padding-bottom: var(--space-4xl);
  }
  .padding-x-4xl\@lg {
    padding-right: var(--space-4xl);
    padding-left: var(--space-4xl);
  }
  .padding-0\@lg {
    padding: 0;
  }
  .padding-y-0\@lg {
    padding-top: 0;
    padding-bottom: 0;
  }
  .padding-x-0\@lg {
    padding-left: 0;
    padding-right: 0;
  }
  .padding-top-0\@lg {
    padding-top: 0;
  }
  .padding-right-0\@lg {
    padding-right: 0;
  }
  .padding-bottom-0\@lg {
    padding-bottom: 0;
  }
  .padding-left-0\@lg {
    padding-left: 0;
  }
  /* text-align */
  .text-center\@lg {
    text-align: center;
  }
  .text-left\@lg {
    text-align: left;
  }
  .text-right\@lg {
    text-align: right;
  }
  .text-justify\@lg {
    text-align: justify;
  }
  /* font-size */
  .text-xs\@lg {
    font-size: var(--text-xs);
  }
  .text-sm\@lg {
    font-size: var(--text-sm);
  }
  .text-base\@lg {
    font-size: var(--text-base);
  }
  .text-md\@lg {
    font-size: var(--text-md);
  }
  .text-lg\@lg {
    font-size: var(--text-lg);
  }
  .text-xl\@lg {
    font-size: var(--text-xl);
  }
  .text-2xl\@lg {
    font-size: var(--text-2xl);
  }
  .text-3xl\@lg {
    font-size: var(--text-3xl);
  }
  .text-4xl\@lg {
    font-size: var(--text-4xl);
  }
  /* column-count */
  .column-count-1\@lg {
    column-count: 1;
  }
  .column-count-2\@lg {
    column-count: 2;
  }
  .column-count-3\@lg {
    column-count: 3;
  }
  .column-count-4\@lg {
    column-count: 4;
  }
  /* width */
  .width-4xs\@lg {
    width: 0.25rem;
  }
  .width-3xs\@lg {
    width: 0.5rem;
  }
  .width-2xs\@lg {
    width: 0.75rem;
  }
  .width-xs\@lg {
    width: 1rem;
  }
  .width-sm\@lg {
    width: 1.5rem;
  }
  .width-md\@lg {
    width: 2rem;
  }
  .width-lg\@lg {
    width: 3rem;
  }
  .width-xl\@lg {
    width: 4rem;
  }
  .width-2xl\@lg {
    width: 6rem;
  }
  .width-3xl\@lg {
    width: 8rem;
  }
  .width-4xl\@lg {
    width: 16rem;
  }
  .width-0\@lg {
    width: 0;
  }
  .width-10\%\@lg {
    width: 10%;
  }
  .width-20\%\@lg {
    width: 20%;
  }
  .width-25\%\@lg {
    width: 25%;
  }
  .width-30\%\@lg {
    width: 30%;
  }
  .width-33\%\@lg {
    width: 33%;
  }
  .width-40\%\@lg {
    width: 40%;
  }
  .width-50\%\@lg {
    width: 50%;
  }
  .width-60\%\@lg {
    width: 60%;
  }
  .width-70\%\@lg {
    width: 70%;
  }
  .width-75\%\@lg {
    width: 75%;
  }
  .width-80\%\@lg {
    width: 80%;
  }
  .width-90\%\@lg {
    width: 90%;
  }
  .width-100\%\@lg {
    width: 100%;
  }
  .width-100\%\@lg {
    width: 100%;
  }
  .width-100vw\@lg {
    width: 100vw;
  }
  .width-auto\@lg {
    width: auto;
  }
  .width-inherit\@lg {
    width: inherit;
  }
  .width-0\@lg {
    width: 0;
  }
  /* height */
  .height-4xs\@lg {
    height: 0.25rem;
  }
  .height-3xs\@lg {
    height: 0.5rem;
  }
  .height-2xs\@lg {
    height: 0.75rem;
  }
  .height-xs\@lg {
    height: 1rem;
  }
  .height-sm\@lg {
    height: 1.5rem;
  }
  .height-md\@lg {
    height: 2rem;
  }
  .height-lg\@lg {
    height: 3rem;
  }
  .height-xl\@lg {
    height: 4rem;
  }
  .height-2xl\@lg {
    height: 6rem;
  }
  .height-3xl\@lg {
    height: 8rem;
  }
  .height-4xl\@lg {
    height: 16rem;
  }
  .height-0\@lg {
    height: 0;
  }
  .height-10\%\@lg {
    height: 10%;
  }
  .height-20\%\@lg {
    height: 20%;
  }
  .height-25\%\@lg {
    height: 25%;
  }
  .height-30\%\@lg {
    height: 30%;
  }
  .height-33\%\@lg {
    height: 33%;
  }
  .height-40\%\@lg {
    height: 40%;
  }
  .height-50\%\@lg {
    height: 50%;
  }
  .height-60\%\@lg {
    height: 60%;
  }
  .height-70\%\@lg {
    height: 70%;
  }
  .height-75\%\@lg {
    height: 75%;
  }
  .height-80\%\@lg {
    height: 80%;
  }
  .height-90\%\@lg {
    height: 90%;
  }
  .height-100\%\@lg {
    height: 100%;
  }
  .height-100\%\@lg {
    height: 100%;
  }
  .height-100vh\@lg {
    height: 100vh;
  }
  .height-auto\@lg {
    height: auto;
  }
  .height-inherit\@lg {
    height: inherit;
  }
  .height-0\@lg {
    height: 0;
  }
  /* max-width */
  .max-width-3xs\@lg {
    max-width: var(--max-width-3xs);
  }
  .max-width-2xs\@lg {
    max-width: var(--max-width-2xs);
  }
  .max-width-xs\@lg {
    max-width: var(--max-width-xs);
  }
  .max-width-sm\@lg {
    max-width: var(--max-width-sm);
  }
  .max-width-md\@lg {
    max-width: var(--max-width-md);
  }
  .max-width-lg\@lg {
    max-width: var(--max-width-lg);
  }
  .max-width-xl\@lg {
    max-width: var(--max-width-xl);
  }
  .max-width-100\%\@lg {
    max-width: 100%;
  }
  .max-width-none\@lg {
    max-width: none;
  }
  /* position */
  .position-relative\@lg {
    position: relative;
  }
  .position-absolute\@lg {
    position: absolute;
  }
  .position-fixed\@lg {
    position: fixed;
  }
  .position-sticky\@lg {
    position: sticky;
  }
  .position-static\@lg {
    position: static;
  }
  .inset-0\@lg {
    inset: 0;
  }
  .top-4xs\@lg {
    top: var(--space-4xs);
  }
  .right-4xs\@lg {
    right: var(--space-4xs);
  }
  .bottom-4xs\@lg {
    bottom: var(--space-4xs);
  }
  .left-4xs\@lg {
    left: var(--space-4xs);
  }
  .top-3xs\@lg {
    top: var(--space-3xs);
  }
  .right-3xs\@lg {
    right: var(--space-3xs);
  }
  .bottom-3xs\@lg {
    bottom: var(--space-3xs);
  }
  .left-3xs\@lg {
    left: var(--space-3xs);
  }
  .top-2xs\@lg {
    top: var(--space-2xs);
  }
  .right-2xs\@lg {
    right: var(--space-2xs);
  }
  .bottom-2xs\@lg {
    bottom: var(--space-2xs);
  }
  .left-2xs\@lg {
    left: var(--space-2xs);
  }
  .top-xs\@lg {
    top: var(--space-xs);
  }
  .right-xs\@lg {
    right: var(--space-xs);
  }
  .bottom-xs\@lg {
    bottom: var(--space-xs);
  }
  .left-xs\@lg {
    left: var(--space-xs);
  }
  .top-sm\@lg {
    top: var(--space-sm);
  }
  .right-sm\@lg {
    right: var(--space-sm);
  }
  .bottom-sm\@lg {
    bottom: var(--space-sm);
  }
  .left-sm\@lg {
    left: var(--space-sm);
  }
  .top-md\@lg {
    top: var(--space-md);
  }
  .right-md\@lg {
    right: var(--space-md);
  }
  .bottom-md\@lg {
    bottom: var(--space-md);
  }
  .left-md\@lg {
    left: var(--space-md);
  }
  .top-lg\@lg {
    top: var(--space-lg);
  }
  .right-lg\@lg {
    right: var(--space-lg);
  }
  .bottom-lg\@lg {
    bottom: var(--space-lg);
  }
  .left-lg\@lg {
    left: var(--space-lg);
  }
  .top-xl\@lg {
    top: var(--space-xl);
  }
  .right-xl\@lg {
    right: var(--space-xl);
  }
  .bottom-xl\@lg {
    bottom: var(--space-xl);
  }
  .left-xl\@lg {
    left: var(--space-xl);
  }
  .top-2xl\@lg {
    top: var(--space-2xl);
  }
  .right-2xl\@lg {
    right: var(--space-2xl);
  }
  .bottom-2xl\@lg {
    bottom: var(--space-2xl);
  }
  .left-2xl\@lg {
    left: var(--space-2xl);
  }
  .top-3xl\@lg {
    top: var(--space-3xl);
  }
  .right-3xl\@lg {
    right: var(--space-3xl);
  }
  .bottom-3xl\@lg {
    bottom: var(--space-3xl);
  }
  .left-3xl\@lg {
    left: var(--space-3xl);
  }
  .top-4xl\@lg {
    top: var(--space-4xl);
  }
  .right-4xl\@lg {
    right: var(--space-4xl);
  }
  .bottom-4xl\@lg {
    bottom: var(--space-4xl);
  }
  .left-4xl\@lg {
    left: var(--space-4xl);
  }
  .top-50\%\@lg {
    top: 50%;
  }
  .top-auto\@lg {
    top: auto;
  }
  .top-0\@lg {
    top: 0;
  }
  .right-50\%\@lg {
    right: 50%;
  }
  .right-auto\@lg {
    right: auto;
  }
  .right-0\@lg {
    right: 0;
  }
  .bottom-50\%\@lg {
    bottom: 50%;
  }
  .bottom-auto\@lg {
    bottom: auto;
  }
  .bottom-0\@lg {
    bottom: 0;
  }
  .left-50\%\@lg {
    left: 50%;
  }
  .left-auto\@lg {
    left: auto;
  }
  .left-0\@lg {
    left: 0;
  }
  /* overflow */
  .overflow-visible\@lg {
    overflow: visible;
  }
  .overflow-hidden\@lg {
    overflow: hidden;
  }
  .overflow-auto\@lg {
    overflow: auto;
  }
  .momentum-scrolling\@lg {
    -webkit-overflow-scrolling: touch;
  }
  /* visibility */
  .visible\@lg {
    visibility: visible;
  }
  .invisible\@lg {
    visibility: hidden;
  }
}
@media not all and (min-width: 80rem) {
  .display\@lg {
    display: none !important;
  }
}
@media (min-width: 90rem) {
  /* grid */
  .grid-col-1\@xl {
    --grid-columns: 1;
  }
  .col-1\@xl {
    --column-span: 1;
  }
  .grid-col-2\@xl {
    --grid-columns: 2;
  }
  .col-2\@xl {
    --column-span: 2;
  }
  .grid-col-3\@xl {
    --grid-columns: 3;
  }
  .col-3\@xl {
    --column-span: 3;
  }
  .grid-col-4\@xl {
    --grid-columns: 4;
  }
  .col-4\@xl {
    --column-span: 4;
  }
  .grid-col-5\@xl {
    --grid-columns: 5;
  }
  .col-5\@xl {
    --column-span: 5;
  }
  .grid-col-6\@xl {
    --grid-columns: 6;
  }
  .col-6\@xl {
    --column-span: 6;
  }
  .grid-col-7\@xl {
    --grid-columns: 7;
  }
  .col-7\@xl {
    --column-span: 7;
  }
  .grid-col-8\@xl {
    --grid-columns: 8;
  }
  .col-8\@xl {
    --column-span: 8;
  }
  .grid-col-9\@xl {
    --grid-columns: 9;
  }
  .col-9\@xl {
    --column-span: 9;
  }
  .grid-col-10\@xl {
    --grid-columns: 10;
  }
  .col-10\@xl {
    --column-span: 10;
  }
  .grid-col-11\@xl {
    --grid-columns: 11;
  }
  .col-11\@xl {
    --column-span: 11;
  }
  .grid-col-12\@xl {
    --grid-columns: 12;
  }
  .col-12\@xl {
    --column-span: 12;
  }
  .col-1\@xl, .col-2\@xl, .col-3\@xl, .col-4\@xl, .col-5\@xl, .col-6\@xl, .col-7\@xl, .col-8\@xl, .col-9\@xl, .col-10\@xl, .col-11\@xl, .col-12\@xl {
    width: calc((100% - (var(--grid-columns) - 1) * var(--column-gap)) * var(--column-span) / var(--grid-columns) + (var(--column-span) - 1) * var(--column-gap));
    flex: initial;
  }
  .col\@xl {
    width: auto;
    flex: 1 1 0;
  }
  .col-content\@xl {
    width: auto;
    flex: 0 1 auto;
  }
  .offset-1\@xl {
    --column-offset: 1;
  }
  .offset-2\@xl {
    --column-offset: 2;
  }
  .offset-3\@xl {
    --column-offset: 3;
  }
  .offset-4\@xl {
    --column-offset: 4;
  }
  .offset-5\@xl {
    --column-offset: 5;
  }
  .offset-6\@xl {
    --column-offset: 6;
  }
  .offset-7\@xl {
    --column-offset: 7;
  }
  .offset-8\@xl {
    --column-offset: 8;
  }
  .offset-9\@xl {
    --column-offset: 9;
  }
  .offset-10\@xl {
    --column-offset: 10;
  }
  .offset-11\@xl {
    --column-offset: 11;
  }
  .offset-1\@xl, .offset-2\@xl, .offset-3\@xl, .offset-4\@xl, .offset-5\@xl, .offset-6\@xl, .offset-7\@xl, .offset-8\@xl, .offset-9\@xl, .offset-10\@xl, .offset-11\@xl {
    margin-left: calc((100% - (var(--grid-columns) - 1) * var(--column-gap)) * var(--column-offset) / var(--grid-columns) + var(--column-offset) * var(--column-gap));
  }
  .offset-0\@xl {
    margin-left: 0;
  }
  /* gap */
  .gap-4xs\@xl {
    --row-gap: var(--space-4xs);
    --column-gap: var(--space-4xs);
    gap: var(--row-gap) var(--column-gap);
  }
  .gap-x-4xs\@xl {
    --column-gap: var(--space-4xs);
    column-gap: var(--column-gap);
  }
  .gap-y-4xs\@xl {
    --row-gap: var(--space-4xs);
    row-gap: var(--row-gap);
  }
  .gap-3xs\@xl {
    --row-gap: var(--space-3xs);
    --column-gap: var(--space-3xs);
    gap: var(--row-gap) var(--column-gap);
  }
  .gap-x-3xs\@xl {
    --column-gap: var(--space-3xs);
    column-gap: var(--column-gap);
  }
  .gap-y-3xs\@xl {
    --row-gap: var(--space-3xs);
    row-gap: var(--row-gap);
  }
  .gap-2xs\@xl {
    --row-gap: var(--space-2xs);
    --column-gap: var(--space-2xs);
    gap: var(--row-gap) var(--column-gap);
  }
  .gap-x-2xs\@xl {
    --column-gap: var(--space-2xs);
    column-gap: var(--column-gap);
  }
  .gap-y-2xs\@xl {
    --row-gap: var(--space-2xs);
    row-gap: var(--row-gap);
  }
  .gap-xs\@xl {
    --row-gap: var(--space-xs);
    --column-gap: var(--space-xs);
    gap: var(--row-gap) var(--column-gap);
  }
  .gap-x-xs\@xl {
    --column-gap: var(--space-xs);
    column-gap: var(--column-gap);
  }
  .gap-y-xs\@xl {
    --row-gap: var(--space-xs);
    row-gap: var(--row-gap);
  }
  .gap-sm\@xl {
    --row-gap: var(--space-sm);
    --column-gap: var(--space-sm);
    gap: var(--row-gap) var(--column-gap);
  }
  .gap-x-sm\@xl {
    --column-gap: var(--space-sm);
    column-gap: var(--column-gap);
  }
  .gap-y-sm\@xl {
    --row-gap: var(--space-sm);
    row-gap: var(--row-gap);
  }
  .gap-md\@xl {
    --row-gap: var(--space-md);
    --column-gap: var(--space-md);
    gap: var(--row-gap) var(--column-gap);
  }
  .gap-x-md\@xl {
    --column-gap: var(--space-md);
    column-gap: var(--column-gap);
  }
  .gap-y-md\@xl {
    --row-gap: var(--space-md);
    row-gap: var(--row-gap);
  }
  .gap-lg\@xl {
    --row-gap: var(--space-lg);
    --column-gap: var(--space-lg);
    gap: var(--row-gap) var(--column-gap);
  }
  .gap-x-lg\@xl {
    --column-gap: var(--space-lg);
    column-gap: var(--column-gap);
  }
  .gap-y-lg\@xl {
    --row-gap: var(--space-lg);
    row-gap: var(--row-gap);
  }
  .gap-xl\@xl {
    --row-gap: var(--space-xl);
    --column-gap: var(--space-xl);
    gap: var(--row-gap) var(--column-gap);
  }
  .gap-x-xl\@xl {
    --column-gap: var(--space-xl);
    column-gap: var(--column-gap);
  }
  .gap-y-xl\@xl {
    --row-gap: var(--space-xl);
    row-gap: var(--row-gap);
  }
  .gap-2xl\@xl {
    --row-gap: var(--space-2xl);
    --column-gap: var(--space-2xl);
    gap: var(--row-gap) var(--column-gap);
  }
  .gap-x-2xl\@xl {
    --column-gap: var(--space-2xl);
    column-gap: var(--column-gap);
  }
  .gap-y-2xl\@xl {
    --row-gap: var(--space-2xl);
    row-gap: var(--row-gap);
  }
  .gap-3xl\@xl {
    --row-gap: var(--space-3xl);
    --column-gap: var(--space-3xl);
    gap: var(--row-gap) var(--column-gap);
  }
  .gap-x-3xl\@xl {
    --column-gap: var(--space-3xl);
    column-gap: var(--column-gap);
  }
  .gap-y-3xl\@xl {
    --row-gap: var(--space-3xl);
    row-gap: var(--row-gap);
  }
  .gap-4xl\@xl {
    --row-gap: var(--space-4xl);
    --column-gap: var(--space-4xl);
    gap: var(--row-gap) var(--column-gap);
  }
  .gap-x-4xl\@xl {
    --column-gap: var(--space-4xl);
    column-gap: var(--column-gap);
  }
  .gap-y-4xl\@xl {
    --row-gap: var(--space-4xl);
    row-gap: var(--row-gap);
  }
  .gap-0\@xl {
    --row-gap: 0px;
    --column-gap: 0px;
    gap: 0px;
  }
  .gap-x-0\@xl {
    --column-gap: 0px;
    column-gap: 0px;
  }
  .gap-y-0\@xl {
    --row-gap: 0px;
    row-gap: 0px;
  }
  /* flexbox */
  .flex\@xl {
    display: flex;
  }
  .inline-flex\@xl {
    display: inline-flex;
  }
  .flex-wrap\@xl {
    flex-wrap: wrap;
  }
  .flex-nowrap\@xl {
    flex-wrap: nowrap;
  }
  .flex-column\@xl {
    flex-direction: column;
  }
  .flex-column-reverse\@xl {
    flex-direction: column-reverse;
  }
  .flex-row\@xl {
    flex-direction: row;
  }
  .flex-row-reverse\@xl {
    flex-direction: row-reverse;
  }
  .flex-center\@xl {
    justify-content: center;
    align-items: center;
  }
  .flex-grow\@xl {
    flex-grow: 1;
  }
  .flex-grow-0\@xl {
    flex-grow: 0;
  }
  .flex-shrink\@xl {
    flex-shrink: 1;
  }
  .flex-shrink-0\@xl {
    flex-shrink: 0;
  }
  .flex-basis-0\@xl {
    flex-basis: 0;
  }
  /* justify-content */
  .justify-start\@xl {
    justify-content: start;
  }
  .justify-end\@xl {
    justify-content: end;
  }
  .justify-center\@xl {
    justify-content: center;
  }
  .justify-between\@xl {
    justify-content: space-between;
  }
  /* align-items */
  .items-center\@xl {
    align-items: center;
  }
  .items-start\@xl {
    align-items: start;
  }
  .items-end\@xl {
    align-items: end;
  }
  .items-baseline\@xl {
    align-items: baseline;
  }
  .items-stretch\@xl {
    align-items: stretch;
  }
  /* align-content */
  .content-start\@xl {
    align-content: start;
  }
  .content-end\@xl {
    align-content: end;
  }
  .content-center\@xl {
    align-content: center;
  }
  .content-between\@xl {
    align-content: space-between;
  }
  /* order */
  .order-1\@xl {
    order: 1;
  }
  .order-2\@xl {
    order: 2;
  }
  .order-3\@xl {
    order: 3;
  }
  /* display */
  .block\@xl {
    display: block;
  }
  .inline-block\@xl {
    display: inline-block;
  }
  .inline\@xl {
    display: inline;
  }
  .contents\@xl {
    display: contents;
  }
  .css-grid\@xl {
    display: grid;
  }
  .css-inline-grid\@xl {
    display: inline-grid;
  }
  .hide\@xl {
    display: none;
  }
  /* margin */
  .margin-4xs\@xl {
    margin: var(--space-4xs);
  }
  .margin-top-4xs\@xl {
    margin-top: var(--space-4xs);
  }
  .margin-right-4xs\@xl {
    margin-right: var(--space-4xs);
  }
  .margin-bottom-4xs\@xl {
    margin-bottom: var(--space-4xs);
  }
  .margin-left-4xs\@xl {
    margin-left: var(--space-4xs);
  }
  .margin-y-4xs\@xl {
    margin-top: var(--space-4xs);
    margin-bottom: var(--space-4xs);
  }
  .margin-x-4xs\@xl {
    margin-right: var(--space-4xs);
    margin-left: var(--space-4xs);
  }
  .margin-3xs\@xl {
    margin: var(--space-3xs);
  }
  .margin-top-3xs\@xl {
    margin-top: var(--space-3xs);
  }
  .margin-right-3xs\@xl {
    margin-right: var(--space-3xs);
  }
  .margin-bottom-3xs\@xl {
    margin-bottom: var(--space-3xs);
  }
  .margin-left-3xs\@xl {
    margin-left: var(--space-3xs);
  }
  .margin-y-3xs\@xl {
    margin-top: var(--space-3xs);
    margin-bottom: var(--space-3xs);
  }
  .margin-x-3xs\@xl {
    margin-right: var(--space-3xs);
    margin-left: var(--space-3xs);
  }
  .margin-2xs\@xl {
    margin: var(--space-2xs);
  }
  .margin-top-2xs\@xl {
    margin-top: var(--space-2xs);
  }
  .margin-right-2xs\@xl {
    margin-right: var(--space-2xs);
  }
  .margin-bottom-2xs\@xl {
    margin-bottom: var(--space-2xs);
  }
  .margin-left-2xs\@xl {
    margin-left: var(--space-2xs);
  }
  .margin-y-2xs\@xl {
    margin-top: var(--space-2xs);
    margin-bottom: var(--space-2xs);
  }
  .margin-x-2xs\@xl {
    margin-right: var(--space-2xs);
    margin-left: var(--space-2xs);
  }
  .margin-xs\@xl {
    margin: var(--space-xs);
  }
  .margin-top-xs\@xl {
    margin-top: var(--space-xs);
  }
  .margin-right-xs\@xl {
    margin-right: var(--space-xs);
  }
  .margin-bottom-xs\@xl {
    margin-bottom: var(--space-xs);
  }
  .margin-left-xs\@xl {
    margin-left: var(--space-xs);
  }
  .margin-y-xs\@xl {
    margin-top: var(--space-xs);
    margin-bottom: var(--space-xs);
  }
  .margin-x-xs\@xl {
    margin-right: var(--space-xs);
    margin-left: var(--space-xs);
  }
  .margin-sm\@xl {
    margin: var(--space-sm);
  }
  .margin-top-sm\@xl {
    margin-top: var(--space-sm);
  }
  .margin-right-sm\@xl {
    margin-right: var(--space-sm);
  }
  .margin-bottom-sm\@xl {
    margin-bottom: var(--space-sm);
  }
  .margin-left-sm\@xl {
    margin-left: var(--space-sm);
  }
  .margin-y-sm\@xl {
    margin-top: var(--space-sm);
    margin-bottom: var(--space-sm);
  }
  .margin-x-sm\@xl {
    margin-right: var(--space-sm);
    margin-left: var(--space-sm);
  }
  .margin-md\@xl {
    margin: var(--space-md);
  }
  .margin-top-md\@xl {
    margin-top: var(--space-md);
  }
  .margin-right-md\@xl {
    margin-right: var(--space-md);
  }
  .margin-bottom-md\@xl {
    margin-bottom: var(--space-md);
  }
  .margin-left-md\@xl {
    margin-left: var(--space-md);
  }
  .margin-y-md\@xl {
    margin-top: var(--space-md);
    margin-bottom: var(--space-md);
  }
  .margin-x-md\@xl {
    margin-right: var(--space-md);
    margin-left: var(--space-md);
  }
  .margin-lg\@xl {
    margin: var(--space-lg);
  }
  .margin-top-lg\@xl {
    margin-top: var(--space-lg);
  }
  .margin-right-lg\@xl {
    margin-right: var(--space-lg);
  }
  .margin-bottom-lg\@xl {
    margin-bottom: var(--space-lg);
  }
  .margin-left-lg\@xl {
    margin-left: var(--space-lg);
  }
  .margin-y-lg\@xl {
    margin-top: var(--space-lg);
    margin-bottom: var(--space-lg);
  }
  .margin-x-lg\@xl {
    margin-right: var(--space-lg);
    margin-left: var(--space-lg);
  }
  .margin-xl\@xl {
    margin: var(--space-xl);
  }
  .margin-top-xl\@xl {
    margin-top: var(--space-xl);
  }
  .margin-right-xl\@xl {
    margin-right: var(--space-xl);
  }
  .margin-bottom-xl\@xl {
    margin-bottom: var(--space-xl);
  }
  .margin-left-xl\@xl {
    margin-left: var(--space-xl);
  }
  .margin-y-xl\@xl {
    margin-top: var(--space-xl);
    margin-bottom: var(--space-xl);
  }
  .margin-x-xl\@xl {
    margin-right: var(--space-xl);
    margin-left: var(--space-xl);
  }
  .margin-2xl\@xl {
    margin: var(--space-2xl);
  }
  .margin-top-2xl\@xl {
    margin-top: var(--space-2xl);
  }
  .margin-right-2xl\@xl {
    margin-right: var(--space-2xl);
  }
  .margin-bottom-2xl\@xl {
    margin-bottom: var(--space-2xl);
  }
  .margin-left-2xl\@xl {
    margin-left: var(--space-2xl);
  }
  .margin-y-2xl\@xl {
    margin-top: var(--space-2xl);
    margin-bottom: var(--space-2xl);
  }
  .margin-x-2xl\@xl {
    margin-right: var(--space-2xl);
    margin-left: var(--space-2xl);
  }
  .margin-3xl\@xl {
    margin: var(--space-3xl);
  }
  .margin-top-3xl\@xl {
    margin-top: var(--space-3xl);
  }
  .margin-right-3xl\@xl {
    margin-right: var(--space-3xl);
  }
  .margin-bottom-3xl\@xl {
    margin-bottom: var(--space-3xl);
  }
  .margin-left-3xl\@xl {
    margin-left: var(--space-3xl);
  }
  .margin-y-3xl\@xl {
    margin-top: var(--space-3xl);
    margin-bottom: var(--space-3xl);
  }
  .margin-x-3xl\@xl {
    margin-right: var(--space-3xl);
    margin-left: var(--space-3xl);
  }
  .margin-4xl\@xl {
    margin: var(--space-4xl);
  }
  .margin-top-4xl\@xl {
    margin-top: var(--space-4xl);
  }
  .margin-right-4xl\@xl {
    margin-right: var(--space-4xl);
  }
  .margin-bottom-4xl\@xl {
    margin-bottom: var(--space-4xl);
  }
  .margin-left-4xl\@xl {
    margin-left: var(--space-4xl);
  }
  .margin-y-4xl\@xl {
    margin-top: var(--space-4xl);
    margin-bottom: var(--space-4xl);
  }
  .margin-x-4xl\@xl {
    margin-right: var(--space-4xl);
    margin-left: var(--space-4xl);
  }
  .margin-auto\@xl {
    margin: auto;
  }
  .margin-0\@xl {
    margin: 0;
  }
  .margin-y-auto\@xl {
    margin-top: auto;
    margin-bottom: auto;
  }
  .margin-y-0\@xl {
    margin-top: 0;
    margin-bottom: 0;
  }
  .margin-x-auto\@xl {
    margin-left: auto;
    margin-right: auto;
  }
  .margin-x-0\@xl {
    margin-left: 0;
    margin-right: 0;
  }
  .margin-top-auto\@xl {
    margin-top: auto;
  }
  .margin-top-0\@xl {
    margin-top: 0;
  }
  .margin-right-auto\@xl {
    margin-right: auto;
  }
  .margin-right-0\@xl {
    margin-right: 0;
  }
  .margin-bottom-auto\@xl {
    margin-bottom: auto;
  }
  .margin-bottom-0\@xl {
    margin-bottom: 0;
  }
  .margin-left-auto\@xl {
    margin-left: auto;
  }
  .margin-left-0\@xl {
    margin-left: 0;
  }
  /* padding */
  .padding-4xs\@xl {
    padding: var(--space-4xs);
  }
  .padding-top-4xs\@xl {
    padding-top: var(--space-4xs);
  }
  .padding-right-4xs\@xl {
    padding-right: var(--space-4xs);
  }
  .padding-bottom-4xs\@xl {
    padding-bottom: var(--space-4xs);
  }
  .padding-left-4xs\@xl {
    padding-left: var(--space-4xs);
  }
  .padding-y-4xs\@xl {
    padding-top: var(--space-4xs);
    padding-bottom: var(--space-4xs);
  }
  .padding-x-4xs\@xl {
    padding-right: var(--space-4xs);
    padding-left: var(--space-4xs);
  }
  .padding-3xs\@xl {
    padding: var(--space-3xs);
  }
  .padding-top-3xs\@xl {
    padding-top: var(--space-3xs);
  }
  .padding-right-3xs\@xl {
    padding-right: var(--space-3xs);
  }
  .padding-bottom-3xs\@xl {
    padding-bottom: var(--space-3xs);
  }
  .padding-left-3xs\@xl {
    padding-left: var(--space-3xs);
  }
  .padding-y-3xs\@xl {
    padding-top: var(--space-3xs);
    padding-bottom: var(--space-3xs);
  }
  .padding-x-3xs\@xl {
    padding-right: var(--space-3xs);
    padding-left: var(--space-3xs);
  }
  .padding-2xs\@xl {
    padding: var(--space-2xs);
  }
  .padding-top-2xs\@xl {
    padding-top: var(--space-2xs);
  }
  .padding-right-2xs\@xl {
    padding-right: var(--space-2xs);
  }
  .padding-bottom-2xs\@xl {
    padding-bottom: var(--space-2xs);
  }
  .padding-left-2xs\@xl {
    padding-left: var(--space-2xs);
  }
  .padding-y-2xs\@xl {
    padding-top: var(--space-2xs);
    padding-bottom: var(--space-2xs);
  }
  .padding-x-2xs\@xl {
    padding-right: var(--space-2xs);
    padding-left: var(--space-2xs);
  }
  .padding-xs\@xl {
    padding: var(--space-xs);
  }
  .padding-top-xs\@xl {
    padding-top: var(--space-xs);
  }
  .padding-right-xs\@xl {
    padding-right: var(--space-xs);
  }
  .padding-bottom-xs\@xl {
    padding-bottom: var(--space-xs);
  }
  .padding-left-xs\@xl {
    padding-left: var(--space-xs);
  }
  .padding-y-xs\@xl {
    padding-top: var(--space-xs);
    padding-bottom: var(--space-xs);
  }
  .padding-x-xs\@xl {
    padding-right: var(--space-xs);
    padding-left: var(--space-xs);
  }
  .padding-sm\@xl {
    padding: var(--space-sm);
  }
  .padding-top-sm\@xl {
    padding-top: var(--space-sm);
  }
  .padding-right-sm\@xl {
    padding-right: var(--space-sm);
  }
  .padding-bottom-sm\@xl {
    padding-bottom: var(--space-sm);
  }
  .padding-left-sm\@xl {
    padding-left: var(--space-sm);
  }
  .padding-y-sm\@xl {
    padding-top: var(--space-sm);
    padding-bottom: var(--space-sm);
  }
  .padding-x-sm\@xl {
    padding-right: var(--space-sm);
    padding-left: var(--space-sm);
  }
  .padding-md\@xl {
    padding: var(--space-md);
  }
  .padding-top-md\@xl {
    padding-top: var(--space-md);
  }
  .padding-right-md\@xl {
    padding-right: var(--space-md);
  }
  .padding-bottom-md\@xl {
    padding-bottom: var(--space-md);
  }
  .padding-left-md\@xl {
    padding-left: var(--space-md);
  }
  .padding-y-md\@xl {
    padding-top: var(--space-md);
    padding-bottom: var(--space-md);
  }
  .padding-x-md\@xl {
    padding-right: var(--space-md);
    padding-left: var(--space-md);
  }
  .padding-lg\@xl {
    padding: var(--space-lg);
  }
  .padding-top-lg\@xl {
    padding-top: var(--space-lg);
  }
  .padding-right-lg\@xl {
    padding-right: var(--space-lg);
  }
  .padding-bottom-lg\@xl {
    padding-bottom: var(--space-lg);
  }
  .padding-left-lg\@xl {
    padding-left: var(--space-lg);
  }
  .padding-y-lg\@xl {
    padding-top: var(--space-lg);
    padding-bottom: var(--space-lg);
  }
  .padding-x-lg\@xl {
    padding-right: var(--space-lg);
    padding-left: var(--space-lg);
  }
  .padding-xl\@xl {
    padding: var(--space-xl);
  }
  .padding-top-xl\@xl {
    padding-top: var(--space-xl);
  }
  .padding-right-xl\@xl {
    padding-right: var(--space-xl);
  }
  .padding-bottom-xl\@xl {
    padding-bottom: var(--space-xl);
  }
  .padding-left-xl\@xl {
    padding-left: var(--space-xl);
  }
  .padding-y-xl\@xl {
    padding-top: var(--space-xl);
    padding-bottom: var(--space-xl);
  }
  .padding-x-xl\@xl {
    padding-right: var(--space-xl);
    padding-left: var(--space-xl);
  }
  .padding-2xl\@xl {
    padding: var(--space-2xl);
  }
  .padding-top-2xl\@xl {
    padding-top: var(--space-2xl);
  }
  .padding-right-2xl\@xl {
    padding-right: var(--space-2xl);
  }
  .padding-bottom-2xl\@xl {
    padding-bottom: var(--space-2xl);
  }
  .padding-left-2xl\@xl {
    padding-left: var(--space-2xl);
  }
  .padding-y-2xl\@xl {
    padding-top: var(--space-2xl);
    padding-bottom: var(--space-2xl);
  }
  .padding-x-2xl\@xl {
    padding-right: var(--space-2xl);
    padding-left: var(--space-2xl);
  }
  .padding-3xl\@xl {
    padding: var(--space-3xl);
  }
  .padding-top-3xl\@xl {
    padding-top: var(--space-3xl);
  }
  .padding-right-3xl\@xl {
    padding-right: var(--space-3xl);
  }
  .padding-bottom-3xl\@xl {
    padding-bottom: var(--space-3xl);
  }
  .padding-left-3xl\@xl {
    padding-left: var(--space-3xl);
  }
  .padding-y-3xl\@xl {
    padding-top: var(--space-3xl);
    padding-bottom: var(--space-3xl);
  }
  .padding-x-3xl\@xl {
    padding-right: var(--space-3xl);
    padding-left: var(--space-3xl);
  }
  .padding-4xl\@xl {
    padding: var(--space-4xl);
  }
  .padding-top-4xl\@xl {
    padding-top: var(--space-4xl);
  }
  .padding-right-4xl\@xl {
    padding-right: var(--space-4xl);
  }
  .padding-bottom-4xl\@xl {
    padding-bottom: var(--space-4xl);
  }
  .padding-left-4xl\@xl {
    padding-left: var(--space-4xl);
  }
  .padding-y-4xl\@xl {
    padding-top: var(--space-4xl);
    padding-bottom: var(--space-4xl);
  }
  .padding-x-4xl\@xl {
    padding-right: var(--space-4xl);
    padding-left: var(--space-4xl);
  }
  .padding-0\@xl {
    padding: 0;
  }
  .padding-y-0\@xl {
    padding-top: 0;
    padding-bottom: 0;
  }
  .padding-x-0\@xl {
    padding-left: 0;
    padding-right: 0;
  }
  .padding-top-0\@xl {
    padding-top: 0;
  }
  .padding-right-0\@xl {
    padding-right: 0;
  }
  .padding-bottom-0\@xl {
    padding-bottom: 0;
  }
  .padding-left-0\@xl {
    padding-left: 0;
  }
  /* text-align */
  .text-center\@xl {
    text-align: center;
  }
  .text-left\@xl {
    text-align: left;
  }
  .text-right\@xl {
    text-align: right;
  }
  .text-justify\@xl {
    text-align: justify;
  }
  /* font-size */
  .text-xs\@xl {
    font-size: var(--text-xs);
  }
  .text-sm\@xl {
    font-size: var(--text-sm);
  }
  .text-base\@xl {
    font-size: var(--text-base);
  }
  .text-md\@xl {
    font-size: var(--text-md);
  }
  .text-lg\@xl {
    font-size: var(--text-lg);
  }
  .text-xl\@xl {
    font-size: var(--text-xl);
  }
  .text-2xl\@xl {
    font-size: var(--text-2xl);
  }
  .text-3xl\@xl {
    font-size: var(--text-3xl);
  }
  .text-4xl\@xl {
    font-size: var(--text-4xl);
  }
  /* column-count */
  .column-count-1\@xl {
    column-count: 1;
  }
  .column-count-2\@xl {
    column-count: 2;
  }
  .column-count-3\@xl {
    column-count: 3;
  }
  .column-count-4\@xl {
    column-count: 4;
  }
  /* width */
  .width-4xs\@xl {
    width: 0.25rem;
  }
  .width-3xs\@xl {
    width: 0.5rem;
  }
  .width-2xs\@xl {
    width: 0.75rem;
  }
  .width-xs\@xl {
    width: 1rem;
  }
  .width-sm\@xl {
    width: 1.5rem;
  }
  .width-md\@xl {
    width: 2rem;
  }
  .width-lg\@xl {
    width: 3rem;
  }
  .width-xl\@xl {
    width: 4rem;
  }
  .width-2xl\@xl {
    width: 6rem;
  }
  .width-3xl\@xl {
    width: 8rem;
  }
  .width-4xl\@xl {
    width: 16rem;
  }
  .width-0\@xl {
    width: 0;
  }
  .width-10\%\@xl {
    width: 10%;
  }
  .width-20\%\@xl {
    width: 20%;
  }
  .width-25\%\@xl {
    width: 25%;
  }
  .width-30\%\@xl {
    width: 30%;
  }
  .width-33\%\@xl {
    width: 33%;
  }
  .width-40\%\@xl {
    width: 40%;
  }
  .width-50\%\@xl {
    width: 50%;
  }
  .width-60\%\@xl {
    width: 60%;
  }
  .width-70\%\@xl {
    width: 70%;
  }
  .width-75\%\@xl {
    width: 75%;
  }
  .width-80\%\@xl {
    width: 80%;
  }
  .width-90\%\@xl {
    width: 90%;
  }
  .width-100\%\@xl {
    width: 100%;
  }
  .width-100\%\@xl {
    width: 100%;
  }
  .width-100vw\@xl {
    width: 100vw;
  }
  .width-auto\@xl {
    width: auto;
  }
  .width-inherit\@xl {
    width: inherit;
  }
  .width-0\@xl {
    width: 0;
  }
  /* height */
  .height-4xs\@xl {
    height: 0.25rem;
  }
  .height-3xs\@xl {
    height: 0.5rem;
  }
  .height-2xs\@xl {
    height: 0.75rem;
  }
  .height-xs\@xl {
    height: 1rem;
  }
  .height-sm\@xl {
    height: 1.5rem;
  }
  .height-md\@xl {
    height: 2rem;
  }
  .height-lg\@xl {
    height: 3rem;
  }
  .height-xl\@xl {
    height: 4rem;
  }
  .height-2xl\@xl {
    height: 6rem;
  }
  .height-3xl\@xl {
    height: 8rem;
  }
  .height-4xl\@xl {
    height: 16rem;
  }
  .height-0\@xl {
    height: 0;
  }
  .height-10\%\@xl {
    height: 10%;
  }
  .height-20\%\@xl {
    height: 20%;
  }
  .height-25\%\@xl {
    height: 25%;
  }
  .height-30\%\@xl {
    height: 30%;
  }
  .height-33\%\@xl {
    height: 33%;
  }
  .height-40\%\@xl {
    height: 40%;
  }
  .height-50\%\@xl {
    height: 50%;
  }
  .height-60\%\@xl {
    height: 60%;
  }
  .height-70\%\@xl {
    height: 70%;
  }
  .height-75\%\@xl {
    height: 75%;
  }
  .height-80\%\@xl {
    height: 80%;
  }
  .height-90\%\@xl {
    height: 90%;
  }
  .height-100\%\@xl {
    height: 100%;
  }
  .height-100\%\@xl {
    height: 100%;
  }
  .height-100vh\@xl {
    height: 100vh;
  }
  .height-auto\@xl {
    height: auto;
  }
  .height-inherit\@xl {
    height: inherit;
  }
  .height-0\@xl {
    height: 0;
  }
  /* max-width */
  .max-width-3xs\@xl {
    max-width: var(--max-width-3xs);
  }
  .max-width-2xs\@xl {
    max-width: var(--max-width-2xs);
  }
  .max-width-xs\@xl {
    max-width: var(--max-width-xs);
  }
  .max-width-sm\@xl {
    max-width: var(--max-width-sm);
  }
  .max-width-md\@xl {
    max-width: var(--max-width-md);
  }
  .max-width-lg\@xl {
    max-width: var(--max-width-lg);
  }
  .max-width-xl\@xl {
    max-width: var(--max-width-xl);
  }
  .max-width-100\%\@xl {
    max-width: 100%;
  }
  .max-width-none\@xl {
    max-width: none;
  }
  /* position */
  .position-relative\@xl {
    position: relative;
  }
  .position-absolute\@xl {
    position: absolute;
  }
  .position-fixed\@xl {
    position: fixed;
  }
  .position-sticky\@xl {
    position: sticky;
  }
  .position-static\@xl {
    position: static;
  }
  .inset-0\@xl {
    inset: 0;
  }
  .top-4xs\@xl {
    top: var(--space-4xs);
  }
  .right-4xs\@xl {
    right: var(--space-4xs);
  }
  .bottom-4xs\@xl {
    bottom: var(--space-4xs);
  }
  .left-4xs\@xl {
    left: var(--space-4xs);
  }
  .top-3xs\@xl {
    top: var(--space-3xs);
  }
  .right-3xs\@xl {
    right: var(--space-3xs);
  }
  .bottom-3xs\@xl {
    bottom: var(--space-3xs);
  }
  .left-3xs\@xl {
    left: var(--space-3xs);
  }
  .top-2xs\@xl {
    top: var(--space-2xs);
  }
  .right-2xs\@xl {
    right: var(--space-2xs);
  }
  .bottom-2xs\@xl {
    bottom: var(--space-2xs);
  }
  .left-2xs\@xl {
    left: var(--space-2xs);
  }
  .top-xs\@xl {
    top: var(--space-xs);
  }
  .right-xs\@xl {
    right: var(--space-xs);
  }
  .bottom-xs\@xl {
    bottom: var(--space-xs);
  }
  .left-xs\@xl {
    left: var(--space-xs);
  }
  .top-sm\@xl {
    top: var(--space-sm);
  }
  .right-sm\@xl {
    right: var(--space-sm);
  }
  .bottom-sm\@xl {
    bottom: var(--space-sm);
  }
  .left-sm\@xl {
    left: var(--space-sm);
  }
  .top-md\@xl {
    top: var(--space-md);
  }
  .right-md\@xl {
    right: var(--space-md);
  }
  .bottom-md\@xl {
    bottom: var(--space-md);
  }
  .left-md\@xl {
    left: var(--space-md);
  }
  .top-lg\@xl {
    top: var(--space-lg);
  }
  .right-lg\@xl {
    right: var(--space-lg);
  }
  .bottom-lg\@xl {
    bottom: var(--space-lg);
  }
  .left-lg\@xl {
    left: var(--space-lg);
  }
  .top-xl\@xl {
    top: var(--space-xl);
  }
  .right-xl\@xl {
    right: var(--space-xl);
  }
  .bottom-xl\@xl {
    bottom: var(--space-xl);
  }
  .left-xl\@xl {
    left: var(--space-xl);
  }
  .top-2xl\@xl {
    top: var(--space-2xl);
  }
  .right-2xl\@xl {
    right: var(--space-2xl);
  }
  .bottom-2xl\@xl {
    bottom: var(--space-2xl);
  }
  .left-2xl\@xl {
    left: var(--space-2xl);
  }
  .top-3xl\@xl {
    top: var(--space-3xl);
  }
  .right-3xl\@xl {
    right: var(--space-3xl);
  }
  .bottom-3xl\@xl {
    bottom: var(--space-3xl);
  }
  .left-3xl\@xl {
    left: var(--space-3xl);
  }
  .top-4xl\@xl {
    top: var(--space-4xl);
  }
  .right-4xl\@xl {
    right: var(--space-4xl);
  }
  .bottom-4xl\@xl {
    bottom: var(--space-4xl);
  }
  .left-4xl\@xl {
    left: var(--space-4xl);
  }
  .top-50\%\@xl {
    top: 50%;
  }
  .top-auto\@xl {
    top: auto;
  }
  .top-0\@xl {
    top: 0;
  }
  .right-50\%\@xl {
    right: 50%;
  }
  .right-auto\@xl {
    right: auto;
  }
  .right-0\@xl {
    right: 0;
  }
  .bottom-50\%\@xl {
    bottom: 50%;
  }
  .bottom-auto\@xl {
    bottom: auto;
  }
  .bottom-0\@xl {
    bottom: 0;
  }
  .left-50\%\@xl {
    left: 50%;
  }
  .left-auto\@xl {
    left: auto;
  }
  .left-0\@xl {
    left: 0;
  }
  /* overflow */
  .overflow-visible\@xl {
    overflow: visible;
  }
  .overflow-hidden\@xl {
    overflow: hidden;
  }
  .overflow-auto\@xl {
    overflow: auto;
  }
  .momentum-scrolling\@xl {
    -webkit-overflow-scrolling: touch;
  }
  /* visibility */
  .visible\@xl {
    visibility: visible;
  }
  .invisible\@xl {
    visibility: hidden;
  }
}
@media not all and (min-width: 90rem) {
  .display\@xl {
    display: none !important;
  }
}